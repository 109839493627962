import React from "react";
import SignInModal from "../../components/authentification/SignInModal";
import logo from "../../pics/logo-hearingspace.png";

import styles from "./Connexion.module.scss";
import { motion } from "framer-motion";

const Connexion = ({ setRememberMe }) => {
  return (
    <motion.div className={styles.containerConnexion}>
      <div className={styles.containerSignIn}>
        {" "}
        <img src={logo} alt="" />
        <SignInModal setRememberMe={setRememberMe} />
      </div>
      <div className={styles.containerWelcomeLogin}></div>
    </motion.div>
  );
};

export default Connexion;
