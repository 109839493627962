import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {FcExpired} from 'react-icons/fc'
import BtnForms from '../UiComponents/forms/BtnForms';
import styles from './SessionDisconnect.module.scss'
import {BsFillArrowRightCircleFill} from 'react-icons/bs'
import logo from '../../pics/logo-hearingspace.png'
const SessionDisconnect = () => {

const navigate=useNavigate()


    return (

        <div className='fixed-container-modals'>
            <div className={styles.containerExpired}>
<img src={logo} alt="" />
<h1>OUPS !  Votre session à expiré </h1>
<FcExpired style={{fontSize:'50px'}}/>
<BtnForms onClick={()=>navigate('/')} text={'Je me reconnecte'} svg={<BsFillArrowRightCircleFill/>}/>




            </div>
        </div>
    );
};

export default SessionDisconnect;