import React from "react";
import ChangePassword from "./forms/ChangePassword";
import styles from "./AccountOwner.module.scss";
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";

const AccountOwner = () => {
  return (
    <>
      <CardHeaderPage text={"Mon compte"} textAlign={"center"} />
      <div className={styles.containerAccountOwner}>
        <h1>Changer votre mot de passe </h1>

        
        <ChangePassword />
      </div>
    </>
  );
};

export default AccountOwner;
