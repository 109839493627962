import React from 'react';
import { MdDoneOutline } from 'react-icons/md';
import styles from "./Success.module.scss";

const Success = ({success}) => {
    return (
        <div className={styles.containerSucess}>
            <MdDoneOutline style={{color:'#2b7515',padding:'5px'}}/>
        <span>{success}</span>
    </div>
    );
};

export default Success;