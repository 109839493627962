import React, { useContext, useEffect, useState } from "react";
import styles from "./SpecificInfosCab.module.scss";
import Button from "../../UiComponents/Button";
import Axios from "../../../_services/caller.service";
import { useCabineContext } from "../../../context/CabineContext";
import { decodedB64, encodedB64 } from "../../../CustomHooks/DecodedBase64";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";
import {
  IoIosArrowDropdown,
  IoIosArrowDropup,
  IoMdInformationCircleOutline,
} from "react-icons/io";
import { BsSlashCircle } from "react-icons/bs";
import { FaPencilAlt } from "react-icons/fa";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { FaExclamationTriangle } from "react-icons/fa";
import Modal from "../../UiComponents/modals/Modal";
import useModal from "../../../hooks/useModal";
import NavContext from "../../../context/NavContext";
import { GrLicense } from "react-icons/gr";
import { FiChevronDown, FiChevronUp, FiCopy } from "react-icons/fi";
import { toast } from "react-toastify";
import { FaRegUser } from "react-icons/fa";
import Select from "react-select";

const SpecificInfosCab = ({
  cabId,
  majCabine,
  setMajCabine,
  setDisplaySettingCab,
  displaySettingCab,
}) => {
  const { cabine } = useCabineContext(); //all cabine owner
  const { ownerProfile } = useContext(NavContext);
  const [text, setText] = useState("");
  const [computerInfos, setComputerInfos] = useState("");
  const [edition, setEdition] = useState(false);
  const [newText, setNewText] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);
  const [displayInfos, setDisplayInfos] = useState(false);
  const [displayInfosDesktop, setDisplayInfosDeskTop] = useState(false);
  const [showModalLicence, setShowModalLicence] = useState(false);
  const [licenceDirac, setLicenceDirac] = useState(false);
  const [showAllUsersUseCab, setShowAllUSersUseCab] = useState(false);
  const [activeCard, setActiveCard] = useState(null);

  const [cabineUser, setCabineUser] = useState(false);
  const [showModalCabineUser, setShowModalCabineUser] = useState(false);

  //MODAL
  const { isShowing, toggle } = useModal();

  // GET CABINE NOTES
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/cabineNotes/${cabId}`)

        .then(function async(response) {
          const data = response.data.data[0].fval;
          if (data === "") {
            setText(data);
          } else {
            const decodedText = decodedB64(data);
            setText(decodedText);
          }
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [cabId, cabine, majCabine]);

  // GET COMPUTER INFOS
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/computerInfos/${cabId}`)

        .then(function async(response) {
          const data = response.data.data;

          setComputerInfos(data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [cabId, cabine, majCabine]);

  const handleChange = (event) => {
    setNewText(event.target.value);
  };
  //RETIRE LE'espace et le -
  const getPartBeforeDash = (str) => {
    // Découpe la chaîne au niveau du tiret et prend la première partie
    return str.split("-")[0].trim(); // trim() enlève les espaces avant et après
  };

  // GET DIRAC LICENCE
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/getLicenceDiracLoginMdp/${cabId}`)

        .then(function async(response) {
          const data = response.data.data;
          setLicenceDirac(data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [cabId, cabine, majCabine]);

  // display user's role
  const displayRole = (role) => {
    if (role === 8) {
      return "Calibration";
    }
    if (role === 1) {
      return "Consultation";
    }
    if (role === 256) {
      return "Admin";
    }
    if (role === 2147483647) {
      return "Owner";
    }
  };
  // GET CABINE USERS
  useEffect(() => {
    if (cabine !== "") {
      Axios.get(`/cabineUsers/${cabId}`)

        .then(function async(response) {
          const data = response.data.data;
          setCabineUser(data);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [cabId, cabine, majCabine]);

  const handleSubmit = (event) => {
    event.preventDefault();

    setText(newText);

    setLoading(true);
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        const textInB64 = encodedB64(newText);

        Axios.put(`/updateCabineNotes/${cabId}`, {
          data: textInB64,
        })

          .then((response) => {
            toast.success("Données enregistrées");
            setEdition(false);

            const data = response.data.data[0].fval;
            const textDecoded = decodedB64(data);
            setText(textDecoded);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {});
  };

  const handleEditClick = () => {
    setNewText(text);
    setEdition(true);
    setShowModalLicence(false);
    setShowModalCabineUser(false);
    toggle();
  };

  const handleCancelClick = () => {
    setEdition(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast("Copié dans le presse-papiers !");
      },
      (err) => {
        console.error("Impossible de copier le texte : ", err);
      }
    );
  };

  const licenceModalToggle = () => {
    setDisplaySettingCab(!displaySettingCab);
    toggle();
    setShowModalLicence(false);
  };

  const changeNotesModalToggle = () => {
    setDisplaySettingCab(!displaySettingCab);
    toggle();
    setShowModalLicence(false);
  };

  const cabineModalToggle = () => {
    setDisplaySettingCab(!displaySettingCab);
    toggle();
    setShowModalCabineUser(false);
  };

  //GESTION DES UTILISATEURS DE LA CABINE
  const handleCopyEmail = (email) => {
    navigator.clipboard.writeText(email).then(
      () => {
        toast("Email copié dans le presse-papiers !");
      },
      (err) => {
        toast.error("Erreur lors de la copie de l'email.");
        console.error("Impossible de copier le texte : ", err);
      }
    );
  };

  const toggleShowUsers = () => {
    setShowAllUSersUseCab((prevShowAll) => !prevShowAll);
  };

  const cabineUserArray = Array.isArray(cabineUser) ? cabineUser : [];
  const visibleUsers = showAllUsersUseCab
    ? cabineUserArray
    : cabineUserArray.slice(0, 1);

  return (
    <>


      {/* INFOS DE LA CONFIGURATION DU PC  */}
<div className={styles.containerConfigPCAndDirac}>
      <div className={styles.containerInfosDesktop}>
        {computerInfos &&
          computerInfos.map((val) => {
            return (
              <div className={styles.infosComputer} key={val.fname}>
                <h3 className={styles.fnameComputer}>{val.fname}:</h3>
                <h3>{val.fval}</h3>
              </div>
            );
          })}
      </div>

      {/* INFOS DE LA LICENCE DIRAC */}
      {licenceDirac && licenceDirac.length > 0 ? (
        <>
          <div className={styles.containerInfosDirac}>
            {licenceDirac.map((licence, index) => (
              <div key={index} className={styles.infosDirac}>
                <div className={styles.infos}>
                  <h3>{licence.Login}</h3>
                  <FiCopy
                    onClick={() => copyToClipboard(licence.Login)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className={styles.infos}>
                  <h3>{licence.mdp}</h3>
                  <FiCopy
                    onClick={() => copyToClipboard(licence.mdp)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </div>
            ))}
            {licenceDirac.length > 1 && (
              <div className={styles.warning}>
                <FaExclamationTriangle
                  style={{ color: "red", fontSize: "25px" }}
                />
                Attention : Plusieurs licences sont associées à cette cabine.
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.containerInfosDirac}>
            Aucune licence associée au compte {ownerProfile.display_name}
          </div>
        </>
      )}
      </div>

      <div className={styles.containerUsersAndSpecificNotes}>
      {/* UTILISATEURS DE LA CABINE */}
      <div
        className={`${styles.containerUsersUseCab} ${
          showAllUsersUseCab ? styles.active : ""
        }`}
      >
        <ul className={styles.userList}>
          {visibleUsers.map((user) => (
            <li key={user.ID} className={styles.userListItem}>
              <strong>{user.display_name}</strong>
              <FiCopy
                onClick={() => handleCopyEmail(user.user_email)}
                style={{ cursor: "pointer" }}
              />{" "}
              {displayRole(user.role)}
            </li>
          ))}
        </ul>
        {cabineUser.length > 1 && (
          <div className={styles.chevronContainer}>
            {showAllUsersUseCab ? (
              <IoIosArrowDropup
                onClick={toggleShowUsers}
                className={styles.chevronIcon}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <IoIosArrowDropdown
                onClick={toggleShowUsers}
                className={styles.chevronIcon}
                style={{ cursor: "pointer" }}
              />
            )}
          </div>
        )}
      </div>
      {/* INFOS SPECIFICQUE A LA CABINES  */}
      {edition && (
        <form className={styles.formArea} onSubmit={handleSubmit}>
          <textarea value={newText} onChange={handleChange} />
          <div className={styles.containerBtnValidation}>
            <Button
              type={"button"}
              text={"Annuler"}
              onPress={handleCancelClick}
              styles={"btnDelete"}
            />
            <Button type={"submit"} text={"Valider"} styles={"btnValidation"} />
          </div>
        </form>
      )}

      {!edition && (
        <div className={styles.containerText}>
          <h3>{text}</h3>
          <FaPencilAlt
            onClick={handleEditClick}
            className={styles.editIcon}
            style={{ cursor: "pointer", fontSize: "20px", marginTop: "5px" }}
          />
        </div>
      )}
      </div>
    </>
  );
};

export default SpecificInfosCab;
