import { createContext, useContext, useState } from "react";
import Loader from "../components/UiComponents/loader/Loader";
import useCabine from "../hooks/useCabine";
import LoaderLogin from "../components/UiComponents/loader/LoaderLogin";

const CabineContext = createContext();

export const useCabineContext = () => {
  const cabine = useContext(CabineContext);

  return cabine;
};

export const CabineProvider = ({ children }) => {
  const cabine = useCabine();

  return cabine ? (
    <CabineContext.Provider value={{ cabine }}>
      {children}
    </CabineContext.Provider>
  ) : (
    <>
      <Loader />
    </>
  );
};
