import React from 'react';

import styles from './ModalComfirmImport.module.scss'
import { AiOutlineClose } from 'react-icons/ai';
import Button from '../../../UiComponents/Button';


const ModalComfirmImport = ({setModalConfirmImport,setOverlay,isCheckedDateUpdate,checkHandler,setMaj,maj,registerNewValue}) => {
    return (
        <div className={styles.modalConfirm}>
        <AiOutlineClose
          className={styles.closeBtnWarningOutpout}
          onClick={() => {
            setMaj(!maj);
            setOverlay(false);
            setModalConfirmImport(false);
          }}
        />
        <h3>
          Les donnéees on été importés souhaitez-vous enregistrer les
          nouvelle données ?
        </h3>
        <div className={styles.useCurrentDate}>
          <input
            type="checkbox"
            checked={isCheckedDateUpdate}
            onChange={checkHandler}
          />
          <label htmlFor="">Mettre à jour avec la date du jour</label>
        </div>
        <div className={styles.btnConfirmModal}>
          <Button
            onPress={() => {
              setMaj(!maj);
              setModalConfirmImport(false);
              setOverlay(false);
            }}
            styles={"btnDelete"}
            text={"Revenir au données précédente"}
          />
          <Button
            onPress={registerNewValue}
            styles={"btnValidation"}
            text={"j'enregistre"}
          />
        </div>
      </div>
    );
};

export default ModalComfirmImport;