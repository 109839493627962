import axios from "axios";

//get token in local storage
const jwt = localStorage.getItem("jwt");

//AXIOS CONFIG

const AxiosDownload = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
  responseType: "blob",
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
});

/**
 * Interceptor token
 */
AxiosDownload.interceptors.request.use((request) => {
  request.headers.authorization = "Bearer " + jwt;

  request.headers["Content-Type"] = "application/octet-stream";

  return request;
});

AxiosDownload.interceptors.response.use((response) => {
  return response;
});

export default AxiosDownload;
