import React, { useState } from "react";
import SupportCard from "../../../UiComponents/card/SupportCard";
import styles from "./NavBarGuideCalibration.module.scss";
//SVG
import { BsCloudDownload, BsFillPersonPlusFill } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";
import { BiMicrophone, BiSupport } from "react-icons/bi";
import { RiUserSettingsLine, RiInstallLine } from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

import { SiEgghead } from "react-icons/si";
const NavBarGuideCalibration = ({ setToggleState, toggleState }) => {
  const [displayNav, setDisplayNav] = useState(true);

  const colorForCards = {
    1: "#d9ed92",
    2: "#52b69a",
    3: "#1a759f",
    4: "#184e77",
  };

  const colorSvg = "#fff";
  const sizeSvg = 66;

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <>
    
        <div className={styles.container}>
          <div className={styles.blocTabs}>
            <button
              className={
                toggleState === 1
                  ? `${styles.tabs} ${styles.activeTabs}`
                  : styles.tabs
              }
              onClick={() => toggleTab(1)}
            >
              Inserts
            </button>

            <button
              className={
                toggleState === 2
                  ? `${styles.tabs} ${styles.activeTabs}`
                  : styles.tabs
              }
              onClick={() => toggleTab(2)}
            >
              Casque
            </button>

            <button
              className={
                toggleState === 3
                  ? `${styles.tabs} ${styles.activeTabs}`
                  : styles.tabs
              }
              onClick={() => toggleTab(3)}
            >
              Champ libre
            </button>

            <button
              className={
                toggleState === 4
                  ? `${styles.tabs} ${styles.activeTabs}`
                  : styles.tabs
              }
              onClick={() => toggleTab(4)}
            >
              Casque opérateur
            </button>
          </div>
        </div>
      
    </>
  );
};

export default NavBarGuideCalibration;
