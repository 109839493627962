import React, { Children, useState } from "react";
import styles from "./ErrorMessageHS.module.scss";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { title } from "process";

const ErrorMessageHS = ({ title, children }) => {
  const [displayDetails, setDisplayDetails] = useState(false);

  return (
    <>
      <div
        onClick={() => setDisplayDetails(!displayDetails)}
        className={styles.containerTitleMessageError}
      >
        <div className={styles.titleMessage}>
          <span>{title}</span>

          {/* <img src={speakersPosition} alt="" /> */}
        </div>
        {displayDetails ? (
          <IoIosArrowDropup className={styles.iconDropDown} />
        ) : (
          <IoIosArrowDropdown className={styles.iconDropDown} />
        )}
      </div>

      {displayDetails && <div className={styles.children}>{children}</div>}
    </>
  );
};

export default ErrorMessageHS;
