import React, { useContext, useState } from "react";
import styles from "./EmailResetPassword.module.scss";
import axios from "axios";
//import { UserContext } from "../../context/UserContext";
import { toast } from "react-toastify";
import { MdOutlineMail } from "react-icons/md";
import useModal from "../../../hooks/useModal";
import Modal from "../../UiComponents/modals/Modal";
import Button from "../../UiComponents/Button";

const EmailResetPassword = ({ email, userLogin }) => {
  //MODAL
  const { isShowing, toggle } = useModal();

  axios.defaults.withCredentials = true;

  const submitFormsForgotPassword = (email) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}api/sendEmailForNewPass`, {
        userMail: email,
      })

      .then(function async(response) {
        if (
          response.data.message ===
          "Un email de réinitialisation a été envoyé sur votre boite mail"
        ) {
          toast.success(
            "Un email de réinistiatisation a été envoyé à l'utilisateur"
          );
          toggle();
        }

        if (response.data.message === "erreur email non envoyé") {
          toast.error("une erreur s'est produite pendant l'envoie de l'email");
        }
      })
      .catch(function (error) {
        toast.error("une erreur s'est produite pendant l'envoie de l'email");
      });
  };

  return (
    <div className={styles.email}>
      <MdOutlineMail
        className={styles.email}
        style={{ fontSize: "25px", cursor: "pointer" }}
        onClick={toggle}
      />
      <Modal isShowing={isShowing} hide={toggle}>
        <div className={styles.content}>
          <h3>
            Souhaitez-vous envoyer un email de réinistiatisation à{" "}
            <span>{userLogin}</span>
          </h3>
          <h3>
            Adresse email de l'utilisateur : <span>{email}</span>
          </h3>
          <div className={styles.containerBtn}>
            <Button onPress={toggle} styles={"btnDelete"} text={"Annuler"} />
            <Button
              onPress={() => submitFormsForgotPassword(email)}
              styles={"btnValidation"}
              text={"Envoi l'email"}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EmailResetPassword;
