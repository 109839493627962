import React from 'react';
import styles from './BtnForms.module.scss'




const BtnForms = ({svg,text,onClick,style,id}) => {
    return (
        <div style={{style}}>
        <button onClick={onClick} id={id} className={styles.login__submit} >
        <div>{text}</div>
   {svg}
      </button>


        </div>
    );
};

export default BtnForms;