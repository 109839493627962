import React from "react";
import stylesBtn from "./Button.module.scss";

/**
 *
 * @param {svg} = image format svg
 * @param {text} = string button text
 * @param {styles} = type = string = different style in Button.module.scss
 * @returns
 */
const Button = ({ svg, text, styles,type,onPress,id}) => {
  return (
    <>
      <button onClick={onPress} type={type} className={stylesBtn[styles]} id={id}>
        {text}
        {svg}
      </button>
    </>
  );
};

export default Button;
