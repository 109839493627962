import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import NavContext from "../context/NavContext";
import { addPropertiesById, mergeById } from "../utils/handleArray";
import Axios from "../_services/caller.service";

const useCabine = () => {
  const { ownerId, userAuth, majCabine, setMajCabine, realOwnerId } =
    useContext(NavContext);
  const [cabineByOwner, setCabineByOwner] = useState(null);
  const [numberDaysBeforeNextCalib, setNumberDaysBeforeNextCalib] = useState(
    []
  );

  useEffect(() => {
    if (userAuth) {
      const userCabine = ownerId != null ? ownerId : realOwnerId;

      Axios.get(`/cabinesCenter/${userCabine}`)

        .then(async (response) => {
          const cabineID = response.data.data;

          const allCabid = cabineID.map((cab) => cab.cabid.toString());

          Axios.post(`/cabines/`, {
            data: allCabid,
          })

            .then(async function (response) {
              const dataCab = response.data.data; // cabname

              const filledArray = await fillArrayAsync(allCabid); //récupères les données de date de calibration des cabines
              const finalArrayForDay = filledArray.filter(
                (day) => day.length !== 0
              ); // filter result null

              const mergedArray = dataCab.concat(finalArrayForDay); // fusion des deux tableaux d'objets

              // retournes les objets fusionner par leur id
              let mergedObjects = mergedArray.reduce((acc, obj) => {
                let id = obj.id;
                if (!acc[id]) {
                  acc[id] = {};
                }
                for (let key in obj) {
                  acc[id][key] = obj[key];
                }
                return acc;
              }, {});
              // remet sous forme de tableaux
              let result = [];
              for (let key in mergedObjects) {
                result.push(mergedObjects[key]);
              }

              setCabineByOwner(result);
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [userAuth, ownerId, majCabine, setMajCabine]);

  return cabineByOwner;
};
export default useCabine;

//FONCTION POUR USE CABINE

async function fillArrayAsync(array) {
  let filledArray = [];

  // Créez un tableau de promesses en exécutant une promesse pour chaque élément du tableau
  const promises = array.map(async (element) => {
    // Exécutez une tâche asynchrone ici (par exemple, un appel à une API)
    const result = await Axios.get(
      `/dateOfLastCalibrationAndNumberDays/${element}`
    )
      .then(function (response) {
        const dataDays = response.data.data;
        return dataDays;
      })
      .catch(function (error) {
        console.log(error);
      });

    return result;
  });

  // Attendre que toutes les promesses soient terminées avant de continuer
  filledArray = await Promise.all(promises);
  return filledArray;
}
