import "./styles/index.scss";
import Connexion from "./pages/Connexion/Connexion";

import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Container from "./components/Container/Container";
import RightNavbar from "./components/RightNavbar/RightNavbar";
import Dashboard from "./components/Dashboard/Dashboard";

import Campaings from "./components/Campaigns/Campaings";
import Team from "./components/Team/Team";
import NavContext from "./context/NavContext";
import axios from "axios";
import Private from "./components/authentification/Private";
import Calibration from "./components/calibration/Calibration";
import Chat from "./components/Chat/Chat";

import UserAccountLinker from "./components/InviteUserAfterMail/UserAccountLinker";
import { CabineProvider } from "./context/CabineContext";
import { UserInviteProvider } from "./context/UserInviteContext";
import SuperAdmin from "./components/superAdmin/SuperAdmin";
import Faq from "./components/FAQ/Faq";
import { output, categoriesName } from "./context/ConstantContext";
import ResetPassword from "./components/authentification/ResetPassword";
import Axios from "./_services/caller.service";
import AccountOwner from "./components/AccountOwner/AccountOwner";
import SessionDisconnect from "./components/authentification/SessionDisconnect";
import SessionUser from "./_services/session.service";
import Erreur404 from "./pages/Erreur404";
import DownloadOwner from "./components/DownloadOwner/DownloadOwner";
import { AnimatePresence } from "framer-motion";
import PublicRouter from "./router/PublicRouter";

import SupportHSRouter from "./router/SupportHSRouter";
import OwnerWithoutCab from "./components/superAdmin/infos/OwnerWithoutCab";
import ManageGroup from "./components/manageUser/manageUserGroup/ManageGroup";
import Preset from "./components/manageUser/manageUserPreset/Preset";

import ManageMics from "./components/manageMics/ManageMics";
import ManageCabines from "./components/manageSubscriptions/ManageCabines";

//NOTIFY
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmailSenderTemplate from "./components/manageSendEmail/EmailSenderTemplate";
import DiracLicence from "./components/diracLicence/DiracLicence";
import ManageCertificat from "./components/manageCertificat/ManageCertificat";
import Statistics from "./components/statistics/Statistics";

function App() {
  const location = useLocation();
  const [nav, setNav] = useState(true);

  // AUTH
  const [superAdmin, setSuperAdmin] = useState(false);
  const [user, setUser] = useState(null); //ici si c'est un user simple sans avoir de droits

  const [ownerId, setOwnerId] = useState(null);
  //STORE THE FIRST USER SUPERADMIN CONNECTION
  const [realOwnerId, setRealOwnerId] = useState(null);
  const [realOwnerProfile, setRealOwnerProfile] = useState(null);

  const [userAuth, setUserAuth] = useState(false);
  const [ownerProfile, setOwnerProfile] = useState([]);
  const [adminProfile, setAdminProfile] = useState([]);
  //Loading
  const [errorLogin, setErrorLogin] = useState("");
  const [loaderLogin, setLoaderLogin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [majCabine, setMajCabine] = useState(false);
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  const [rememberMe, setRememberMe] = useState(false);
  const [privateRoute, setPrivateRoute] = useState(true);
  //Progress Download ZIP
  const [progressWord, setProgressWord] = useState(0);
  const [progressHearing, setProgressHearing] = useState(0);
  const [progressManual, setProgressManual] = useState(0);
  //USER GROUP
  const [userGroup, setUserGroup] = useState(null);

  const [displayOwnerWithoutCabine, setDisplayOwnerWithoutCabine] =
    useState(false); // if true we don't display superadmin components
  const jwt = localStorage.getItem("jwt");
  const navigate = useNavigate();

  axios.defaults.withCredentials = true;

  const loginUser = (email, password) => {
    setLoaderLogin(true);

    Axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}api/login`,
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        console.log(res.data.msg);
        if (res.status === 209) {
          toast.error(res.data.msg);
          setLoaderLogin(false);
          return;
        }
    
        localStorage.setItem("jwt", res.data.token);

        if (rememberMe) {
          localStorage.setItem("isLoggedIn", true);
        }
        toast.success("Connexion réussie");
        setOwnerId(res.data.id);
        // setUserAuth(true);
        setAdminProfile(res.data.profil);
        setLoaderLogin(false);
        setLoading(false);
        navigate("private/dashboard");
        window.location.reload();

        if (res.data.superAdmin === true) {
          setSuperAdmin(true);
        }
      })

      .catch((error) => {
        if (error.response && error.response.status === 429) {
          toast.error("Trop de requêtes, veuillez réessayer plus tard dans 15 minutes",{autoClose:false});
 
          setLoaderLogin(false)
        } else {
          toast.error("Une erreur est survenue");
        }
      });
  };

  useEffect(() => {
    // si on est pour une invitation alors on ne fais pas la req pour vérifier userConnect car sinon cela
    // renvoie une 401
    let params = new URLSearchParams(document.location.href);
    const checkIfPageIsUserAccountLinker = params
      .toString()
      .includes("userAccountLinker");
    const checkIfPagesIsVerifyTokenForForgetPassword = params
      .toString()
      .includes("resetPassword");

    if (
      !checkIfPageIsUserAccountLinker &&
      !checkIfPagesIsVerifyTokenForForgetPassword
    ) {
      Axios.get(`/userConnect`)

        .then(function async(response) {
          setOwnerId(response.data.ownerId);
          setRealOwnerId(response.data.id);
          setRealOwnerProfile(response.data.profil);
          setAdminProfile(response.data.profil); // admin profile c'est uniquement quand l'user est admin
          setSuperAdmin(response.data.superAdmin);
          setUser(response.data.user);
          setUserAuth(true);
          setLoading(false);
        })
        .catch(function (error) {});
    }
  }, []);

  //PROFILE
  useEffect(() => {
    if (ownerId !== null) {
      Axios.get(`/getOwnerProfile/${ownerId}`)

        .then(function async(response) {
          setOwnerProfile(response.data.data);
        })
        .catch(function (error) {});
    }
  }, [ownerId]);

  // //GROUP
  useEffect(() => {
    if (ownerId !== null) {
      Axios.get(`/getOwnerGroup/${ownerId}`)

        .then(function async(response) {
          setUserGroup(response.data.userGroup[0]);
        })
        .catch(function (error) {});
    }
  }, [ownerId]);

  //IF IN URL WE HAVE NOT PRIVATE ROUTE WE DON'T SEE NAVBAR
  useEffect(() => {
    let params = new URLSearchParams(document.location.href);
    if (
      params.toString().includes("session") ||
      params.toString().includes("userAccountLinker")
    ) {
      setPrivateRoute(false);
    }
  }, []);

  //IF LOGGED IN IS TRUE SO WE REDIRECT TO DASHBOARD PAGE
  useEffect(() => {
    if (userAuth && location.pathname === "/" && jwt) {
      setPrivateRoute(true);
      navigate("private/dashboard");
    }
  }, [location, jwt, navigate, ownerId, userAuth]);

  const value = {
    nav,
    setNav,
    user,
    setUser,
    userGroup,
    setUserGroup,
    ownerId,
    setOwnerId,
    realOwnerId,
    setRealOwnerId,
    realOwnerProfile,
    setRealOwnerProfile,
    loginUser,
    errorLogin,
    loaderLogin,
    setLoaderLogin,
    userAuth,
    setUserAuth,
    loading,
    setLoading,
    ownerProfile,
    setOwnerProfile,
    adminProfile,
    setAdminProfile,
    superAdmin,
    setSuperAdmin,
    output,
    categoriesName,
    majCabine,
    setMajCabine,
    displayOwnerWithoutCabine,
    setDisplayOwnerWithoutCabine,
    setProgressWord,
    progressWord,
    progressHearing,
    setProgressHearing,
    progressManual,
    setProgressManual,
  };

  return (
    <div className="App">
      <NavContext.Provider value={value}>
        <ToastContainer theme="colored" autoClose={2000} />
        {userAuth && privateRoute && <Navbar />}
        <SessionUser />

        <Container
          stickyNav={userAuth && <RightNavbar />}
          content={
            <>
              <AnimatePresence exitBeforeEnter>
                <Routes location={location} key={location.key}>
                  <Route
                    path="/*"
                    element={
                      <PublicRouter
                        setRememberMe={setRememberMe}
                        isLoggedIn={isLoggedIn}
                      />
                    }
                  />

                  {/* ROUTES PRIVES */}
                  <Route path="/private/" element={<Private />}>
                    <Route path="/private/dashboard/" element={<Dashboard />} />
                
                    <Route
                      path="/private/download"
                      element={!user && <DownloadOwner />}
                    />
                    <Route
                      path="/private/mon-compte"
                      element={<AccountOwner />}
                    />

                    <Route
                      path="/private/calibration"
                      element={
                        <UserInviteProvider>
                          <CabineProvider>{ <Calibration />}</CabineProvider>
                        </UserInviteProvider>
                      }
                    />

                    <Route
                      path="/private/team"
                      element={
                        <UserInviteProvider>
                          <CabineProvider>{!user && <Team />}</CabineProvider>
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/abonnements"
                      element={
                        <UserInviteProvider>
                          <CabineProvider>
                            {!user && <ManageCabines />}
                          </CabineProvider>
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <SuperAdmin />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin/manageUsers"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <ManageGroup />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin/manageUserPreset"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <Preset />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin/manageMics"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <ManageMics />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin/diracLicence"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <DiracLicence />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin/manageCertificat"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <ManageCertificat />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    <Route
                      path="/private/superAdmin/statistics"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <Statistics />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    />
                    {/* <Route
                      path="/private/superAdmin/sendEmail"
                      element={
                        <UserInviteProvider>
                          {displayOwnerWithoutCabine ? (
                            <OwnerWithoutCab />
                          ) : (
                            <CabineProvider>
                              {superAdmin && <EmailSenderTemplate />}
                            </CabineProvider>
                          )}
                        </UserInviteProvider>
                      }
                    /> */}

                    <Route path="/private/faq" element={<Faq />} />
                    {/* <Route path="/private/supportHS" element={<SupportHS />} /> */}
                    <Route
                      path="/private/supportHS/*"
                      element={superAdmin && <SupportHSRouter />}
                    />

                    <Route path="/private/messages" element="" />
                  </Route>
                </Routes>
              </AnimatePresence>
            </>
          }
        />
      </NavContext.Provider>
    </div>
  );
}

export default App;
