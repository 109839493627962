//STYLES
import styles from "./RightNavbar.module.scss";
import React, { useState, useEffect, useRef } from "react";
//HOOKS
import { useContext } from "react";



//ICONS , IMAGES
import { MdOutlineMenu } from "react-icons/md";

//Components
import MyProfile from "./Submenus/MyProfile";
import Support from "./Submenus/Support";

//IMG
import logo from "../../pics/logo-hearingspace.png";
import Axios from "../../_services/caller.service";
import NavContext from "../../context/NavContext";
import InfosConnexion from "../superAdmin/infos/InfosConnexion";

const RightNavbar = ({ socket }) => {
  const { nav, setNav } = useContext(NavContext);
  const [displayConnectingUser, setDisplayConnectingUser] = useState(false);
  const [ownerLink, setOwnerLink] = useState(""); // stockes l'id du owner qui est aussi user d'un autre owner
  const [cabineAccess, setCabineAccess] = useState(true);
  const [displaySelectConnexion, setDisplaySelectConnexion] = useState(false);
  const [infosUser, setInfosUser] = useState([]);
  const [userType, setUserType] = useState("");
  const [userIsOwner, setUserIsOwner] = useState(false);
  const [
    displayConnectingUserWithCabineSearch,
    setDisplayConnectingUserWithCabineSearch,
  ] = useState(false);
  const [inviteOwnerId, setInviteOwnerId] = useState(""); // stockes l'id du owner de l'invité
  //CONTEXT
  const {
    ownerId,
    setOwnerId,

    setDisplayOwnerWithoutCabine,
  } = useContext(NavContext);



 // GET INFORMATION IF USER IS AN OWNER
 useEffect(() => {
  setDisplayConnectingUser(false);
  setCabineAccess(true);

  Axios.get(`/relationUser/${ownerId}`)
    .then(function async(response) {
      setDisplaySelectConnexion(true);

      const user = response.data.data.user;
      const ownerLink = response.data.data.owner; //les owner link
      const userTypeReceive = response.data.data.userType;
      setInfosUser(user);
      setOwnerLink(ownerLink);
      setUserType(userTypeReceive);

      if (displayConnectingUserWithCabineSearch) {
        setDisplayConnectingUser(true);
      }

      if (
        userTypeReceive !== "Owner et user d'un autre owner" &&
        userTypeReceive !== "Utilisateur des cabines d'un owner"
      ) {
        setDisplayConnectingUser(true);
      }

      if (userTypeReceive === "Aucun accès à une cabine") {
        setCabineAccess(false);
      }
    })
    .catch(function (error) {
      //  console.log(error);
    });
 
}, [ownerId]);



  
  return (
    <div className={styles.container}>
     {displayConnectingUser && cabineAccess && (
            <InfosConnexion
              infosUser={infosUser}
              setOwnerId={setOwnerId}
              userIsOwner={userIsOwner}
              displayConnectingUser={displayConnectingUser}
              setDisplayConnectingUser={setDisplayConnectingUser}
              inviteOwnerId={inviteOwnerId}
              ownerLink={ownerLink}
              ownerId={ownerId}
            />
          )}
      <img className={styles.centralLogo} src={logo} alt="" />

  

      <div
        className={styles.burger_container}
        onClick={() => {
          setNav(!nav);
        }}
      >
        <MdOutlineMenu />
      </div>

      {/* ACTIONS */}
      <div className={styles.actions}>{/* <Support /> */}</div>
      {process.env.REACT_APP_API_URL.includes("localhost") && (
        <span>LOCALHOST</span>
      )}
      {process.env.REACT_APP_API_URL.includes("beta") && <span>BETA</span>}

      {/* My Profile */}
      <MyProfile />
    </div>
  );
};

export default RightNavbar;
