import React, { useContext, useEffect, useState } from "react";
import styles from "./ManageCabines.module.scss";
import Axios from "../../_services/caller.service";
import NavContext from "../../context/NavContext";
import InputForms from "../UiComponents/forms/InputForms";
import { FiRefreshCcw } from "react-icons/fi";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Button from "../UiComponents/Button";
import HeaderManageCabines from "./HeaderManageCabines";
import { ToastContainer, toast } from "react-toastify";
const ManageCabines = () => {
  const { ownerId } = useContext(NavContext);
  //Cabine
  const [cabines, setCabines] = useState([]);
  const [updateCabines, setUpdateCabines] = useState({});

  //ABO
  const [selectedAbo, setSelectedAbo] = useState("all");
  const uniqueAboIds = [
    ...new Set(cabines.map((item) => item.subscription_identify)),
  ];
  // RECHERCHE ET FILTRE
  const [searchTerm, setSearchTerm] = useState(""); // Pour la recherche cabine
  const [filteredCabines, setFilteredCabines] = useState(cabines);

  const [maj, setMaj] = useState(false); // utile pour recharger les cabines

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);
  // CHANGE NAME CABINE
  const [cabineNames, setCabineNames] = useState({});
  //TRIER
  const [sorted, setSorted] = useState({ field: null, order: null });

  const handleInputChange = (cabineId, newName) => {
    setCabineNames((prevState) => ({
      ...prevState,
      [cabineId]: newName,
    }));
  };

  //GET USER
  useEffect(() => {
    Axios.get(`/cabinesByAboIds/${ownerId}`)
      .then((response) => {
        setCabines(response.data.data);
        setFilteredCabines(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [maj]);

  //UPDATE CABNAME
  const handleUpdateCabine = (cabineId) => {
    const newName = cabineNames[cabineId];

    if (!newName) {
      console.error("Aucun nouveau nom saisi pour cette cabine.");
      return;
    }

    Axios.put(`/updateCabineName/${ownerId}`, {
      id: cabineId,
      newName: newName,
    })
      .then((response) => {
        console.log(response.data.message);
        toast.success("Le nom de la cabine a été mise à jour");
        // Après la mise à jour réussie, réinitialisez le nom dans l'état local
        setCabineNames((prevState) => {
          const newState = { ...prevState };
          delete newState[cabineId];
          return newState;
        });
        // Marquer la cabine comme mise à jour avec succès

        // Mettez à jour le nom de la cabine dans l'état local
        updateLocalCabineName(cabineId, newName);
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour de la cabine:", error);
        toast.error("Une erreur est survenue");
      });
  };

  const filterAndSearchCabines = () => {
    let result = [...cabines];

    if (selectedAbo !== "all") {
      result = result.filter(
        (cab) => cab.subscription_identify.toString() === selectedAbo
      );
    }

    if (searchTerm) {
      result = result.filter((cab) => {
        return (
          cab.cabname.toLowerCase().includes(searchTerm.toLowerCase()) ||
          cab.id.toString().includes(searchTerm)
        );
      });
    }

    setFilteredCabines(result);
  };

  //UPDATE CABNAME
  //sous fonction
  const updateLocalCabineName = (cabineId, newName) => {
    const updatedCabines = cabines.map((cab) => {
      if (cab.id === cabineId) {
        return { ...cab, cabname: newName };
      }
      return cab;
    });

    setCabines(updatedCabines);
  };

  useEffect(() => {
    filterAndSearchCabines();
  }, [selectedAbo, searchTerm, cabines]);

  //REFRESH FILTRE
  const refreshfilter = () => {
    setSearchTerm("");
    setFilteredCabines(cabines);
    setSorted({ field: null, order: null });
    setUsersPerPage(10);
    setSelectedAbo("all");
  };

  //  TRI
  const handleSort = (field, order) => {
    // Si l'utilisateur clique à nouveau sur le même chevron
    if (sorted.field === field && sorted.order === order) {
      setSorted({ field: null, order: null });
      // Utilisez une copie de vos données originales ici
      setFilteredCabines(cabines);
      return;
    }

    setSorted({ field, order });

    const sortedCabines = [...filteredCabines].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      } else {
        return order === "asc" ? a[field] - b[field] : b[field] - a[field];
      }
    });

    setFilteredCabines(sortedCabines);
  };

  //PAGINATION

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const currentCabines = filteredCabines.slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  const pageNumbers = [];
  // Juste après avoir calculé currentUsers
  const totalPages = Math.ceil(filteredCabines.length / usersPerPage);
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerUser}>
          <div
            className={styles.containerSearchAndFilter}
            id={styles.inputSearchUser}
          >
            <InputForms
              id={styles.inputSearchAudio}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                filterAndSearchCabines();
              }}
              value={searchTerm}
              placeholder="Rechercher"
            />

            <div className={styles.containerFilter}>
              <select
                value={usersPerPage}
                onChange={(e) => {
                  setUsersPerPage(e.target.value);
                  setCurrentPage(1); // réinitialiser la page actuelle
                }}
              >
                {[5, 10, 20, 30, 50].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>

              {/* Sélecteur d'abonnement */}
              <select
                value={selectedAbo}
                onChange={(e) => {
                  setSelectedAbo(e.target.value);
                }}
              >
                <option value="all">Tous les abonnements</option>
                {uniqueAboIds.map((id) => (
                  <option key={id} value={id}>
                    {id}
                  </option>
                ))}
              </select>

              <FiRefreshCcw
                onClick={refreshfilter}
                className={styles.refresh}
                style={{
                  color: "#6d5dfc",
                  fontSize: "30px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <HeaderManageCabines setSorted={handleSort} sorted={sorted} />

          {/* ********************************************************************************* */}
          <ul>
            {currentCabines.map((cab, index) => (
              <li
                key={index}
                className={styles.containerInfosCabines}
                // style={{
                //   backgroundColor: MICRO_COLORS[mic.mictype],
                // }}
              >
                <span className={styles.id}>{cab.id}</span>
                <span className={styles.sub}>{cab.subscription_identify}</span>
                <span className={styles.cabname}>{cab.cabname}</span>

                <InputForms
                  id={styles.inputCab}
                  value={cabineNames[cab.id] || ""}
                  onChange={(e) => handleInputChange(cab.id, e.target.value)}
                />
                <div className={styles.btnDesktop}>
                  <Button
                    onPress={() => handleUpdateCabine(cab.id)}
                    text={"enregistrer"}
                    styles={"btnValidation"}
                  />
                </div>
                <div className={styles.btnMobile}>
                  <AiOutlineCheckCircle
                    style={{ color: "#CCFFCB", fontSize: "30px" }}
                    onClick={() => handleUpdateCabine(cab.id)}
                  />
                </div>
              </li>
            ))}
          </ul>

          {/* //PAGINATION */}
          <div className={styles.containerPagination}>
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            >
              Première
            </button>
            <button
              onClick={() => {
                if (currentPage > 1) setCurrentPage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            >
              Précédent
            </button>
            <button
              onClick={() => {
                if (currentPage < pageNumbers.length)
                  setCurrentPage(currentPage + 1);
              }}
              disabled={currentPage === pageNumbers.length}
            >
              Suivant
            </button>

            <button
              onClick={() => setCurrentPage(pageNumbers.length)}
              disabled={currentPage === pageNumbers.length}
            >
              Dernière
            </button>
          </div>
          <div className={styles.numberPage}>
            Page {currentPage} sur {pageNumbers.length}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCabines;
