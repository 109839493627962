import React from 'react';
import styles from './AudioHeadphoneGuideCalibration.modules.scss'

const AudioHeadphone = () => {
    return (
        <div>
          Pour calibrer le casque de l'opérateur il faut brancher le casque sur la prise casque de l'ordinateur, il va apparâitre dans la liste des carte son. Calibration approximative: on ne connait pas le casque et sa qualité. utilisation avec un micro dans fil amazon par ex.
        </div>
    );
};

export default AudioHeadphone;