import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./ModalConfirmRegisterNewValue.module.scss";
import Button from "../../../UiComponents/Button";

const ModalConfirmRegisterNewValue = ({
  setOverlay,

  isCheckedDateUpdate,
  checkHandler,
  setModalConfirmRegisterNewValue,
  registerNewValue,
}) => {
  return (
    <div className={styles.modalConfirm}>
      <AiOutlineClose
        className={styles.closeBtnWarningOutpout}
        onClick={() => {
          setOverlay(false);
          setModalConfirmRegisterNewValue(false);
        }}
      />
      <h3>Souhaitez-vous enregistrer les nouvelles valeurs ?</h3>
      <div className={styles.useCurrentDate}>
        <input
          type="checkbox"
          checked={isCheckedDateUpdate}
          onChange={checkHandler}
        />
        <label htmlFor="">Mettre à jour avec la date du jour</label>
      </div>
      <div className={styles.btnConfirmModal}>
        <Button
          onPress={() => {
            setModalConfirmRegisterNewValue(false);
            setOverlay(false);
          }}
          styles={"btnDelete"}
          text={"Revenir au données précédente"}
        />
        <Button
          onPress={registerNewValue}
          styles={"btnValidation"}
          text={"j'enregistre"}
        />
      </div>
    </div>
  );
};

export default ModalConfirmRegisterNewValue;
