import React from "react";
import Button from "../../UiComponents/Button";
import styles from "./InfosUser.module.scss";
import { RiAdminFill, RiUserAddFill, SiAirplayaudio } from "react-icons/si";

const InfosCabine = ({
  ownerName,
  email,
  login,
  date,
  inviteOwnerId,
  setOwnerId,
  setdisplayInfosOwnerLinkCab,
  setDisplayConnectingUserWithCabineSearch,
}) => {
  return (
    <div className={styles.containerInfosUser}>
      <div className={styles.containerInviteInfos}>
        <div className={styles.invite}>
          <div className={styles.title}>
            <SiAirplayaudio />
            <h3>Cette cabine est lié à l'owner donc voici les informations:</h3>
          </div>

          <div className={styles.infos}>
            <div>
              <span>Nom:</span> {ownerName}
            </div>
            <div>
              <span>Email:</span> {email}
            </div>
            <div>
              <span>Login:</span> {login}
            </div>
            <div>
              <span>Client depuis le:</span> {date}
            </div>
          </div>
          <Button
            onPress={() => {
              setOwnerId(inviteOwnerId);
              setdisplayInfosOwnerLinkCab(false);
              setDisplayConnectingUserWithCabineSearch(true);
            }}
            styles={"btnValidation"}
            text={"Se connecter "}
          />
        </div>
      </div>
    </div>
  );
};

export default InfosCabine;
