// export default ManageGroup;
import React, { useEffect, useState } from "react";
import Axios from "../../../_services/caller.service";
import styles from "./ManageGroup.module.scss";
import { GROUP_TYPES, RESTRICTIONS, GROUP_COLORS, PERMISSIONS } from "./config";
import HeaderManageGroup from "./HeaderManageGroup";
import InputForms from "../../UiComponents/forms/InputForms";
import { FiRefreshCcw } from "react-icons/fi";
import { BsExclamationTriangleFill } from "react-icons/bs";
import Button from "../../UiComponents/Button";
import Modal from "../../UiComponents/modals/Modal";
import useModal from "../../../hooks/useModal";
import Success from "../../UiComponents/sucess/Success";
import EmailResetPassword from "./EmailResetPassword";
const ManageGroup = () => {
  //USERS
  const [users, setUsers] = useState([]);

  // RECHERCHE ET FILTRE
  const [searchTerm, setSearchTerm] = useState(""); // Pour la recherche d'utilisateur

  const [filteredUsers, setFilteredUsers] = useState(users);

  const [selectedGroup, setSelectedGroup] = useState("all");
  const [selectedRestriction, setSelectedRestriction] = useState("all");
  const [selectedPermission, setSelectedPermission] = useState("all");

  const [maj, setMaj] = useState(false); // utile pour recharger les users

  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);

  const [sorted, setSorted] = useState({ field: null, order: null });

  //CHANGER NOM D'UN GROUPE
  const [selectedGroupToRename, setSelectedGroupToRename] = useState("");
  const [newGroupName, setNewGroupName] = useState("");
  //MODAL POUR CHANGEMENT DE GROUP AVERTISSEMENT
  const { isShowing, toggle } = useModal();

  //GET USER
  useEffect(() => {
    Axios.get("/getUserGroup")
      .then((response) => {
        const sortedUsers = response.data.users.sort((a, b) => {
          const isAUnknown = !GROUP_TYPES.includes(a["group_type"]);
          const isBUnknown = !GROUP_TYPES.includes(b["group_type"]);

          if (isAUnknown && !isBUnknown) {
            return -1; // Place A avant B
          } else if (!isAUnknown && isBUnknown) {
            return 1; // Place B avant A
          } else {
            // Si les deux utilisateurs ont un group-type connu ou inconnu,
            // les trier par leur login
            return a.user_login.localeCompare(b.user_login);
          }
        });

        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [maj]);

  // MISE A JOUR DU GROUPE
  const assignToGroup = (userId, groupName) => {
    Axios.post("/updateUserGroup", {
      userId: userId,
      groupName: groupName,
    })
      .then((response) => {
        if (response.data.success) {
          // Mise à jour de l'état local après avoir modifié le groupe
          const updatedUsers = [...filteredUsers];
          const userIndex = updatedUsers.findIndex(
            (user) => user.id === userId
          );

          // Assigner le groupe à l'utilisateur
          updatedUsers[userIndex].group_type = groupName;

          setFilteredUsers(updatedUsers);
        } else {
          console.error(
            "Erreur lors de la mise à jour côté serveur:",
            response.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour:", error);
      });
  };

  // RESTRICTIONS

  const isRestricted = (restrictions, restriction) => {
    if (!restrictions) return false;
    const restrictionArray = restrictions.split(",");
    return restrictionArray.includes(restriction);
  };
  const toggleRestriction = (userId, restriction) => {
    Axios.post("/updateUserRestriction", {
      userId: userId,
      restrictionAction: restriction,
    })
      .then((response) => {
        if (response.data.success) {
          // Mise à jour de l'état local après modification des restrictions
          const updatedUsers = [...filteredUsers];
          const userIndex = updatedUsers.findIndex(
            (user) => user.id === userId
          );
          const currentRestrictions = updatedUsers[userIndex].restriction
            ? updatedUsers[userIndex].restriction.split(",")
            : [];

          // Si la restriction est déjà présente, la supprimer de la liste, sinon l'ajouter.
          if (currentRestrictions.includes(restriction)) {
            updatedUsers[userIndex].restriction = currentRestrictions
              .filter((r) => r !== restriction)
              .join(",");
          } else {
            updatedUsers[userIndex].restriction = [
              ...currentRestrictions,
              restriction,
            ].join(",");
          }

          // Si la liste des restrictions est vide, réinitialiser à une chaîne vide
          if (!updatedUsers[userIndex].restriction) {
            updatedUsers[userIndex].restriction = "";
          }

          setFilteredUsers(updatedUsers);
        } else {
          console.error(
            "Erreur lors de la mise à jour côté serveur:",
            response.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour:", error);
      });
  };

  //PERMISSION
  const hasPermission = (permissions, permission) => {
    if (!permissions) return false;
    const permissionArray = permissions.split(",");
    return permissionArray.includes(permission);
  };

  const togglePermission = (userId, permission) => {
    Axios.post("/updateUserPermisison", {
      userId: userId,
      permissionAction: permission,
    })
      .then((response) => {
        if (response.data.success) {
          const updatedUsers = [...filteredUsers];
          const userIndex = updatedUsers.findIndex(
            (user) => user.id === userId
          );
          const currentPermissions = updatedUsers[userIndex].permission
            ? updatedUsers[userIndex].permission.split(",")
            : [];

          if (currentPermissions.includes(permission)) {
            updatedUsers[userIndex].permission = currentPermissions
              .filter((p) => p !== permission)
              .join(",");
          } else {
            updatedUsers[userIndex].permission = [
              ...currentPermissions,
              permission,
            ].join(",");
          }

          if (!updatedUsers[userIndex].permission) {
            updatedUsers[userIndex].permission = "";
          }

          setFilteredUsers(updatedUsers);
        } else {
          console.error(
            "Erreur lors de la mise à jour côté serveur:",
            response.data.message
          );
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour:", error);
      });
  };

  //MISE A JOUR NOM DE GROUPE
  const handleRenameGroup = () => {
    if (!selectedGroupToRename || !newGroupName) {
      alert("Veuillez sélectionner un groupe et fournir un nouveau nom.");
      return;
    }

    Axios.post("/renameGroup", {
      oldGroupName: selectedGroupToRename,
      newGroupName: newGroupName,
    })
      .then((response) => {
        if (response.data.success) {
          alert("Le groupe a été renommé avec succès!");
          // Réinitialisez les sélecteurs
          setSelectedGroupToRename("");
          setNewGroupName("");
          setSelectedGroup("all");
          setSelectedRestriction("all");
          setSorted({ field: null, order: null });
          setMaj(!maj); //permet de relancer la liste des users dependance useEffect

          toggle();
        } else {
          alert(
            "Erreur lors de la mise à jour côté serveur: " +
              response.data.message
          );
          toggle();
        }
      })
      .catch((error) => {
        alert("Erreur lors du renommage du groupe: " + error);
      });
  };

  //FILTRE DE RECHERCHE
  const handleFilterChange = () => {
    let newFilteredUsers;

    if (
      selectedGroup === "all" &&
      selectedRestriction === "all" &&
      selectedPermission === "all"
    ) {
      newFilteredUsers = [...users]; // return all users if no filters are applied
    } else {
      newFilteredUsers = users.filter((user) => {
        return (
          (selectedGroup !== "all"
            ? user.group_type === selectedGroup
            : true) &&
          (selectedRestriction !== "all"
            ? user.restriction && user.restriction.includes(selectedRestriction)
            : true) &&
          (selectedPermission !== "all"
            ? user.permission && user.permission.includes(selectedPermission)
            : true)
        );
      });
    }

    setFilteredUsers(newFilteredUsers);
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedGroup, selectedRestriction, users, selectedPermission]);

  //  TRI

  const handleSort = (field, order) => {
    // Si l'utilisateur clique à nouveau sur le même chevron
    if (sorted.field === field && sorted.order === order) {
      setSorted({ field: null, order: null });
      // Utilisez une copie de vos données originales ici
      setFilteredUsers(users);
      return;
    }

    setSorted({ field, order });

    const sortedUsers = [...filteredUsers].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      } else {
        return order === "asc" ? a[field] - b[field] : b[field] - a[field];
      }
    });

    setFilteredUsers(sortedUsers);
  };

  //REFRESH FILTRE
  const refreshfilter = () => {
    setMaj(!maj);
  };

  //RECHERCHE
  const searchUsers = (users, term) => {
    return users.filter((user) => {
      return (
        String(user.id).includes(term) ||
        (user.display_name &&
          user.display_name.toLowerCase().includes(term.toLowerCase())) ||
        (user.user_login &&
          user.user_login.toLowerCase().includes(term.toLowerCase()))
      );
    });
  };
  const handleSearch = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    const searchedUsers = searchUsers(users, term);
    setFilteredUsers(searchedUsers);
  };

  //PAGINATION

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  // Juste après avoir calculé currentUsers
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const toggleModal = () => {
    if (!selectedGroupToRename || !newGroupName) {
      alert("Veuillez sélectionner un groupe et fournir un nouveau nom.");
      return;
    }

    toggle();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerUser}>
          <div
            className={styles.containerSearchAndFilter}
            id={styles.inputSearchUser}
          >
            <InputForms
              id={styles.inputSearchAudio}
              onChange={handleSearch}
              value={searchTerm}
              placeholder="Rechercher"
            />
            <div className={styles.selectGroup}>
            <select
              value={usersPerPage}
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(1); // réinitialiser la page actuelle
              }}
            >
              {[5, 10, 20, 30, 50].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>

            {/* Sélecteur de groupe */}
            <select
              value={selectedGroup}
              onChange={(e) => {
                setSelectedGroup(e.target.value);
                handleFilterChange();
              }}
            >
              <option value="all">Tout les groupes</option>
              {GROUP_TYPES.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
            <select
              value={selectedRestriction}
              onChange={(e) => {
                setSelectedRestriction(e.target.value);
                handleFilterChange();
              }}
            >
              <option value="all">Toutes les restrictions</option>
              {RESTRICTIONS.map((restriction) => (
                <option key={restriction} value={restriction}>
                  {restriction}
                </option>
              ))}
            </select>
            <select
              value={selectedPermission}
              onChange={(e) => {
                setSelectedPermission(e.target.value);
                handleFilterChange();
              }}
            >
              <option value="all">Toutes les permisions</option>
              {PERMISSIONS.map((permission) => (
                <option key={permission} value={permission}>
                  {permission}
                </option>
              ))}
            </select>
            <FiRefreshCcw
              onClick={refreshfilter}
              style={{ color: "#6d5dfc", fontSize: "30px", cursor: "pointer" }}
            />
            </div>
          </div>
          <HeaderManageGroup setSorted={handleSort} sorted={sorted} />

          <ul>
            {currentUsers.map((user) => (
              <li
                key={user.id}
                className={`${styles.userItem} ${
                  !GROUP_TYPES.includes(user.group_type) ? styles.error : ""
                }`}
                style={{
                  backgroundColor: !GROUP_TYPES.includes(user.group_type)
                    ? "red"
                    : GROUP_COLORS[user.group_type || "principale"],
                }}
              >
                <div className={styles.userData}>
                  <span className={styles.userId}>{user.id}</span>
                  <span className={styles.userLogin}> {user.user_login}</span>
                  <span className={styles.userName}> {user.display_name}</span>
                  <div className={styles.email}>
                    <EmailResetPassword
                      email={user.user_email}
                      userLogin={user.user_login}
                    />
                  </div>
                </div>

                {/* SELECT GROUP */}
                <div className={styles.group}>
                  <select
                    value={user.group_type || "principale"}
                    onChange={(e) => assignToGroup(user.id, e.target.value)}
                  >
                    {GROUP_TYPES.map((group) => (
                      <option key={group} value={group}>
                        {group}
                      </option>
                    ))}
                  </select>
                </div>
                <div className={styles.restrictions}>
                  {RESTRICTIONS.map((restriction) => (
                    <button
                      key={restriction}
                      className={
                        isRestricted(user.restriction, restriction)
                          ? styles.btnRed
                          : styles.btnGreen
                      }
                      onClick={() => toggleRestriction(user.id, restriction)}
                    >
                      {restriction}
                    </button>
                  ))}
                </div>
                <div className={styles.permissions}>
                  {PERMISSIONS.map((permission) => (
                    <button
                      key={permission}
                      className={
                        hasPermission(user.permission, permission)
                          ? styles.btnGreen // Si l'utilisateur a l'autorisation
                          : styles.btnRed // Si l'utilisateur n'a pas l'autorisation
                      }
                      onClick={() => togglePermission(user.id, permission)}
                    >
                      {permission}
                    </button>
                  ))}
                </div>

                {/* si erreur dans la db pour un user ( exemple il a group_typ qui n'existe pas ou plus) */}
                <span>
                  {" "}
                  {!GROUP_TYPES.includes(user.group_type) && <span>⚠️</span>}
                </span>
              </li>
            ))}
          </ul>

          {/* //PAGINATION */}
          <div className={styles.containerPagination}>
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            >
              Première
            </button>
            <button
              onClick={() => {
                if (currentPage > 1) setCurrentPage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            >
              Précédent
            </button>
            <button
              onClick={() => {
                if (currentPage < pageNumbers.length)
                  setCurrentPage(currentPage + 1);
              }}
              disabled={currentPage === pageNumbers.length}
            >
              Suivant
            </button>

            <button
              onClick={() => setCurrentPage(pageNumbers.length)}
              disabled={currentPage === pageNumbers.length}
            >
              Dernière
            </button>
          </div>
          <div className={styles.numberPage}>
            Page {currentPage} sur {pageNumbers.length}
          </div>
        </div>
      </div>
      {/* FONCTION POUR RENOMMER UN GROUPE */}
      <div className={styles.renameGroupContainer}>
        <select
          value={selectedGroupToRename}
          onChange={(e) => setSelectedGroupToRename(e.target.value)}
        >
          <option value="">Sélectionner un groupe à renommer</option>
          {GROUP_TYPES.filter((group) => group !== "principale").map(
            (group) => (
              <option key={group} value={group}>
                {group}
              </option>
            )
          )}
        </select>

        <InputForms
          onChange={(e) => setNewGroupName(e.target.value)}
          placeholder={"nouveau nom"}
          value={newGroupName}
          type={"text"}
        />
        <Button
          onPress={toggleModal}
          text={"renommer"}
          styles={"btnValidation"}
        />
      </div>

      {/* MODAL AVERTISSEMENT CHANGEMENT DE NOM */}
      <Modal isShowing={isShowing} hide={toggle}>
        <div className={styles.containerModal}>
          <BsExclamationTriangleFill
            style={{ fontSize: "55px", color: "red" }}
          />

          <div className={styles.contentAvertissement}>
            <div>
              Vous êtes sur le point de renommer le nom du groupe{" "}
              <span>{selectedGroupToRename}</span> pour le nouveau nom{" "}
              <span>{newGroupName}</span>. Une fois effectué cela renommera dans
              la base de données (table users_group) tous les users dont le
              group_types est <span>{selectedGroupToRename}</span> pour{" "}
              <span>{newGroupName}</span>.
            </div>{" "}
            <div>
              Une fois cette Action effectuée il faut modifier le tableau
              GROUP_TYPES dans la partie Front-End =={" "}
              <strong>
                dans src\components\manageUser\manageUserGroup\config.js{" "}
              </strong>
              . Il faut remplacer dans le tableau{" "}
              <span>{selectedGroupToRename}</span> par{" "}
              <span>{newGroupName}</span>.
            </div>
          </div>
        </div>
        <div className={styles.btnUpdate}>
          <Button
            type={"button"}
            text={"Annuler"}
            onPress={handleRenameGroup}
            styles={"btnDelete"}
          />
          <Button
            type={"button"}
            text={"Renommer"}
            onPress={handleRenameGroup}
            styles={"btnValidation"}
          />
        </div>
      </Modal>
    </>
  );
};

export default ManageGroup;
