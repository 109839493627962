import React, { useState } from "react";
import Button from "../../UiComponents/Button";
import styles from "./ModalSoundCard.module.scss";
import { AiOutlineClose } from "react-icons/ai";
import { encodedB64 } from "../../../CustomHooks/DecodedBase64";
import Axios from "../../../_services/caller.service";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";

const ModalSoundCard = ({
  transductorNameForUpdate,
  setDisplayModalConfirm,
  updateSoundCard,
  cabId,
  cabname,
  setMaj,
  maj,
}) => {
  const [loading, setLoading] = useState(false);
  const [sucess, setSuccess] = useState(false);
  // ICI on remplace transductor par transductorNameForUpdate
  const registerNewCardSound = async (transductor) => {
    const soundCardSorted = await sortGoodTypeTransductor(transductor);
    //setLoading(true);
    const soundCardNameB64 = encodedB64(soundCardSorted);

    if (soundCardNameB64 === "") {
      // if No changement we do nothing
      setLoading(false);
      setSuccess(true);
      setMaj(!maj);
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
      return;
    }
    //create catid if they not exist
    Axios.post(`/createCatid/${cabId}`, {})

      .then((response) => {
        Axios.put(`/updateSoundCardName/${cabId}`, {
          data: { transductor: transductor, soundCardName: soundCardNameB64 },
        })

          .then((response) => {
            setLoading(false);
            setSuccess(true);
            setMaj(!maj);
            setTimeout(() => {
              setDisplayModalConfirm(false);
              setSuccess(false);
            }, 1500);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sortGoodTypeTransductor = (transductor) => {
    switch (transductor) {
      case "CO":
        return updateSoundCard.CO;

      case "Casque":
        return updateSoundCard.Casque;

      case "Inserts":
        return updateSoundCard.Inserts;

      case "HSSoundcardUse":
        return updateSoundCard.HSSoundcardUse;

      case "DiracSoundcardUse":
        return updateSoundCard.DiracSoundcardUse;

      case "Operator":
        return updateSoundCard.Operator;

      default:
        return "";
    }
  };

  return (
    <>
      <div
        onClick={() => setDisplayModalConfirm(false)}
        className={styles.wrapperModal}
      ></div>
      <div className={styles.containerModal}>
        <div className={styles.containerInfos}>
          <h3>
            Cabine: <span>{cabname}</span>
          </h3>
          <h3>
            Sortie: <span>{transductorNameForUpdate}</span>
          </h3>
          <h3>
            Nom de la carte:
            <span>
              {sortGoodTypeTransductor(transductorNameForUpdate)}
            </span>{" "}
          </h3>
        </div>

        <AiOutlineClose
          style={{ fontSize: "25px", cursor: "pointer" }}
          onClick={() => setDisplayModalConfirm(false)}
        />
        <div className={styles.containerBtn}>
          <Button
            //  onPress={() => setTransductorMaj(transductor)}
            onPress={() => setDisplayModalConfirm(false)}
            styles={"btnDelete"}
            text={"Annuler"}
          />
          <Button
            //  onPress={() => setTransductorMaj(transductor)}
            onPress={() => registerNewCardSound(transductorNameForUpdate)}
            styles={"btnValidation"}
            text={"ok"}
          />
        </div>
        {loading && <Loader />}
        <div className={styles.containerReponse}>
          {sucess && <Success success={"Carte son MAJ"} />}
        </div>
      </div>
    </>
  );
};

export default ModalSoundCard;
