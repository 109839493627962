import React from "react";
import styles from "./InfosInviteUser.module.scss";




const InfosInviteUser = () => {
  return (
    <div className={styles.container}>
      <h3>
        Pour qu'un utilisateur de votre équipe puisse avoir son propre
        compte Hearing Space,
     vous devez remplir le formulaire d'invitation qui lui enverra un email.
     
     
      </h3>
      
   
      
   
      
    </div>
  );
};

export default InfosInviteUser;
