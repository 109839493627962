import React from 'react';
import CardHeaderPage from '../../UiComponents/card/CardHeaderPage';
import HeaderNavSupportHS from '../headerNav/HeaderNavSupportHS';

const VRGuide = () => {
    return (
        <div>
         <HeaderNavSupportHS/>
            <CardHeaderPage text={'Guide pour installation du casque virtuel Oculus'} textAlign={'center'}/>
        </div>
    );
};

export default VRGuide;