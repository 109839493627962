import React, { useEffect, useState } from "react";
import axios from "axios";

import AlreadyLinked from "./layout/AlreadyLinked";
import LinkToSelf from "./layout/LinkToSelf";
import JwtExpired from "./layout/JwtExpired";
import JwtError from "./layout/JwtError";
import SuccessLinked from "./layout/SuccessLinked";
import CreateAccount from "./layout/CreateAccount";

import LoginInvite from "./layout/LoginInvite";
import Axios from "../../_services/caller.service";
import styles from "./UserAccountLinker.module.scss";
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";
import logo from "../../pics/logo-hearingspace.png";
import { MdAccountCircle } from "react-icons/md";
import { MdOutlineAssignmentInd } from "react-icons/md";
import Button from "../UiComponents/Button";
const UserAccountLinker = () => {
  //TOKEN URL
  const [token, setToken] = useState(false); //token verify true or false
  const [jwtExpired, setJwtExpired] = useState(false); // if jwt is expired
  const [jwtError, setJwtError] = useState(false); // if jwt not good
  const [jwtAlreadyUSe, setJwtAlreadyUSe] = useState(false); // if invite link already use
  // USER
  const [emailUser, setEmailUser] = useState(""); //user mail
  const [userId, setUserId] = useState(""); // user's invite ID
  const [userName, setUserName] = useState("");

  //NAME AND LASTNAME IN TOKEN
  const [nameUser, setNameUser] = useState("");
  const [lastNameUser, setLastNameUser] = useState("");
  //OWNER
  const [ownerId, setOwnerId] = useState(0); // owner id
  const [senderOwner, setSenderOwner] = useState(""); //name owner
  const [emailOwner, setEmailOwner] = useState(""); //name owner
  const [hashId, setHashId] = useState(""); //hash == n° unique pour être encodé dans le payload token pour être sur de supprimer l'email dans invitList

  //FOR EMAIL SUCCESS
  const [nameForEmailSuccess, setNameForEmailSuccess] = useState("");
  const [lastNameForEmailSuccess, setLastNameForEmailSucess] = useState("");

  //CONNEXION STATUS
  const [confirmLinked, setConfirmLinked] = useState(false); // user in email is the same to userConnect
  const [successLinked, setSuccessLinked] = useState(false);

  //After User create an account
  const [accountCreate, setAccountCreate] = useState(false);

  // SIGNIN STATE
  const [userMailSignIn, setUserMailSignIn] = useState("");
  const [userPasswordSignIn, setUserPasswordSignIn] = useState("");

  //ERROR
  const [linkToSelf, setLinkToSelf] = useState(false); // userConnection dismatch with email url link
  const [alreadyLinked, setAlreadyLinked] = useState(false);

  //REGISTER
  const [displaySignUpModal, setDisplaySignUpModal] = useState(false);
  //DISPLAY
  const [displayLogin, setDisplayLogin] = useState(false);
  const [displayNewAccount, setDisplayNewAccount] = useState(false);

  // TOKEN LINK INVITATION
  const tokenInviteLogin = localStorage.getItem("invite");

  // VERIFY TOKEN IN URL

  
  useEffect(() => {
    let params = new URLSearchParams(document.location.search);

    let token = params.get("token"); // token verify in backend

    Axios.post(`/verifyTokenInUrl/`, { token })

      .then(function (response) {
        
        if (response.data.msg === "jwt expired") {
          setJwtExpired(true);
        }
        if (response.data.name === "JsonWebTokenError") {
          setJwtError(true);
        }
        if (response.data.msg === "invite already use") {
          setJwtAlreadyUSe(true);
        } else {
          localStorage.setItem("invite", token);
          setToken(response.data.tokenStatus);
          setEmailUser(response.data.email);
          setOwnerId(response.data.ownerId);
          setSenderOwner(response.data.senderOwner);
          setEmailOwner(response.data.emailOwner);
          setHashId(response.data.hash);

          setNameUser(
            response.data.name[0].toUpperCase() + response.data.name.slice(1)
          );
          setLastNameUser(
            response.data.lastName[0].toUpperCase() +
              response.data.lastName.slice(1)
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // CONFIRM INVITATION  ICI userInviteId est l'id généré lors de la création de compte ou celui déja existant si déja un compte
  const confirmInvitation = (userInviteId) => {
    //NE PAS MODIFIER axios par AXIOS

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/confirmInvitation/${userInviteId}`,
        {
          ownerId,
        },
        {
          headers: { authorization: "Bearer " + tokenInviteLogin },
          withCredentials: true,
        }
      )

      .then(function (response) {
        if (response.data.msg === "already linked") {
          setAlreadyLinked(true);
          axios
            .delete(
              `${process.env.REACT_APP_API_URL}api/deleteInviteList/${emailUser}/${hashId} `,

              {
                headers: { authorization: "Bearer " + tokenInviteLogin },
                withCredentials: true,
              }
            )

            .then(function (response) {
              localStorage.removeItem("invite");
              localStorage.removeItem("inviteInfos");
            })
            .catch(function (error) {
              console.log(error);
              localStorage.removeItem("invite");
              localStorage.removeItem("inviteInfos");
            });
        }
        if (response.data.msg === "link to self") {
          setLinkToSelf(true);

          axios
            .delete(
              `${process.env.REACT_APP_API_URL}api/deleteInviteList/${emailUser}/${hashId} `,

              {
                headers: { authorization: "Bearer " + tokenInviteLogin },
                withCredentials: true,
              }
            )

            .then(function (response) {
              localStorage.removeItem("invite");
              localStorage.removeItem("inviteInfos");
            })
            .catch(function (error) {
              localStorage.removeItem("invite");
              localStorage.removeItem("inviteInfos");
            });
        }
        if (response.data.msg === "success linked") {
          //NE PAS MODIFIER axios par AXIOS
          axios
            .delete(
              `${process.env.REACT_APP_API_URL}api/deleteInviteList/${emailUser}/${hashId} `,

              {
                headers: { authorization: "Bearer " + tokenInviteLogin },
                withCredentials: true,
              }
            )

            .then(function (response) {
              axios
                .post(
                  `${process.env.REACT_APP_API_URL}api/emailSuccessLinked/${userInviteId} `,
                  {
                    data: {
                      emailOwner,
                      userName: nameForEmailSuccess,
                      userLastName: lastNameForEmailSuccess,
                    },
                  },
                  {
                    headers: { authorization: "Bearer " + tokenInviteLogin },
                    withCredentials: true,
                  }
                )

                .then(function (response) {
                  localStorage.removeItem("invite");
                  localStorage.removeItem("inviteInfos");
                })
                .catch(function (error) {
                  console.log(error);
                  localStorage.removeItem("invite");
                  localStorage.removeItem("inviteInfos");
                });
            })
            .catch(function (error) {
              console.log(error);
            });

          setSuccessLinked(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div className={styles.container}>
      <img src={logo} className={styles.logo} alt="" />

      {!successLinked && (
        <CardHeaderPage
          text={`Bienvenue ${nameUser} ${lastNameUser} dans l'espace invitation d'Hearing Space`}
        />
      )}
      {token && !successLinked && !linkToSelf && !alreadyLinked && (
        <div className={styles.containerLoginAndCreate}>
          <div className={styles.infos}>
            <p>
              Si vous avez déja un compte sur Hearing Space connectez-vous avec
              votre compte pour pouvoir vous lier <span>{senderOwner}</span>.
            </p>{" "}
            <p>Autrement crée vous un compte Hearing Space.</p>
          </div>
          <div className={styles.containerBtn}>
            <Button
              text={"Je me connecte"}
              onPress={() => {
                setDisplayLogin(true);
                setDisplayNewAccount(false);
              }}
              styles={"btnPrimary"}
              svg={<MdAccountCircle />}
            />
            <Button
              text={"Je crée un compte"}
              onPress={() => {
                setDisplayNewAccount(true);
                setDisplayLogin(false);
              }}
              styles={"btnPrimary"}
              svg={<MdOutlineAssignmentInd />}
            />
          </div>

          {displayLogin && (
            <LoginInvite
              emailUser={emailUser}
              setConfirmLinked={setConfirmLinked}
              setUserId={setUserId}
              setUserName={setUserName}
              userMailSignIn={userMailSignIn}
              userPasswordSignIn={userPasswordSignIn}
              setUserMailSignIn={setUserMailSignIn}
              setUserPasswordSignIn={setUserPasswordSignIn}
              senderOwner={senderOwner}
              confirmInvitation={confirmInvitation}
              setNameForEmailSuccess={setNameForEmailSuccess}
            />
          )}
          {displayNewAccount && (
            <CreateAccount
              setDisplaySignUpModal={setDisplaySignUpModal}
              setAccountCreate={setAccountCreate}
              setUserMailSignIn={setUserMailSignIn}
              setUserPasswordSignIn={setUserPasswordSignIn}
              senderOwner={senderOwner}
              confirmInvitation={confirmInvitation}
              setNameForEmailSuccess={setNameForEmailSuccess}
              setLastNameForEmailSucess={setLastNameForEmailSucess}
            />
          )}
        </div>
      )}

      {successLinked && (
        <>
          <CardHeaderPage text={`Bienvenue ${userName} dans Hearing Space`} />
          <SuccessLinked userName={userName} senderOwner={senderOwner} />
        </>
      )}

      {alreadyLinked && (
        <AlreadyLinked senderOwner={senderOwner} emailUser={emailUser} />
      )}
      {linkToSelf && <LinkToSelf />}

      {jwtExpired && <JwtExpired />}
      {jwtAlreadyUSe && (
        <h2>
          Ce lien a expiré. <br /> Contactez votre administrateur pour qu'il
          vous renvoie l'invitation.
        </h2>
      )}
      {jwtError && <JwtError />}
    </div>
  );
};

export default UserAccountLinker;
