import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import BtnForms from "../components/UiComponents/forms/BtnForms";
import NavContext from "../context/NavContext";
import styles from "./Erreur404.module.scss";
const Erreur404 = () => {
  const { userAuth } = useContext(NavContext);
  const navigate = useNavigate();

  const redirectionUser = (params) => {
    if (userAuth) {
      navigate("/private/dashboard");
    } else {
      navigate("/");
    }
  };

  return (
    <div className={styles.fixed}>
      <div className={styles.container}></div>
      <h1>Erreur 404</h1>
      <h1>OUPS ! Il semble que vous soyez perdu</h1>
      <img src="https://i.gifer.com/X5NY.gif" alt="" />
      {userAuth ? (
        <BtnForms
          onClick={redirectionUser}
          text={"Aller au dashboard"}
          style={{ width: "200px" }}
        />
      ) : (
        <BtnForms
          onClick={redirectionUser}
          text={"Aller à la page login"}
          style={{ width: "200px" }}
        />
      )}
    </div>
  );
};

export default Erreur404;
