import React from "react";

import styles from "../UserAccountLinker.module.scss";

const JwtExpired = () => {
  return (
    <div className={styles.errorToken}>
      {" "}
      <h2>Cette Invitation a expiré</h2>
      <p>
        Veuillez contacter la personne qui vous a invité pour obtenir une
        nouvelle invitation
      </p>
    </div>
  );
};

export default JwtExpired;
