import { useContext, useEffect, useState } from "react";

//styles
import styles from "./Team.module.scss";
import { WiStars } from "react-icons/wi";
// context
import NavContext from "../../context/NavContext";
import { useCabineContext } from "../../context/CabineContext";
import { useUserInviteContext } from "../../context/UserInviteContext";
//component
import UserCard from "./userCard/UserCard";

import InviteForms from "./InvitUser/InviteForms";

import { BiMailSend } from "react-icons/bi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { BsInfoSquare } from "react-icons/bs";
import HeaderUserCard from "./userCard/HeaderUserCard";
import {
  WelcomeCardAdmin,
  WelcomeCardOwner,
} from "../UiComponents/card/WelcomeCard";
import Axios from "../../_services/caller.service";
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
import TutorialInvite from "./InvitUser/TutorialInvite";
import Modal from "../UiComponents/modals/Modal";
import useModal from "../../hooks/useModal";
import Button from "../UiComponents/Button";

import InputForms from "../UiComponents/forms/InputForms";
import InfosInviteUser from "./InvitUser/InfosInviteUser";
import HeaderInviteUser from "./InvitUser/HeaderInviteUser";

import ResendInviteCard from "./InvitUser/ResendInviteCard";
import SendEmailConfirmationAccessHS from "./sendEmailConfirmation/SendEmailConfirmationAccessHS";

const Team = () => {
  //CONTEXT
  const { ownerId, userAuth, ownerProfile, adminProfile, superAdmin } =
    useContext(NavContext);
  const { userInvite, inviteId } = useUserInviteContext(); // get all user's invite and ID

  const { cabine } = useCabineContext();
  // ROLE
  const [userRole, setUserRole] = useState([]); // user's role
  const [updateRole, setUpdateRole] = useState([]); // update role with selected
  const [majRole, setMajRole] = useState(false); // declenche Maj Role
  //CABINE
  const [cabId, setCabId] = useState([]); // cabId for updtate
  //USER
  const [inviteUser, setInviteUser] = useState([]); // array with all cabine authorize user to access cabine
  const [updateUserId, setUpdateUserId] = useState([]); // user ID to update
  const [inviteWaiting, setInviteWaiting] = useState("");
  const [searchUserInviteWaiting, setSearchUserInviteWaiting] = useState("");
  const [sortedInviteUser, setSortedInviteUser] = useState(""); // asc or dsc by email or name
  const [newInvite, setNewInvite] = useState(0);
  // DISPLAY
  const [maj, setMaj] = useState(false); //reload all user Invite
  const [reloadWaitingInvite, setReloadWaitingInvite] = useState(""); // reload a new

  const [displayInfosInvitation, setDisplayInfosInvitation] = useState(false);

  //FILTER
  const [sorted, setSorted] = useState(""); // asc or dsc by email or name
  const [searchUser, setSearchUser] = useState(""); //reload all user Invite
  //MODAL
  const { isShowing, toggle } = useModal();

  const [cabAuth, setcabAuth] = useState(null); //reload all user Invite

  // GET ROLE FOR ALL CABINE
  useEffect(() => {
    // get user's role (calibration/consulation/admin)

    if (userAuth && inviteId.length >= 1) {
      Axios.post(`/userRole/${ownerId}`, {
        data: inviteId,
      })

        .then((response) => {
          setUserRole(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [inviteId, ownerId, majRole, setMajRole]);

  // UPDATE ROLE FOR ALL CABINES
  useEffect(() => {
    // console.log(updateUserId[0]);
    if (updateRole.length >= 1) {
      Axios.put(`/updateRole/${ownerId}`, {
        data: {
          updateUserId: updateUserId[0],
          updateRole: updateRole,
          inviteId: inviteId,
        },
      })

        .then((response) => {
          const allRole = response.data.data;
          setUserRole(allRole);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [updateRole, updateUserId, ownerId, majRole, setMajRole]);

  // GET ALL CABINE USER CABINE
  useEffect(() => {
    Axios.get(`/getAuthCabineUser/${ownerId}`)

      .then((response) => {
        setInviteUser(response.data.data);

        // setNumberUserDisplay(response.data.data.length);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, [ownerId, maj, setMaj]);

  // UPDATE AUTH CAB BY CAB

  const createPermissionCabByCab = (checked) => {
    if (checked) {
      Axios.post(`/createPermissionsCabByCab/${ownerId}`, {
        updateUserId: updateUserId[0],
        cabId: cabId[0],
        role: updateRole,
      })

        .then((response) => {
          setMajRole(!majRole); // redeclenche les droits
          setMaj(!maj); // redeclenche getAuthUser pour relancer setInviteUser
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      Axios.delete(
        `/deletePermissionsCabByCab/${ownerId}?userId=${updateUserId[0]}&cabid=${cabId[0]}`
      )

        .then((response) => {
          setMajRole(!majRole);
          setMaj(!maj);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  // display user's role
  const displayRoleByUser = (id) => {
    const role = userRole.filter((role) => id === role.user).map((c) => c.role);

    if (role[0] === 8) {
      return "Calibration";
    }
    if (role[0] === 1) {
      return "Consultation";
    }
    if (role[0] === 256) {
      return "Admin";
    }

    if (role[0] === 0) {
      return "Aucun Droit";
    }

    if (role.length === 0) {
      return "Aucun Droit";
    }
  };
  const selectDefaultValue = (id) => {
    const role = userRole.filter((role) => id === role.user).map((c) => c.role);

    if (role[0] === 8) {
      return 8;
    }
    if (role[0] === 1) {
      return 1;
    }
    if (role[0] === 256) {
      return 256;
    }
    if (role.length === 0) {
      return 0;
    }
  };

  const numberCabsAuth = (id) => {
    let numberAuth = [];
    const userID = inviteUser.filter((u) => u.ID === id);
    const arrayAuth = userID.map((user) =>
      user.cabines[0].map((cab) => cab.auth)
    );

    let authCab = arrayAuth[0].indexOf(true);
    while (authCab != -1) {
      numberAuth.push(authCab);
      authCab = arrayAuth[0].indexOf(true, authCab + 1);
    }
    return numberAuth.length;
  };

  //FILTERED USER
  const getFilteredAndSortedUsers = () => {
    return inviteUser
      .filter((user) => {
        // Si superAdmin est true, on ignore la condition de comparaison avec adminProfile
        if (superAdmin) {
          return true;
        }

        if (adminProfile && displayRoleByUser(user.ID) === "Admin") {
          return false;
        }
        // Si superAdmin est false, on applique la condition de comparaison
        return (
          user.display_name.toLowerCase() !==
          adminProfile.display_name.toLowerCase()
        );
      })
      .filter((user) => {
        const termsDisplayName = (user.display_name || "")
          .toLowerCase()
          .split(" ");
        const termsFirstName = (user.first_name || "").toLowerCase().split(" ");
        const termsLastName = (user.last_name || "").toLowerCase().split(" ");
        const termsUserLogin = (user.user_login || "").toLowerCase().split(" ");

        const searchTerm = searchUser.toLowerCase();

        return (
          termsDisplayName.some((term) => term.includes(searchTerm)) ||
          termsFirstName.some((term) => term.includes(searchTerm)) ||
          termsLastName.some((term) => term.includes(searchTerm)) ||
          termsUserLogin.some((term) => term.includes(searchTerm)) ||
          user.display_name?.toLowerCase().includes(searchTerm) ||
          user.first_name?.toLowerCase().includes(searchTerm) ||
          user.last_name?.toLowerCase().includes(searchTerm) ||
          user.user_login?.toLowerCase().includes(searchTerm)
        );
      })

      .sort((a, b) => {
        let valA, valB;
        switch (sorted) {
          case "ascLogin":
            valA = a.user_login || "";
            valB = b.user_login || "";
            return valA.localeCompare(valB);
          case "dscLogin":
            valA = a.user_login || "";
            valB = b.user_login || "";
            return valB.localeCompare(valA);
          case "ascName":
            valA = a.last_name || "";
            valB = b.last_name || "";
            return valA.localeCompare(valB);
          case "dscName":
            valA = a.last_name || "";
            valB = b.last_name || "";
            return valB.localeCompare(valA);
          default:
            valA = a.display_name || "";
            valB = b.display_name || "";
            return valA.localeCompare(valB);
        }
      });
  };
  const filteredAndSortedUsers = getFilteredAndSortedUsers();

  //INVITE WAITING FILTER

  const getFilteredAndSortedUsersInviteWaiting = () => {
    if (inviteWaiting.length === 0) {
      return;
    } else {
      return inviteWaiting
        .filter(
          (user) =>
            user.display_name.toLowerCase().includes(searchUserInviteWaiting) ||
            user.email.toLowerCase().includes(searchUserInviteWaiting)
        )

        .sort((a, b) => {
          let valA, valB;
          switch (sortedInviteUser) {
            case "ascLogin":
              valA = a.display_name || "";
              valB = b.display_name || "";
              return valA.localeCompare(valB);
            case "dscLogin":
              valA = a.display_name || "";
              valB = b.display_name || "";
              return valB.localeCompare(valA);
            case "ascName":
              valA = a.email || "";
              valB = b.email || "";
              return valA.localeCompare(valB);
            case "dscName":
              valA = a.email || "";
              valB = b.email || "";
              return valB.localeCompare(valA);

            case "ascExpiration":
              valA = a.when;
              valB = b.when;
              return valA - valB;
            case "dscExpiration":
              valA = a.when;
              valB = b.when;
              return valB - valA;

            default:
              valA = a.display_name || "";
              valB = b.display_name || "";
              return valA.localeCompare(valB);
          }
        });
    }
  };

  const filteredAndSortedUsersInviteWaiting =
    getFilteredAndSortedUsersInviteWaiting();

  useEffect(() => {
    Axios.get(`/inviteWaiting/${ownerId}`)

      .then(function (response) {
        const inviteList = response.data.data;

        setInviteWaiting(inviteList);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [newInvite, reloadWaitingInvite]);

  const getTimeReading = (currentTimeUnix) => {
    const dateObj = new Date(currentTimeUnix * 1000);

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("fr-FR", options);

    // affiche la date au format jj/mm/yyyy
    return formattedDate;
  };

  const getTimeExpired = (currentTimeUnix) => {
    const dateObj = new Date(currentTimeUnix * 1000);

    // Ajout de 15 jours
    dateObj.setDate(dateObj.getDate() + 15);

    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("fr-FR", options);

    // affiche la date au format jj/mm/yyyy après avoir ajouté 15 jours
    return formattedDate;
  };

  return (
    <motion.main
      variants={animatePage}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {adminProfile.ID === ownerProfile.ID ? (
        <WelcomeCardOwner owner={ownerProfile.display_name} />
      ) : (
        <WelcomeCardAdmin
          owner={ownerProfile.display_name}
          admin={adminProfile.display_name}
        />
      )}

      {inviteWaiting.length >= 1 && (
        <>
          <div className={styles.title}>
            <div className={styles.title_icon}>
              <h1>Mes invitations en attente</h1>

              <WiStars />
            </div>
          </div>
          <div className={styles.wrapperUser}>
            <div className={styles.searchUser}>
              {" "}
              <InputForms
                id={styles.inputSearchInvitation}
                onChange={(e) => setSearchUserInviteWaiting(e.target.value)}
                placeholder="Recherche Invitation"
              />
            </div>
            <HeaderInviteUser setSorted={setSortedInviteUser} />
            {inviteWaiting.length >= 1 &&
              filteredAndSortedUsersInviteWaiting.map((invit, index) => {
                return (
                  <ResendInviteCard
                    dateInvitation={getTimeReading(invit.when)}
                    dateExpiration={getTimeExpired(invit.when)}
                    inviteWaiting={inviteWaiting}
                    setinviteWaiting={setInviteWaiting}
                    invit={invit}
                    key={index}
                    hashId={invit.hash}
                    inviteEmail={invit.email}
                    inviteName={invit.display_name}
                    newInvite={newInvite}
                    setNewInvite={setNewInvite}
                  />
                );
              })}
          </div>
        </>
      )}
      {inviteUser.length >= 1 && (
        <>
          <div className={styles.title}>
            <div className={styles.title_icon}>
              <h1>Mon équipe</h1>
              <WiStars />
              <Button
                type={"submit"}
                styles={"btnPrimary"}
                svg={<BiMailSend />}
                text={"Inviter un utilisateur"}
                onPress={() => toggle()}
              />

              <IoMdInformationCircleOutline
                className={styles.infosInvite}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  color: "#6d5dfc",
                }}
                onMouseEnter={() =>
                  setDisplayInfosInvitation(!displayInfosInvitation)
                }
                onMouseLeave={() =>
                  setDisplayInfosInvitation(!displayInfosInvitation)
                }
              />
              {displayInfosInvitation && <InfosInviteUser />}
            </div>
          </div>
       
          <div className={styles.wrapperUser}>
            <div className={styles.searchUser}>
              {" "}
              <InputForms
                id={styles.inputSearchAudio}
                onChange={(e) => setSearchUser(e.target.value)}
                placeholder="Recherche utilisateurs"
              />
            </div>

            <HeaderUserCard setSorted={setSorted} />
            <div className={styles.searchAndCard}>
              {filteredAndSortedUsers.map((user) => {
                return (
                  <UserCard
                    key={user.ID}
                    login={user.user_login}
                    firstName={user.first_name}
                    lastName={user.last_name}
                    displayName={user.display_name}
                    email={user.user_email}
                    user={user}
                    inviteUser={inviteUser}
                    setInviteUser={setInviteUser}
                    updateUserId={updateUserId}
                    setUpateUserId={setUpdateUserId}
                    userId={user.ID}
                    displayRoleByUser={displayRoleByUser}
                    selectDefaultValue={selectDefaultValue}
                    userRoleId={user.ID}
                    setUpdateRole={setUpdateRole}
                    userRole={userRole}
                    setUserRole={setUserRole}
                    setCabId={setCabId}
                    cabines={user.cabines[0]}
                    setMaj={setMaj}
                    maj={maj}
                    majRole={majRole}
                    setMajRole={setMajRole}
                    createPermissionCabByCab={createPermissionCabByCab}
                    numberCabsAuth={numberCabsAuth}
                  />
                );
              })}
            </div>
          </div>
        </>
      )}

      {inviteUser.length === 0 && <TutorialInvite toggle={toggle} />}
      <Modal isShowing={isShowing} hide={toggle}>
        <InviteForms
          setReloadWaitingInvite={setReloadWaitingInvite}
          reloadWaitingInvite={reloadWaitingInvite}
          toggle={toggle}
        />
      </Modal>
    </motion.main>
  );
};
export default Team;
