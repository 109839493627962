import React, { useState } from "react";
import InputForms from "../../UiComponents/forms/InputForms";

import styles from "./SearchUser.module.scss";
const SearchUser = ({
  allUser,
  changeOwnerIdWithSelect,
  changeOwnerId,
  searchUser,
  setSearchUser,
  displaySelectOpen,
  setDisplaySelectOpen,
  ownerId,
  isSearchingUser,
  setIsSearchingUser,
}) => {
  const filteredUser = allUser.filter(
    (u) =>
      u.display_name.toLowerCase().includes(searchUser) ||
      u.user_email.toLowerCase().includes(searchUser) ||
      u.ID.toString().includes(searchUser) ||
      u.user_login.toLowerCase().includes(searchUser)
  );

  return (
    <div className={styles.containerUser}>
      <InputForms
        value={searchUser}
        onChange={(e) => {
          setSearchUser(e.target.value.toLowerCase());
          setIsSearchingUser(true);
        }}
        placeholder={"Centre/utilisateur"}
      />

      <select
        value={ownerId}
        onChange={changeOwnerIdWithSelect}
        size={isSearchingUser ? 10 : 0}
        onBlur={() => setIsSearchingUser(false)}
      >
        <option value="">-- Sélectionnez un utilisateur --</option>
        {filteredUser.length === 0 && (
          <option value="" disabled>
            -- Aucune option disponible --
          </option>
        )}
        {allUser &&
          filteredUser

            .sort((a, b) => a.user_login.localeCompare(b.user_login))

            .map((c) => {
              return (
                <option key={c.ID} value={c.ID}>
                  {c.user_login} {c.display_name} ({c.ID})
                </option>
              );
            })}
      </select>
    </div>
  );
};

export default SearchUser;
