import { title } from "process";
import React from "react";
import ReactDOM from "react-dom";

import styles from './Modal.module.scss'

const Modal = ({ isShowing, hide,title,children }) =>
  isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={styles.overlay}>
            <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
              <div className={styles.modal}>
                <div className={styles.header}>
                  <h4>{title}</h4>
               
                  <button
                    type="button"
                    className={styles.closeBtn}
                    onClick={hide}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className={styles.modalBody}>{children}</div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null;

export default Modal;