import React from "react";
import styles from "./CardDownload.module.scss";
import ProgressBarDownload from "./ProgressBarDownload";

const CardDownload = ({ title, svg, content, onClick,progressBar}) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={`${styles.face} ${styles.face1}`}>
            <div className={styles.content}>
              {svg}
              <h3>{title}</h3>
            </div>
          </div>
          <div className={`${styles.face} ${styles.face2}`}>
            <div className={styles.content}>
       
              <span onClick={onClick} > 
                Télécharger
              </span>
            </div>
          </div>
              <ProgressBarDownload progress={progressBar} />
        </div>
      </div>
    </>
  );
};

export default CardDownload;
