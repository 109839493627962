import React, { useEffect, useState } from "react";
import Button from "../../UiComponents/Button";
import styles from "./TutorialInvite.module.scss";
import { GoPlus } from "react-icons/go";
import Slider from "react-slick";
import ears from "../../../pics/microears.png";
import umik from "../../../pics/umik.png";
import presets from "../../../pics/presets.png";
import acces from "../../../pics/acces.png";
import presetsLittle from "../../../pics/presetLittle.png";
import accesLittle from "../../../pics/presetLittle2.png";
import { IoMdInformationCircleOutline  } from "react-icons/io";

//style
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TutorialInvite = ({ toggle }) => {
  const [showMobile, setShowMobile] = useState(false);
  const [displayGuideInvitation, setDisplayGuideInvitation] = useState(false);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    if (window.innerWidth < 1300) {
      setShowMobile(true);
    } else {
      setShowMobile(false);
    }
  }, []);

  return (
    <>
      <div className={styles.container}>
        <h1>Plusieurs utilisateurs pour une seule cabine ?</h1>
        <div className={styles.containerTuto}>
          <div className={styles.containerExplication}>
            <p>
              Invitez un membre de votre équipe à utiliser votre cabine.
            </p>
            <Button
              type={"text"}
              styles={"btnPrimary"}
              svg={<GoPlus />}
              text={"Inviter un utilisateurs"}
              onPress={() => toggle()}
            />

            <IoMdInformationCircleOutline 
              className={styles.infosInvite}
              style={{ cursor: "pointer", fontSize: "25px", zIndex: 100000 ,color:'#6d5dfc'}}
              onMouseEnter={() => setDisplayGuideInvitation(!displayGuideInvitation)} onMouseLeave={() => setDisplayGuideInvitation(!displayGuideInvitation)}
            />

            {displayGuideInvitation && (
              <div className={styles.guideInvite}>
                <p>
                  1° Renseignez le formulaire d'invitation en cliquant sur le
                  bouton ci-dessus.
                </p>
                <p>
                  2° L'utilisateur devra accepter l'invitation en cliquant
                  sur le lien reçu par mail.
                </p>
                <p>
                  3° Ensuite il devra se connecter avec son compte ou en crée un
                  via le formulaire.
                </p>
                <p>
                  4° Vous devrez ensuite aller sur la section "utilisateurs" et
                  lui attribuer des droits et lui autoriser l'accès.
                </p>
              </div>
            )}
          </div>
          {showMobile ? (
            <div className={styles.containerSlider}>
              <Slider {...settings}>
                <div className={styles.containerImg}>
                  <img src={presets} alt="" />
                </div>
                <div className={styles.containerImg}>
                  <img src={acces} alt="" />
                </div>
              </Slider>
            </div>
          ) : (
            <div className={styles.containerSlider}>
              <Slider {...settings}>
                <div className={styles.containerImg}>
                  <img src={presets} alt="" />
                </div>
                <div className={styles.containerImg}>
                  <img src={acces} alt="" />
                </div>
              </Slider>
            </div>
          )}
          
        </div>
      </div>
    </>
  );
};

export default TutorialInvite;
