import React from 'react';
import { MdContactPage } from 'react-icons/md';
import SignUpModal from '../../authentification/SignUpModal';
import Button from '../../UiComponents/Button';
import styles from '../UserAccountLinker.module.scss'

const CreateAccount = ({setDisplaySignUpModal,setAccountCreate,setUserMailSignIn,setUserPasswordSignIn,senderOwner,confirmInvitation,setLastNameForEmailSucess,setNameForEmailSuccess}) => {
    return (
        <div className={styles.createAccount}>
          <h2>
       
            Créer votre compte <br/> et vous serez lier automatiquement à {senderOwner}
          </h2>
          <SignUpModal
          setDisplaySignUpModal={setDisplaySignUpModal}
          setAccountCreate={setAccountCreate}
     
          setUserPasswordSignIn={setUserPasswordSignIn}
          confirmInvitation={confirmInvitation}
          setNameForEmailSuccess={setNameForEmailSuccess}
          setLastNameForEmailSucess={setLastNameForEmailSucess}
        />
        
        </div>
    );
};

export default CreateAccount;