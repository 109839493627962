import ErrorMessageHS from "../_components/ErrorMessageHS";
import ConfigHp from "./template/ConfigHp";
import ContactSupportOnStartHS from "./template/ContactSupportOnStartHS";
import InitialisationErrorSoundCard from "./template/InitialisationErrorSoundCard";
import OupoutNotConnected from "./template/OupoutNotConnected";
import ReinstallHS from "./template/ReinstallHS";




export const dataMessageError =[

    {
    title: "La configuration des HP n'est plus la bonne",
    children:<ConfigHp/>,
    
    },
    {
    title: "Erreur d'initialisation de la sortie (si cette notification ne disparaît pas)",
    children: <InitialisationErrorSoundCard/>
    
    },
    {
    title: "La sortie casque/insert/champ libre/co n'est pas connecté",
    children:<OupoutNotConnected/>
    
    },
    {
    title: "Erreur veuillez-contacter le support et HS se ferme",
    children:<ContactSupportOnStartHS/>
    
    },
    {
    title: "Réinstallation Hearing Space",
    children:<ReinstallHS/>
    
    },
    {
    title: "Current GUID Modal WindoUne erreur s'est produite WPServer error: Invalid credentials generated from" ,
    children: "Il faut supprimer le fichier HSSecurityGUID dans programmData/chiara/hearingSpace/HSSecurityGUID et délier la cabine côté serveur"
    
    },
    {
    title: "Le nombre de dossier trouvés ne correspond pas dans espace vocale" ,
    children: "Vérifier si les mots sont bien installés"
    
    },
    {
    title: "Installation pas autorisés" ,
    children: "Vérifier l'id et mdp sinon aller dans Serials allocate côté serveur"
    
    },
    {
    title: "Scarlett focusrite rouge" ,
    children: "Si plusieurs Focus il ne faut pas que les drivers soit installés car sinon ne peuvent pas fonctionner. Le volume doit-être à fond"
    
    },
    {
    title: "Le micro de calibration se coupe tout seul" ,
    children: "Tapez 'alimentation' dans barre de recherche windows puis parametre suplémentaire/ usb et désactivé la veille"
    
    },
    {
    title: "Dolby digital live pour carte son en optique" ,
    children: "Aller dans proprietes puis statistique avancée et seléctionné dolby digital live"
    
    },
    
    ]