import React, { useContext, useEffect, useState } from "react";
import styles from "./DeleteUser.module.scss";
import Button from "../../UiComponents/Button";
import { MdRemoveCircle, MdOutlineCancel } from "react-icons/md";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsFillTrashFill } from "react-icons/bs";


import NavContext from "../../../context/NavContext";

import Success from "../../UiComponents/sucess/Success";
import useModal from "../../../hooks/useModal";
import Modal from "../../UiComponents/modals/Modal";
import DeleteUserWarningModals from "../../UiComponents/modals/DeleteUserWarningModals";
import Axios from "../../../_services/caller.service";

const DeleteUser = ({
  displayDeleteUser,
  setDisplayDeleteUser,
  name,
  lastName,
  userId,
  setSettingAllCab,
  maj,
  setMaj,
}) => {
  const token = localStorage.getItem("jwt");
  const { ownerId } = useContext(NavContext);
  const [success, setSuccess] = useState(false);
  const { isShowing, toggle } = useModal();

  const { isShowing: askDelete, toggle: toggleAskDelete } = useModal();
  const { isShowing: reallyDelete, toggle: toggleDelete } = useModal();
  const { isShowing: successDelete, toggle: toggleSuccess } = useModal();

  const deleteUser = (userId) => {
    Axios.delete(`/deleteuserlink/${ownerId}?userId=${userId}`)

      .then((response) => {
        setSuccess(true);
        toggleSuccess();
        toggleDelete();
        toggleAskDelete();

        setTimeout(() => {
          setSuccess(false);
          toggleDelete();
          toggleSuccess();
          setMaj(!maj); // maj all components
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className={styles.deleteUser} onClick={() => toggleAskDelete()}>
        {displayDeleteUser ? (
          <div className={styles.confirmDelete}></div>
        ) : (
          <>
            <BsFillTrashFill
              style={{
                color: "#eb4d4b",
                fontSize: "18px",
                cursor: "pointer",
                zIndex: 2,
              }}
            />
            {/* <span onClick={() => toggleAskDelete()}>Supprimmer l'accès</span> */}
          </>
        )}
      </div>

      <Modal
        isShowing={askDelete}
        hide={toggleAskDelete}
        children={
          <div className={styles.confirmDelete}>
            <h2>
              Souhaitez-vous supprimer l'accès aux cabines à{" "}
              <span>
                {name} {lastName}
              </span>
            </h2>

            <Button
              svg={<MdOutlineCancel />}
              text={"non"}
              onPress={() => toggleAskDelete()}
              styles={"btnPrimary"}
            />
            <Button
              svg={<AiOutlineCheckCircle />}
              text={"oui"}
              onPress={() => toggleDelete()}
              //  onPress={() => deleteUser(userId)}
              styles={"btnPrimary"}
            />
          </div>
        }
      />
      <Modal
        deleteUser={deleteUser}
        isShowing={reallyDelete}
        hide={() => {
          toggleDelete();
          toggleAskDelete();
        }}
        children={
          <DeleteUserWarningModals
            deleteUser={deleteUser}
            userId={userId}
            onPress={() => deleteUser(userId)}
          />
        }
      />

      <Modal
        isShowing={successDelete}
        hide={toggleSuccess}
        children={
          <Success success={"L'utilisateur à bien été retiré de votre liste"} />
        }
      />
    </>
  );
};

export default DeleteUser;
