import React from "react";

export const QuestionData = [
  {
    question: "Comment rétablir la configuration de mes hauts parleurs ?",
    answer:
      "Pour vous aider à rétablir la configuration de vos hauts parleurs:",
    link: "https://www.hearing-space.com/configuration-carte-son-windows",
    linkText: "Suivre le tutoriel",
  },
  {
    question: "Pourquoi ma carte son est grisée dans hearing-space ?",
    answer: "Pour vous aider à rétablir votre carte son:",
    // list: [
    //   "Vérifiez dans un premier temps que votre carte son est branchées et apparaît bien dans la liste des sorties son (sur windows). ",
    //   "Pour que votre sortie fonctionne elle doit avoir été calibrées.",
    //   "Enfin il se peut que votre carte son n'est plus associés côté serveur",
    //   "Pour réassocier votre carte son vous pouvez suivre ce tutoriel:",
    // ],
    link:"https://www.hearing-space.com/mon-transducteur-ne-fonctionne-plus",
    linkText:'Suivre le tutoriel'
  },
  {
    question: "Comment configurer mon casque VR Meta Quest 2 ?",
    answer: "Pour vous aider à l'installation du casque VR:",
    // list: [
    //   "Vérifiez dans un premier temps que votre carte son est branchées et apparaît bien dans la liste des sorties son (sur windows). ",
    //   "Pour que votre sortie fonctionne elle doit avoir été calibrées.",
    //   "Enfin il se peut que votre carte son n'est plus associés côté serveur",
    //   "Pour réassocier votre carte son vous pouvez suivre ce tutoriel:",
    // ],
    link:"https://www.hearing-space.com/installation-oculus-quest-2",
    linkText:'Suivre le tutoriel'
  },

];
