import React, { useEffect } from 'react';
import {Routes,Route} from 'react-router-dom'
import ResetPassword from '../components/authentification/ResetPassword';
import SessionDisconnect from '../components/authentification/SessionDisconnect';
import DashboardCard from '../components/Dashboard/Card/DashboardCard';
import UserAccountLinker from '../components/InviteUserAfterMail/UserAccountLinker';
import Connexion from '../pages/Connexion/Connexion';
import Erreur404 from '../pages/Erreur404';

const PublicRouter = ({setRememberMe, isLoggedIn}) => {



    return (
        <Routes>
        <Route
        path="/"
        element={<Connexion/>}
        // element={ isLoggedIn ? (<DashboardCard/>) : (<Connexion  setRememberMe={setRememberMe} />)}
      />
      <Route
        path="/sessionDisconnect"
        element={<SessionDisconnect />}
      />
      <Route
        path="/userAccountLinker"
        element={<UserAccountLinker />}
      />
      <Route path="/resetPassword/*" element={<ResetPassword />} />
      <Route path="*" element={<Erreur404 />} />
      
      </Routes>
    );
};

export default PublicRouter;