import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import Select from "react-select";
import styles from "./GlobalComparisonMetrics.module.scss";
import metricsConfig from "../metricsConfig";
import {
  getRandomColor,
  transformComparisonData,
  calculateAveragesForComparisonUserOrCabine,
} from "../functionUtilsStats";

export default function GlobalComparisonMetrics({
  comparisonData,
  comparisonMode,
  setComparisonMode,
  usersMap,
  cabinesMap,
  setTimeInterval,
  timeInterval,
  selectedMetrics,
  setSelectedMetrics,
}) {
  const [transformedData, setTransformedData] = useState([]);

  const metricOptions = Object.keys(metricsConfig).map((key) => ({
    value: key,
    label: metricsConfig[key].title,
  }));

  const [includeSaturday, setIncludeSaturday] = useState(false);
  const [userColors, setUserColors] = useState({});

  const timeIntervalOptions = [
    { value: "daily", label: "Jour par jour" },
    { value: "weekly", label: "Semaine par semaine" },
    { value: "monthly", label: "Mois par mois" },
    { value: "yearly", label: "Année par année" },
  ];

  const handleTimeIntervalChange = (selectedOption) => {
    setTimeInterval(selectedOption.value);
  };

  const handleSaturdayChange = (e) => {
    setIncludeSaturday(e.target.checked);
  };

  const assignUserColors = (data) => {
    const newUserColors = {};
    data.forEach((item, index) => {
      if (!newUserColors[item.uid]) {
        newUserColors[item.uid] = getRandomColor(); // Fonction hypothétique pour obtenir une couleur aléatoire
      }
    });
    setUserColors(newUserColors);
  };

  useEffect(() => {
    const newUserColors = {};
    comparisonData.forEach((data) => {
      if (!newUserColors[data.uid]) {
        newUserColors[data.uid] = getRandomColor();
      }
    });
    setUserColors(newUserColors);
  }, [comparisonData]);




  useEffect(() => {
console.log(comparisonData);

    // Supposons que transformComparisonData accepte timeInterval et includeSaturday comme paramètres
    const transformed = transformComparisonData(
      comparisonData,
      usersMap,
      cabinesMap,
      selectedMetrics.map((metric) => metric.value),
      timeInterval,
      includeSaturday
    );
    setTransformedData(transformed);
  }, [comparisonData, selectedMetrics, timeInterval, includeSaturday]);

  const handleMetricsChange = (selectedOptions) => {
    setSelectedMetrics(selectedOptions || []);
  };

 
  const renderLines = () => {
    const lines = [];
    transformedData.forEach((dataPoint) => {
      Object.keys(dataPoint).forEach((key) => {
        if (
          key !== "date" &&
          selectedMetrics.some((metric) => key.includes(metric.value))
        ) {
          const metricName = key.split(" - ")[1]; // Sépare la clé pour obtenir le nom de la métrique
          const userLabel = key.split(" - ")[0]; // Sépare la clé pour obtenir le label de l'utilisateur
          const color = userColors[userLabel] || getRandomColor(); // Utilise la couleur assignée ou une par défaut
          const lineKey = `${userLabel}-${metricName}`;

          if (!lines.find((line) => line.key === lineKey)) {
            lines.push(
              <Line
                key={lineKey}
                type="monotone"
                dataKey={key}
                stroke={color}
                name={`${userLabel} - ${
                  metricsConfig[metricName]?.title || metricName
                }`}
                connectNulls={true}
              />
            );
          }
        }
      });
    });
    return lines;
  };

  const averages = calculateAveragesForComparisonUserOrCabine(transformedData);

  const renderReferenceLinesAndLegend = (averages) => {
    let referenceLines = [];
    let legendData = [];

    Object.entries(averages).forEach(([userOrCabineName, metrics]) => {
      Object.entries(metrics).forEach(([metricName, avgValue]) => {
        const label = `${userOrCabineName} ${metricName} moy: ${avgValue.toFixed(
          2
        )}`;
        const strokeColor = userColors[userOrCabineName] || getRandomColor(); // Assurez-vous d'avoir une couleur par utilisateur/cabine

        referenceLines.push(
          <ReferenceLine
            key={`${userOrCabineName}-${metricName}-moy`}
            y={avgValue}
            stroke={strokeColor}
            ifOverflow="extendDomain"
            label={{ value: label, position: "top", fill: strokeColor }}
          />
        );

        // Préparation des données pour la légende personnalisée
        legendData.push({ value: label, type: "line", color: strokeColor });
      });
    });

    return { referenceLines, legendData };
  };

  const { referenceLines, legendData } =
    renderReferenceLinesAndLegend(averages);

  return (
    <div className={styles.container}>
      <div className={styles.containerSelect}>
        <Select
          isMulti
          options={metricOptions}
          value={selectedMetrics}
          onChange={handleMetricsChange}
          placeholder="Select metrics to compare..."
          className="metric-select"
        />
        <Select
          options={timeIntervalOptions}
          value={timeIntervalOptions.find(
            (option) => option.value === timeInterval
          )}
          onChange={handleTimeIntervalChange}
          placeholder="Sélectionnez l'intervalle de temps..."
        />
        {timeInterval === "daily" && (
          <label>
            <input
              type="checkbox"
              checked={includeSaturday}
              onChange={handleSaturdayChange}
            />{" "}
            Inclure les samedis
          </label>
        )}
      </div>
      <ResponsiveContainer width="100%" height={600}>
        <LineChart data={transformedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />

          {renderLines()}
          {referenceLines}
          <Legend
            verticalAlign="top"
            align="right"
            wrapperStyle={{
              top: 0,
              right: 20,
              position: "absolute",
            }}
            content={({ payload }) => {
              return (
                <div style={{ textAlign: "right" }}>
                  {legendData.map((entry, index) => (
                    <div key={`item-${index}`} style={{ color: entry.color }}>
                      {entry.value}
                    </div>
                  ))}
                </div>
              );
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
