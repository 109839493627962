import React, { useState, useEffect, useMemo } from "react";
import Axios from "../../_services/caller.service";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./Statistics.module.scss";
import { SummaryOfFilters } from "./components/SummaryOfFilters";
import MiniCardStats from "./components/MiniCardStats";
//IMAGE
import HSStartedImg from "../../pics/HSSarted.webp";
import HSReportImg from "../../pics/HSReports.webp";
import calibrationStats from "../../pics/calibration_stats.webp";
import audiogramStats from "../../pics/audiogram_stats.png";
// COMPONENTS
import UsersNumberStats from "./components/UsersNumberStats";

import GlobalSelectMetrics from "./charts/GlobalSelectMetrics";
import GlobalComparisonMetrics from "./charts/GloblaComparisonMetrics";
import metricsConfig from "./metricsConfig";

function Statistics() {
  const [startDate, setStartDate] = useState(new Date(2024, 0, 1));
  const [endDate, setEndDate] = useState(new Date());
  const [allUsers, setAllUsers] = useState([]);
  const [usersMap, setUsersMap] = useState({});

  const [allCabines, setAllCabines] = useState([]);
  const [cabinesMap, setCabinesMap] = useState({});

  const [selectedUsers, setSelectedUsers] = useState([]); // Ajout de l'état pour l'utilisateur sélectionné
  const [selectedCabines, setSelectedCabines] = useState([]); // Ajout de l'état pour la cabine sélectionnée
  const [linkedUsers, setLinkedUsers] = useState([]);
  const [timeInterval, setTimeInterval] = useState("monthly");
  const [defaultMetrics, setDefaultMetrics] = useState(["HSStarted"]);
  const defaultSelectedMetrics = defaultMetrics && defaultMetrics.map((metric) => ({
    value: metric,
    label: metricsConfig[metric].title,
  }));

  const [selectedMetrics, setSelectedMetrics] = useState(
    defaultSelectedMetrics
  );
  // data and data formatted
  const [data, setData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  //Comparaison user or cabines
  const [comparisonMode, setComparisonMode] = useState("none");
  const [comparisonData, setComparisonData] = useState([]);
  const [triggerFetch, setTriggerFetch] = useState(false);

  const [error, setError] = useState("");

  // Chargement initial des utilisateurs
  useEffect(() => {
    Axios.get(`/getAllUserForSuperAdmin`)
      .then((response) => {
        const usersOptions = [
          { value: null, label: "Aucun utilisateur" }, // Option par défaut
          ...response.data.data.map((user) => ({
            value: user.ID,
            label: `${user.user_login} - ${user.display_name} - ${user.ID}`,
          })),
        ];

        setAllUsers(usersOptions);
        const userMap = response.data.data.reduce((acc, user) => {
          acc[user.ID] = {
            displayName: user.display_name,
            userLogin: user.user_login,
          };
          return acc;
        }, {});

        // Mise à jour de l'état avec userMap
        setUsersMap(userMap);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // chargement initial des cabines
  useEffect(() => {
    Axios.get(`/allCabines/`)

      .then(async function (response) {
        const cabineOptions = [
          { value: null, label: "Aucune cabine" }, // Option par défaut
          ...response.data.data.map((cab) => ({
            value: cab.id,
            label: `${cab.cabname} - ${cab.id}`,
          })),
        ];

        setAllCabines(cabineOptions);
        const cabineMap = response.data.data.reduce((acc, cab) => {
          acc[cab.id] = cab.cabname;
          return acc;
        }, {});
        // Mise à jour de l'état avec userMap
        setCabinesMap(cabineMap);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  //STATS DATA GENERALS CUMUL

  const fetchData = () => {
    if (!startDate || !endDate) return;

    let queryStringParts = [];

    // Gérer la logique de date en fonction des dates de début et de fin
    if (startDate && endDate) {
      queryStringParts.push(`startYear=${startDate.getFullYear()}`);
      queryStringParts.push(`startMonth=${startDate.getMonth() + 1}`);
      queryStringParts.push(`startDay=${startDate.getDate()}`);
      queryStringParts.push(`endYear=${endDate.getFullYear()}`);
      queryStringParts.push(`endMonth=${endDate.getMonth() + 1}`);
      queryStringParts.push(`endDay=${endDate.getDate()}`);
    } else if (startDate) {
      // Cas où seule une date de début (ou une date unique) est sélectionnée
      queryStringParts.push(`year=${startDate.getFullYear()}`);
      queryStringParts.push(`month=${startDate.getMonth() + 1}`);
      queryStringParts.push(`day=${startDate.getDate()}`);
    }

    // Ajouter les utilisateurs et les cabines sélectionnés à la requête
    if (selectedUsers && selectedUsers.length > 0) {
      queryStringParts.push(`uids=${selectedUsers.join(",")}`);
    }

    if (selectedCabines && selectedCabines.length > 0) {
      queryStringParts.push(`cabids=${selectedCabines.join(",")}`);
    }

    // Construire la chaîne de requête finale
    let queryString = queryStringParts.join("&");

    Axios.get(`/generalStats?${queryString}`)
      .then((response) => {
        if (!response.data.error) {
          setData(response.data.data);
        } else {
          setError(response.data.message);
        }
      })
      .catch((err) => setError(err.message));
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate, selectedUsers, selectedCabines]); // Déclencher fetchData sur changement de ces dépendances

  const fetchDetailedData = () => {
    if (!startDate || !endDate) return;

    let queryStringParts = [
      `startYear=${startDate.getFullYear()}`,
      `startMonth=${startDate.getMonth() + 1}`,
      `startDay=${startDate.getDate()}`,
      `endYear=${endDate.getFullYear()}`,
      `endMonth=${endDate.getMonth() + 1}`,
      `endDay=${endDate.getDate()}`,
    ];

    if (selectedUsers && selectedUsers.length > 0) {
      queryStringParts.push(`uids=${selectedUsers.join(",")}`);
    }

    if (selectedCabines && selectedCabines.length > 0) {
      queryStringParts.push(`cabids=${selectedCabines.join(",")}`);
    }

    // Utiliser une route différente qui correspond à la logique de récupération détaillée
    Axios.get(`/getDailyMetricsGeneral?${queryStringParts.join("&")}`)
      .then((response) => {
        if (!response.data.error) {
          setDetailedData(response.data.data);
        } else {
          setError(response.data.message);
        }
      })
      .catch((err) => setError(err.message));
  };

  useEffect(() => {
    fetchDetailedData();
  }, [startDate, endDate, selectedUsers, selectedCabines]); // Déclencher fetchDataDetailed sur changement de ces dépendances

  useEffect(() => {
    const fetchComparisonData = async () => {
      // Préparation des paramètres de date
      let queryString = `startYear=${startDate.getFullYear()}&startMonth=${startDate.getMonth() + 1}&startDay=${startDate.getDate()}&endYear=${endDate.getFullYear()}&endMonth=${endDate.getMonth() + 1}&endDay=${endDate.getDate()}`;
  
      // Ajout des paramètres pour les utilisateurs ou les cabines à comparer
      if (comparisonMode === "users" && selectedUsers.length > 0) {
        queryString += `&uids=${selectedUsers.join(",")}`;
      } else if (comparisonMode === "cabines" && selectedCabines.length > 0) {
        queryString += `&cabids=${selectedCabines.join(",")}`;
      } else {
        // Si aucun mode de comparaison n'est sélectionné ou aucune sélection valide n'est faite
        return;
      }
  
      try {
        const response = await Axios.get(`/compareUsersOrCabinesMetrics?${queryString}`);
        if (response.data && !response.data.error) {
          console.log("nouvelle data pour comparaison", response.data.data);
          setComparisonData(response.data.data);
        } else {
          console.error("Erreur lors de la récupération des données de comparaison", response.data.message);
        }
      } catch (error) {
        console.error("Erreur lors de la requête de comparaison", error);
      }
    };
  
    if (comparisonMode !== "none") {
      fetchComparisonData();
    }
  }, [comparisonMode, selectedUsers, selectedCabines, startDate, endDate, triggerFetch]);
  

  // GET user linked Ownerids
  const fetchLinkedAccounts = (ownerIds) => {
    // Assurez-vous que ownerIds est un tableau et convertissez-le en chaîne séparée par des virgules
    const ownerIdsParam = Array.isArray(ownerIds)
      ? ownerIds.join(",")
      : ownerIds;

    Axios.get(`/getUserInfosForStats?ownerIds=${ownerIdsParam}`)
      .then((response) => {
        if (!response.data.error && response.data.data.length > 0) {
          const linkedOptions = response.data.data.map((user) => ({
            value: user.ID,
            label: user.display_name || user.user_login, // Utilisez user.display_name ou user.user_login comme label
          }));
          setLinkedUsers(linkedOptions);
        } else {
          setLinkedUsers([]);
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des comptes liés", error);
      });
  };

  const handleLinkedUserChange = (selectedOptions) => {
    const linkedUserIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    // Ajoutez les utilisateurs liés sélectionnés aux utilisateurs déjà sélectionnés sans duplication
    setSelectedUsers((prevUsers) => [
      ...new Set([...prevUsers, ...linkedUserIds]),
    ]);
  };

  const handleUserChange = (selectedOptions) => {
    console.log(selectedOptions);

    if (selectedOptions.length === 1 && selectedOptions[0].value === null) {
      // Nettoyer les sélections d'utilisateurs et d'utilisateurs liés
      setSelectedUsers([]);
      setLinkedUsers([]);

      return;
    }

    const userIds = selectedOptions
      ? selectedOptions.map((option) => option.value).join(",")
      : "";

    setSelectedUsers(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );
    setLinkedUsers(
      selectedOptions ? selectedOptions.map((option) => option.value) : []
    );

    if (userIds) {
      fetchLinkedAccounts(userIds);
    }

    // Modifier l'URL pour utiliser une query string pour userIds
    const url = userIds
      ? `/getCabinesForSeveralUsers?userIds=${userIds}`
      : `/allCabines/`;

    Axios.get(url)
      .then((response) => {
        const userCabineOptions = userIds
          ? [
              { value: null, label: "Aucune cabine" },
              ...response.data.data.map((userCabine) => ({
                value: userCabine.id,
                label: `${userCabine.cabname} - ${userCabine.id}`,
              })),
            ]
          : [
              { value: null, label: "Aucune cabine" },
              ...response.data.data.map((userCabine) => ({
                value: userCabine.id,
                label: `${userCabine.cabname} - ${userCabine.id}`,
              })),
            ];

        setAllCabines(userCabineOptions);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCabineChange = (selectedOptions) => {
    const cabineIds = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedCabines(cabineIds); // Mise à jour avec le tableau des cabineIds

    // Logique supplémentaire si nécessaire
  };

  // Gestionnaire pour "Sélectionner tous les utilisateurs"
  const handleSelectAllUsers = (e) => {
    if (e.target.checked) {
      setSelectedUsers(allUsers.map((option) => option.value));
    } else {
      setSelectedUsers([]);
    }
  };

  // Gestionnaire pour "Sélectionner tous les utilisateurs liés"
  const handleSelectAllLinkedUsers = (e) => {
    if (e.target.checked) {
      // Ajoute tous les utilisateurs liés à selectedUsers, en évitant les doublons
      setSelectedUsers((prevUsers) => [
        ...new Set([
          ...prevUsers,
          ...linkedUsers.map((option) => option.value),
        ]),
      ]);
    } else {
      // Retire les utilisateurs liés de selectedUsers
      setSelectedUsers((prevUsers) =>
        prevUsers.filter(
          (user) => !linkedUsers.map((option) => option.value).includes(user)
        )
      );
    }
  };

  // Gestionnaire pour "Sélectionner toutes les cabines"
  const handleSelectAllCabines = (e) => {
    if (e.target.checked) {
      setSelectedCabines(allCabines.map((option) => option.value));
    } else {
      setSelectedCabines([]);
    }
  };

  // Transformation des données reçues
  const transformedData = useMemo(
    () =>
      data.reduce((acc, curr) => {
        acc[curr.mname] = curr.totalValue;
        return acc;
      }, {}),
    [data]
  );

  // Fonction pour extraire et cumuler les valeurs spécifiques pour plusieurs mname
  const getValuesForMnames = (mnames, defaultValue = 0) => {
    return mnames.reduce(
      (acc, mname) => acc + (transformedData[mname] || 0),
      defaultValue
    );
  };

  const handleComparisonModeChange = (newMode) => {
    // Gérer le changement de mode de comparaison
    if (newMode !== comparisonMode) {
      setComparisonMode(newMode);
      if (newMode === "users") {
        setSelectedCabines([]);
        setComparisonData([]);
      } else if (newMode === "cabines") {
        setSelectedUsers([]);
        setComparisonData([]);
      } else {
        setComparisonData([]);
      }
      // Déclenche la récupération des données
      setTriggerFetch(!triggerFetch); // Basculer la valeur pour déclencher l'effet
    }
  };

  return (
    <>
      <div className={styles.containerFilterAndSummary}>
        <div className={styles.usersNumberStats}>
          <UsersNumberStats
            totalUsers={allUsers.length}
            totalCabines={allCabines.length}
          />
        </div>
        <div className={styles.summaryOfFilters}>
          <SummaryOfFilters
            startDate={startDate}
            endDate={endDate}
            selectedUsers={selectedUsers}
            linkedUsers={linkedUsers}
            selectedCabines={selectedCabines}
            allUsers={allUsers}
            allCabines={allCabines}
            comparisonMode={comparisonMode}
          />
        </div>
        <div className={styles.containerFilter}>
          <h2>Filtres des données</h2>
          <div className={styles.selectors}>
            <DatePicker
              showIcon
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => {
                setStartDate(update[0]);
                setEndDate(update[1]);
              }}
              dateFormat="dd/MM/yyyy"
              showYearDropdown
              showMonthDropdown
              useShortMonthInDropdown
              placeholderText="Sélectionnez une plage de dates"
              isClearable={true}
              monthsShown={1}
            />
            <div className={styles.containerSelect}>
              <Select
                key={`user-select-${comparisonMode}`} // Clé dynamique basée sur comparisonMode
                name="uid"
                options={allUsers}
                isMulti
                onChange={handleUserChange}
                value={allUsers.filter((user) =>
                  selectedUsers.includes(user.value)
                )}
                placeholder="Sélectionner un utilisateur"
                isClearable
              />
              {/* Sélectionner tous les utilisateurs */}
              {/* <label>
                <input
                  type="checkbox"
                  onChange={handleSelectAllUsers}
                  checked={selectedUsers.length === allUsers.length}
                />{" "}
                Sélectionner tous les utilisateurs
              </label> */}
            </div>
            <div className={styles.containerSelect}>
              {linkedUsers.length > 0 && (
                <Select
                  name="linkedUid"
                  options={linkedUsers}
                  // value={linkedUsers.filter((user) =>
                  //   selectedUsers.includes(user.value)
                  // )}
                  onChange={handleLinkedUserChange}
                  placeholder="Sélectionner des utilisateurs liés"
                  className="my-3"
                  isClearable
                  isMulti
                />
              )}

              {selectedUsers.length > 0 && linkedUsers.length > 0 && (
                <label>
                  <input
                    type="checkbox"
                    onChange={handleSelectAllLinkedUsers}
                    checked={linkedUsers.every((user) =>
                      selectedUsers.includes(user.value)
                    )}
                  />
                  Sélectionner tous les utilisateurs liés
                </label>
              )}
            </div>
            <div className={styles.containerSelect}>
              <Select
                key={`cabine-select-${comparisonMode}`} // Clé dynamique basée sur comparisonMode
                name="cabid"
                options={allCabines}
                isMulti
                onChange={handleCabineChange}
                value={allCabines.filter((cabine) =>
                  selectedCabines.includes(cabine.value)
                )}
                placeholder="Sélectionner une cabine"
                isClearable
              />
              {/* Sélectionner toutes les cabines */}
              {/* <label>
                <input
                  type="checkbox"
                  onChange={handleSelectAllCabines}
                  checked={selectedCabines.length === allCabines.length}
                />{" "}
                Sélectionner toutes les cabines
              </label> */}
            </div>
          </div>
          <div className={styles.containerComparison}>
            <h2>Mode comparaison</h2>
            <div className={styles.containerRadioComparaison}>
              <label>
                <input
                  type="radio"
                  name="comparisonMode"
                  checked={comparisonMode === "users"}
                  onChange={() => handleComparisonModeChange("users")}
                />{" "}
                Comparaison des utilisateurs
              </label>
              <label>
                <input
                  type="radio"
                  name="comparisonMode"
                  checked={comparisonMode === "cabines"}
                  onChange={() => handleComparisonModeChange("cabines")}
                />{" "}
                Comparaison des cabines
              </label>
              <label>
                <input
                  type="radio"
                  name="comparisonMode"
                  checked={comparisonMode === "none"}
                  onChange={() => handleComparisonModeChange("none")}
                />{" "}
                Aucune comparaison
              </label>
            </div>
          </div>
        </div>
      </div>

      {/* /*ICI COMMENCE LES STATISTICS*/}

      <div>
        <div className={styles.containerMainStats}>
          <MiniCardStats
            data={getValuesForMnames(["HSStarted"])}
            title={"Total HS Started"}
            description={"Nombre de démarrage"}
            image={HSStartedImg}
          />
          <MiniCardStats
            data={getValuesForMnames(["ReportsGenerated"])}
            title={"Total Reports Generated"}
            description={"Rapport Générer"}
            image={HSReportImg}
          />
          <MiniCardStats
            data={getValuesForMnames([
              "SpeakersCalibrationCompleted",
              "HeadphonesCalibrationCompleted",
              "InsertsCalibrationCompleted",
            ])}
            title={"Total Calibration"}
            description={"Total des calibrations"}
            image={calibrationStats}
            backContent={
              <ul>
                <li>
                  Speakers Calibration:{" "}
                  <span>
                    {getValuesForMnames(["SpeakersCalibrationCompleted"])}
                  </span>
                </li>
                <li>
                  Headphones Calibration:{" "}
                  <span>
                    {getValuesForMnames(["HeadphonesCalibrationCompleted"])}
                  </span>
                </li>
                <li>
                  Inserts Calibration:{" "}
                  <span>
                    {getValuesForMnames(["InsertsCalibrationCompleted"])}
                  </span>
                </li>
              </ul>
            }
          />
          <MiniCardStats
            data={getValuesForMnames([
              "TonalAudiogramSaved",
              "VocalAudiogramSaved",
              "TSTAudiogramSaved",

              "LocalisationTestSaved",
            ])}
            title={"Total Audiogrammes"}
            description={"Total audiogrammes sauvegarder"}
            image={audiogramStats}
            backContent={
              <ul>
                <li>
                  Tonal:{" "}
                  <span>{getValuesForMnames(["TonalAudiogramSaved"])}</span>
                </li>
                <li>
                  Vocal:{" "}
                  <span> {getValuesForMnames(["VocalAudiogramSaved"])}</span>
                </li>
                <li>
                  TST: <span>{getValuesForMnames(["TSTAudiogramSaved"])}</span>
                </li>
                <li>
                  Localisation:{" "}
                  <span>{getValuesForMnames(["LocalisationTestSaved"])}</span>
                </li>
              </ul>
            }
          />
        </div>
        {comparisonMode === "cabines" || comparisonMode === "users" ? (
          <GlobalComparisonMetrics
            key={comparisonMode}
            comparisonData={comparisonData}
            defaultMetrics={[defaultMetrics]}
            comparisonType={comparisonMode}
            setComparisonMode={setComparisonMode}
            usersMap={usersMap}
            cabinesMap={cabinesMap}
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
            selectedMetrics={selectedMetrics}
            setSelectedMetrics={setSelectedMetrics}
          />
        ) : (
          <GlobalSelectMetrics
            key={comparisonMode}
            dataForChart={detailedData}
            defaultMetrics={[defaultMetrics]}
            timeInterval={timeInterval}
            setTimeInterval={setTimeInterval}
            selectedMetrics={selectedMetrics}
            setSelectedMetrics={setSelectedMetrics}
          />
        )}

        {/* <GlobalSelectMetrics dataForChart={detailedData} defaultMetrics={['HSStarted', 'OpenedMenuSpace']} /> */}
      </div>
    </>
  );
}

export default Statistics;
