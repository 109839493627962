import React, { useState, useEffect, useContext } from "react";
import styles from "./UserCard.module.scss";
import { AiFillSetting } from "react-icons/ai";
import { BsPatchExclamation } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";

import axios from "axios";
import { MdOutlineClose } from "react-icons/md";
import BubbleInfos from "../../UiComponents/bubleInfos/BubbleInfos";
import {
  InfosRole,
  InfosChangeRole,
} from "../../UiComponents/bubleInfos/Infos";
import Modal from "../../UiComponents/modals/Modal";
import WarningAdminModal from "../../UiComponents/modals/WarningAdminModal";
import useModal from "../../../hooks/useModal";
import UserSettings from "./UserSettings";

import { GreenLed, RedLed, YellowLed } from "./Led";
import Error from "../../UiComponents/error/Error";
import DeleteUser from "./DeleteUser";
import NavContext from "../../../context/NavContext";
import Axios from "../../../_services/caller.service";
import InputForms from "../../UiComponents/forms/InputForms";
import SendEmailConfirmationAccessHS from "../sendEmailConfirmation/SendEmailConfirmationAccessHS";

const UserCard = ({
  name,
  email,
  userRoleId,
  userId,
  displayRoleByUser,
  setUpdateRole,
  user,
  setUpateUserId,
  setCabId,
  cabines,
  setMaj,
  maj,
  createPermissionCabByCab,
  inviteUser,
  numberCabsAuth,
  selectDefaultValue,
  lastName,
  firstName,
  displayName,
  login,
  majRole,
  setMajRole,
}) => {
  // DISPLAY
  const [displaySettings, setDisplaySettings] = useState(false);
  const [displayDeleteUser, setDisplayDeleteUser] = useState(false);
  const [infosBubble, setInfosBubble] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const { ownerId, adminProfile,superAdmin  } = useContext(NavContext);
  const [cabAuth, setCabAuth] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // 1. État local pour la recherche
 

  // Filtrer les cabines selon la recherche cab by cab
  const filteredCabinesInCabByCab = cabines.filter((cab) =>
    cab.cabname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const { isShowing, toggle } = useModal();
  // OPEN settings Card
  const [open, setOpen] = useState(false);

  const updateUserRoleWithSelect = (e) => {
    if (e.target.value === "256") {
      toggle();
    } else {
      setUpdateRole(e.target.value); // store value role update
    }
  };

  const updateRoleInAdmin = () => {
    setUpdateRole("256");
    toggle();
  };

  // get user's ID for UPDATE role
  const getUserId = () => {
    const ID = inviteUser.filter((u) => user.ID === u.ID).map((u) => u.ID);

    setUpateUserId(ID);
  };
  const getCabAndUserId = (id) => {
    const test = getUserId();

    getUserId();
    getCabId(id);
  };

  const getCabId = (id) => {
    const cabID = cabines.filter((u) => id === u.id).map((u) => u.id);

    setCabId(cabID);
  };

  // display settings use'rs
  const displaySettingUserCard = () => {
    setDisplaySettings(!displaySettings);
  };

  const checkIfOneCabIsAuth = () => {
    if (displayRoleByUser(userRoleId) === "Aucun Droit") {
      return true;
    }
  };

  const oneCabOrMoreAreAuth = checkIfOneCabIsAuth();

  const authMustBeClickInfosUser = (params) => {
    setInfosBubble(true);
  };

  // GET ALL AUTH CABINE USER LINK
  useEffect(() => {
    Axios.post(`/allCabAreAuth/${ownerId}`, {
      data: {
        updateUserId: userId,
      },
    })

      .then((response) => {
        const statusAuth = response.data.data;
        setCabAuth(statusAuth);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, [refresh, setRefresh, ownerId, userId]);

  // ALL CAB PERMISSION
  const createPermissionsForAllCab = (checked) => {
    const ID = inviteUser.filter((u) => user.ID === u.ID).map((u) => u.ID);
    console.log(ID[0]);

    if (checked) {
      Axios.post(`/createPermissionsAllCab/${ownerId}`, {
        data: {
          updateUserId: ID[0],
        },
      })

        .then((response) => {
          console.log(response.data.data);
          const authAllCab = response.data.data;
          //  console.log(authAllCab);
          //  setCabAuth(authAllCab)
          setRefresh(!refresh);
          setMajRole(!majRole); // pour redeclencher le choix des droits
          setMaj(!maj); //relance le composant dans team ( getAuthCabineUser)
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      Axios.delete(`/deletePermissionsAllCab/${ownerId}?userId=${ID[0]}`)

        .then((response) => {
          // console.log(response.data.data);
          const authAllCab = response.data.data;
          //console.log(authAllCab);
          //setCabAuth(authAllCab)
          setRefresh(!refresh);
          //  setInviteUser(allPermissions);
          setMaj(!maj); //relance le composant dans team ( getAuthCabineUser)
          setMajRole(!majRole); // pour redeclencher le choix des droits
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const handleChangeAllCab = (e) => {
    const { checked } = e.target;

    createPermissionsForAllCab(checked); // execute fonction for update db create or delete row in TEAM

    setInfosBubble(false);
  };

  const handleChangeCabByCab = (e) => {
    const { checked } = e.target;

    createPermissionCabByCab(checked); // execute fonction for update db create or delete row in TEAM

    setInfosBubble(false);
  };

  return (
    <div
      className={`${styles.cardContainer} ${
        displaySettings ? styles.card_open : ""
      } ${open ? styles.cardSettingOpen : styles.cardContainer}`}
    >
      <div className={styles.userInfos}>
        <h3 className={styles.login}>{login}</h3>

        <div className={styles.name}>
          {!lastName || !firstName ? (
            <h3>{displayName}</h3>
          ) : (
            <h3>
              {lastName} {firstName}
            </h3>
          )}
        </div>

        <div className={styles.email}>
          <MdOutlineMailOutline
            className={styles.emailIcon}
            onMouseEnter={() => setShowEmail(true)}
            onMouseLeave={() => setShowEmail(false)}
            onClick={() => {
              let subject = encodeURIComponent(
                "[Hearing Space] Administrateur - Mise à jour de vos droits d'accès"
              );
              window.location.href = `mailto:${email}?subject=${subject}`;
            }}
          />
          {showEmail && <div className={styles.showEmail}>{email}</div>}
        </div>

        <div className={styles.formSelect} onClick={() => getUserId()}>
          {oneCabOrMoreAreAuth ? (
            <div
              onClick={() => setInfosBubble(!infosBubble)}
              className={styles.fakeSelect}
            >
              <span>Droits</span>
              <span className={styles.chevronBottom}></span>
            </div>
          ) : (
            <select
              onChange={updateUserRoleWithSelect}
              value={selectDefaultValue(userId)}
            >
              {/* <option value="0">--Choisir les droits--</option> */}
              <option value="1">Consultation</option>
              <option value="8">Calibration</option>
              {/* /*autoriser de faire le changement si je superAdmin ou owner*/ }
              {(superAdmin || adminProfile.length ===0 ) && <option value="256">Admin</option>}  
            </select>
          )}
        </div>

        <div
          // get cabId and User ID
          className={styles.containerCheckBox}
        >
          <input
            id="switchAll"
            type="checkbox"
            onChange={handleChangeAllCab}
            checked={cabAuth}
            // onClick={onPress}
          />
          <label htmlFor="switchAll"></label>
        </div>
        {/****** LED *************/}
        <div className={styles.numberCab}>
          {numberCabsAuth(userId) === 0 && (
            <RedLed Children={`${numberCabsAuth(userId)}/${cabines.length}`} />
          )}
          {numberCabsAuth(userId) === cabines.length && (
            <GreenLed
              Children={`${numberCabsAuth(userId)}/${cabines.length}`}
            />
          )}
          {numberCabsAuth(userId) !== cabines.length &&
            numberCabsAuth(userId) !== 0 && (
              <YellowLed
                Children={`${numberCabsAuth(userId)}/${cabines.length}`}
              />
            )}
        </div>

        <div className={styles.settings}>
          <UserSettings
            setDisplaySettings={setDisplaySettings}
            displaySettings={displaySettings}
          />
        </div>
        <div className={styles.deleteUser}>
          <DeleteUser
            displayDeleteUser={displayDeleteUser}
            setDisplayDeleteUser={setDisplayDeleteUser}
            name={name}
            lastName={lastName}
            userId={userId}
            setMaj={setMaj}
            maj={maj}
          />
        </div>
    
      </div>

      {/* Cabine PAR CABINE AUTH */}

      {infosBubble && (
        <div className={styles.oneCabMessage}>
          <BsPatchExclamation style={{ color: "orange", fontSize: "20px" }} />
          <span>Vous devez d'abord autoriser une cabine</span>
        </div>
      )}
   {    displaySettings && superAdmin && (<SendEmailConfirmationAccessHS login={login} userMail={email} />)}
      {displaySettings && (
        <InputForms
          id={styles.inputSearchCabByCab}
          placeholder={"chercher une cabine..."}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      )}
      {displaySettings &&
        filteredCabinesInCabByCab.length >= 1 &&
        filteredCabinesInCabByCab.map((cab) => {
          return (
            <div key={cab.id} className={styles.cabine}>
              <h3 key={cab.id} className={styles.cabinesName}>
                {" "}
                {cab.cabname}
              </h3>
              <div
                onMouseEnter={() => getCabAndUserId(cab.id)} // get cabId and User ID
                className={styles.containerCheckboxCabByCab}
              >
                <h3>Autoriser l'accès</h3>

                <div>
                  <input
                    id="switchOne"
                    type="checkbox"
                    onChange={handleChangeCabByCab}
                    checked={cab.auth}
                  />
                  <label htmlFor="switchOne"></label>
                </div>
              </div>
            </div>
          );
        })}

      <Modal
        isShowing={isShowing}
        hide={toggle}
        children={
          <WarningAdminModal
            name={name}
            lastName={lastName}
            updateRoleInAdmin={updateRoleInAdmin}
          />
        }
      />
    </div>
  );
};

export default UserCard;
