import React, { useEffect, useState } from "react";
import styles from "./InfosUser.module.scss";
import { RiAdminFill, RiUserAddFill } from "react-icons/ri";
import { IoMdCopy, IoMdInformationCircleOutline } from "react-icons/io";
import Axios from "../../../_services/caller.service";
import Select from "react-select";
import { toast } from "react-toastify";

const InfosConnexion = ({
  setDisplayConnectingUser,
  displayConnectingUser,
  infosUser,
  ownerId,
  setOwnerId,
}) => {
  const [displayInfos, setDisplayInfos] = useState(false);
  const [infosCabAccess, setInfosCabAccess] = useState([]);
  const [noAccess, setNoAccess] = useState("");

  useEffect(() => {
    Axios.get(`/infosCabAccess/${ownerId}`)

      .then(function async(response) {
        if (response.data.message === "aucun acces") {
          setNoAccess("Aucun accès aux cabines pour cet utilisateur");
          setInfosCabAccess([]);
        } else {
          setInfosCabAccess(response.data.data);
          setNoAccess("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [ownerId, setOwnerId]);

  // display user's role
  const displayRole = (role) => {
    if (role === 8) {
      return "Calibration";
    }
    if (role === 1) {
      return "Consultation";
    }
    if (role === 256) {
      return "Admin";
    }
    if (role === 2147483647) {
      return "Owner de la cabine";
    }
  };

  const options =
    infosCabAccess.length >= 1 &&
    infosCabAccess.map((cab) => ({
      value: cab.id, // l'identifiant de la cabine
      label: `${cab.cabname} - Role: ${displayRole(cab.role)}`, // le texte à afficher
      role: cab.role,
    }));

  const customStyles = {
    option: (provided, state) => {
      // Définir des styles personnalisés pour les options où le rôle est "Owner de la cabine"
      if (state.data.role === 2147483647) {
        return {
          ...provided,
          backgroundColor: "lightgreen", // Couleur de fond verte pour le rôle "Owner de la cabine"
          color: "black", // Couleur de texte pour le rôle "Owner de la cabine"
        };
      }

      // Styles par défaut pour les autres options
      return {
        ...provided,
        backgroundColor: "white",
        color: "black",
      };
    },
    // Vous pouvez ajouter d'autres styles personnalisés si nécessaire
  };

  const handleCopyEmail = () => {
    navigator.clipboard.writeText(infosUser.user_email).then(
      () => {
        toast("Email copié dans le presse-papier");
      },
      (err) => {
        console.error("Erreur lors de la copie : ", err);
      }
    );
  };
  return (
    <div className={styles.containerInfosConnexion}>
      <div className={styles.invite}>
        <div className={styles.containerTitle}>
          <div className={styles.title}>
            <div className={styles.hideOnSmallScreen}>
            <RiAdminFill  />
            </div>
            <h3>
              <span className={styles.ownerName}>
                {infosUser.display_name}{" "}
              </span>{" "}
              {/* <IoMdInformationCircleOutline
                style={{ color: "#6d5dfc", fontSize: "20px" }}
                className={styles.infosIcon}
                onClick={() => setDisplayInfos(!displayInfos)}
              />{" "} */}
            </h3>
          </div>
          <h3 className={styles.email}>{infosUser.user_email}</h3>
          <div className={styles.hideOnSmallScreen}>
            <IoMdCopy
              style={{ cursor: "pointer", marginLeft: "8px", color: "#6d5dfc" }}
              onClick={handleCopyEmail}
              title="Copier l'email"
            />
          </div>
        </div>
        {/* {displayInfos && (
          <div className={styles.infosConnexion}>
            <div>
              <span>Nom:</span> {infosUser.display_name}
            </div>
            <div>
              <span>Email:</span> {infosUser.user_email}
          
            </div>
            <div>
              <span>Login:</span> {infosUser.user_login}
            </div>
            <div> */}
        {/* <div className={styles.container}>
                <Select
                  options={options}
                  className={styles.reactSelect}
                  styles={customStyles}
                  placeholder="Sélectionner une cabine"
                /> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* )} */}
      </div>
    </div>
  );
};

export default InfosConnexion;
