import React from 'react';

const OupoutNotConnected = () => {



    return (
        <div>
          Deux possibilités: <br/>
           - Soit la sortie est effectivement pas branché à l'ordinateur dans ce cas il faut la branché.<br/>
           - Soit il faut réassocier la carte son côté serveur car le GUID et le nom de la carte ont changés.
        </div>
    );
};

export default OupoutNotConnected;