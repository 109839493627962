// config.js

export const GROUP_TYPES = ['vyv3', 'krys', 'principale','entendre',];
export const RESTRICTIONS = ['download', 'statistic','calibration'];
export const PERMISSIONS = ['changeCabName'];

export const GROUP_COLORS = {
    vyv3: '#175071',  // couleur pour vyv3
    krys: '#1b64d7',  // couleur pour krys
    principale: '#4a5759',  // couleur pour principale
    entendre: '#5ABFB5'  // couleur pour principale
  };


