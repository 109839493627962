import Axios from "./caller.service";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import NavContext from "../context/NavContext";

//IF TOKEN IS EXPIRED THE AUTH MIDDLEWARE RESEND AN ERROR AND AXIO CATCH ERROR AND WE
//DICONNNECT USER

const SessionUser = () => {
  const navigate = useNavigate();
  const { userAuth,setUserAuth } = useContext(NavContext);


  
  useEffect(() => {
    const interceptor = Axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("jwt");
          localStorage.setItem("isLoggedIn", "false");
          navigate("/"); 
          setUserAuth(false)
        }
        return Promise.reject(error);
      }
    );

    // Nettoyage de l'intercepteur lors du démontage du composant
    return () => {
      Axios.interceptors.response.eject(interceptor);
    };
  }, [navigate]); // Ajoutez navigate comme dépendance si nécessaire

  return <div></div>;
};

export default SessionUser;

