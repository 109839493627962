import React from "react";
import styles from "./RulesPassword.module.scss";

const RulesPassword = () => {
  return (
    <div className={styles.rules_password}>
      <span className={styles.title_rules}>
        Votre mot de passe doit contenir :
      </span>
      <span>* au moins 8 caractères</span>
      <span>* au moins 1 majuscule</span>
      <span>* au moins 1 minuscule</span>
      <span>* au moins 1 nombre</span>
      <span>* au moins 1 caractère spéciale</span>
    </div>
  );
};

export default RulesPassword;
