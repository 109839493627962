import { JSBase64Decode } from "../../CustomHooks/DecodedBase64";

// simple value

export const displayOneValue = (data, type, value) => {
  const valueData = data
    .filter(
      (gen) => gen.fname.includes(`${type}`) && gen.type.includes(`${value}`)
    )
    .map((val) => val.fval);
  return valueData;
};

// for soundCard components dispach good value
export const displayCalibValue = (data, type) => {
  const valueData = data
    .filter((gen) => gen.fname.includes(`${type}`))
    .map((val) => val.fval);

  return valueData;
};

// display date

export const displayDate = (data, fname) => {
  const valueData = data
    .filter(
      (gen) => gen.fname.includes(`${fname}`) 
    )
    .map((val) => {
      return ticksToDate(val.fval);
    });
  return valueData;
};

export const displayEncodede64 = (data, type, value) => {
  const valueData = data
    .filter(
      (gen) => gen.fname.includes(`${type}`) && gen.type.includes(`${value}`)
    )
    .map((val) => b64_to_utf8(val.fval));

  return valueData;
};
//get infos center adress

export const displayEncodededForInfosAdress = (data, type) => {
  const valueData = data
    .filter((gen) => gen.fname.includes(`${type}`))
    .map((val) => b64_to_utf8(val.fval));

  return valueData;
};

export const displayMicroValue = (data, type) => {
  const valueData = data
    .filter((gen) => gen.fname.includes(`${type}`))
    .map((val) => val.fval);

  return valueData;
};
export const displayAngleValue = (data, type) => {
  const valueData = data
    .filter((gen) => gen.fname.includes(`${type}`))
    .map((val) => val.fval);

  return valueData;
};
export const displayAngleValueSvg = (data, type, color) => {
  const valueData = data
    .filter((gen) => gen.fname.includes(`${type}`))
    .map((val) => val.fval);
  const number = parseInt(valueData, 10);

  return [{ angle: number, color: color }];
};

export const displayArrayValue = (data,type, start = 0, end = 1000) => {
  const valueData = data
    .filter(
      (gen) => gen.fname.includes(`${type}`)
    )
    .map((val) => {
      return val.fval.split(",").slice(start, end);
    });
  return valueData;
};

// fonction decoded base 64 for character special ok
function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}




// verify is string is in base 64
function isBase64(str) {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return btoa(atob(str)) === str;
  } catch (err) {
    return false;
  }
}
// transform ticks date in date
export function ticksToDate(ticks) {
  const options = {
  //  weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
   // hour: "numeric",
    //minute: "numeric",
  };
  const dateTicks = new Date(
    ticks / 1e4 + new Date("0001-01-01T00:00:00+02:00").getTime()
  );
  const date = dateTicks.toLocaleDateString("fr-FR", options);

  if (ticks.length === 18) {
    return date;
  } else {
    return ticks;
  }
}
// export function ticksToDateYearMonthDay(ticks) {
//   const options = {
//     //  weekday: "short",
//       year: "numeric",
//       month: "2-digit",
//       day: "2-digit",
//       hour: "2-digit",
//       minute: "2-digit",
//     };
//     const dateTicks = new Date(
//       ticks / 1e4 + new Date("0001-01-01T00:00:00+02:00").getTime()
//     );
//     const dateComponents = dateTicks.toLocaleDateString("fr-FR", options).split('/');

//     // Réorganisez les composants pour obtenir le format année_mois_jour
//     const formattedDate = `${dateComponents[2]}_${dateComponents[1]}_${dateComponents[0]}`;
  

//       return formattedDate;

// }

export function ticksToDateYearMonthDay(ticks) {
  // Convertir les ticks en date en tenant compte du décalage de l'époque .NET
  const dateTicks = new Date(
    ticks / 1e4 + new Date("0001-01-01T00:00:00+02:00").getTime()
  );

  // Extraire les composants de date et heure pour formater correctement
  const day = String(dateTicks.getDate()).padStart(2, '0');
  const month = String(dateTicks.getMonth() + 1).padStart(2, '0'); // Les mois commencent à 0
  const year = dateTicks.getFullYear();
  const hours = String(dateTicks.getHours()).padStart(2, '0');
  const minutes = String(dateTicks.getMinutes()).padStart(2, '0');

  // Formater la date et l'heure
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}


