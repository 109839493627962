import Axios from "../../../_services/caller.service";
import React, { useContext, useState } from "react";

import { handleValidation } from "../../../utils/formValidation";
import Error from "../../UiComponents/error/Error";
import BtnForms from "../../UiComponents/forms/BtnForms";
import ContainerForm from "../../UiComponents/forms/ContainerForm";

import InputPassword from "../../UiComponents/forms/InputPassword";
import RulesPassword from "../../UiComponents/forms/RulesPassword";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";
import NavContext from "../../../context/NavContext";

const ChangePassword = () => {
  const [password, setPassword] = useState("password");
  const [password2, setPassword2] = useState("password");
  const [oldPassword, setOldPassword] = useState("password");

  //CONTEXT

  const { ownerProfile, ownerId } = useContext(NavContext);
  //DISPLAY

  const [displayForms, setDisplayForms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setEror] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorValidation, setErrorValidation] = useState(null);
  const [loading, setLoading] = useState(false);

  const submitChangePassword = (e) => {
    e.preventDefault();
    
    Axios.post("/login", {
      email: ownerProfile.user_email,
      password: oldPassword,
    })
      .then((response) => {
        Axios.put("/changePassword", {
          data: {
            id: ownerId,
            password,
            password2,
          },
        })
          .then((response) => {
            setLoading(false);
            if (response.data.error) {
                setEror(true)
              setErrorMessage(response.data.message);
              setTimeout(() => {
                setEror(false)
              }, 1500);
            }
            if (!response.data.error) setSuccess(true);
            setSuccessMessage(response.data.message);
            setSuccess(true)
            setTimeout(() => {
               setSuccess(false) 
            }, 1500);
          })
          .catch((error) => {});
      })
      .catch((err) => {
        console.log(error);
      });
  };

  return (
    <div>
      <ContainerForm
        onSubmit={submitChangePassword}
        modal={false}
        children={
          <>
            <InputPassword
              onChange={(e) => setOldPassword(e.target.value.trim())}
              placeholder="Ancien mot de passe"
            />
            <InputPassword
              onChange={(e) => setPassword(e.target.value.trim())}
              placeholder="Nouveau mot de passe"
              onKeyUp={(e) => setErrorValidation(handleValidation(e))}
            />
            <InputPassword
              onChange={(e) => setPassword2(e.target.value.trim())}
              placeholder="Nouveau mot de passe"
            />
            {errorValidation !== '' && errorValidation !== null && <Error error={errorValidation} />}
            {errorValidation === "" && (
              <Success success={"mot de passe valide"} />
            )}
            <BtnForms
              text="Réinitialiser mon mot de passe"
            
            />
            {success && <Success success={successMessage} />}
            {error && <Error error={errorMessage} />}
            {loading && <Loader />}
            {/* {loaderLogin && <Loader />} */}
            <RulesPassword />
          </>
        }
      />
    </div>
  );
};

export default ChangePassword;
