import React from "react";
import styles from "./ContainerForm.module.scss";

const ContainerForm = ({ onSubmit, children, modal }) => {
  return (
    <div className={styles.containerForms}>
      <div className={styles.screen}>
        <div className={styles.screenContent}>
          <form onSubmit={onSubmit} className={styles.login}>
            {children}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContainerForm;
