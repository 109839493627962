import React, { useState } from "react";
import CardHeaderPage from "../../UiComponents/card/CardHeaderPage";
import HeaderNavSupportHS from "../headerNav/HeaderNavSupportHS";
import TodoList from "../todoStep/TodoList";
import NavBarGuideCalibration from "./nav/NavBarGuideCalibration";
import styles from "./CalibrationGuide.module.scss";
import InsertsGuideCalibration from "./inserts/InsertsGuideCalibration";
import AudioHeadphoneGuideCalibration from "./audioHeadphone/AudioHeadphoneGuideCalibration";
import SpeakersGuideCalibation from "./speakers/SpeakersGuideCalibration";
import HeadphoneGuideCalibration from "./headphone/HeadPhoneGuideCalibration";

const CalibrationGuide = () => {
  const [toggleState, setToggleState] = useState(1);

  return (
    <div>
      <HeaderNavSupportHS />
      <CardHeaderPage
        text={"Procédure pour toutes les calibrations"}
        textAlign={"center"}
      />

      {/* <TodoList /> */}
   
      <NavBarGuideCalibration
        setToggleState={setToggleState}
        toggleState={toggleState}
      />

      {toggleState === 1 && <InsertsGuideCalibration />}
      {toggleState === 2 && <HeadphoneGuideCalibration />}
      {toggleState === 3 && <SpeakersGuideCalibation />}
      {toggleState === 4 && <AudioHeadphoneGuideCalibration />}
    </div>
  );
};

export default CalibrationGuide;
