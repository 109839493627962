import React, { useEffect, useState } from "react";
import SupportCard from "../../UiComponents/card/SupportCard";
import styles from "./HeaderNavSupportHS.module.scss";
//SVG
import { BsCloudDownload, BsFillPersonPlusFill } from "react-icons/bs";
import { BiErrorCircle } from "react-icons/bi";
import { BiMicrophone, BiSupport } from "react-icons/bi";
import {
  RiUserSettingsLine,
  RiInstallLine,
  RiSoundModuleFill,
} from "react-icons/ri";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";

import { SiEgghead } from "react-icons/si";
const HeaderNavSupportHS = () => {
  const [displayNav, setDisplayNav] = useState(true);
  const [mobile, setMobile] = useState(true);

  const colorForCards = {
    1: "#fe6d73",
    2: "#89b0ae",
    3: "#bee3db",
    4: "#ffd6ba",
    5: "#555b6e",
  };

  const colorSvg = "#fff";

  const sizeSvg = mobile ? 30:45;
  const sizeSvgHamburger = 35;

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 950) {
        setMobile(true);
        setDisplayNav(false)
      } else {
        setMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <button onClick={() => setDisplayNav(!displayNav)}>{displayNav ? 'cacher le menu': 'ouvrir le menu'}</button> */}
      {!displayNav ? (
        <GiHamburgerMenu
          style={{ fontSize: sizeSvgHamburger, cursor: "pointer" }}
          onClick={() => setDisplayNav(true)}
        />
      ) : (
        <GrClose
          style={{ fontSize: sizeSvgHamburger, cursor: "pointer" }}
          onClick={() => setDisplayNav(false)}
        />
      )}

      {displayNav &&  (
        <div className={styles.header}>
          <SupportCard
            title={"Erreur"}
            backGround={colorForCards[1]}
            link="/private/supportHS/"
            svg={
              <BiErrorCircle style={{ fontSize: sizeSvg, color: colorSvg }} />
            }
          />
          <SupportCard
            title={"Calibrations"}
            backGround={colorForCards[4]}
            link="/private/supportHS/calibrationGuide"
            svg={
              <BiMicrophone style={{ fontSize: sizeSvg, color: colorSvg }} />
            }
          />
          <SupportCard
            title={"Dirac"}
            backGround={colorForCards[2]}
            link="/private/supportHS/Dirac"
            svg={
              <RiSoundModuleFill
                style={{ fontSize: sizeSvg, color: colorSvg }}
              />
            }
          />
          <SupportCard
            title={"casque occulus"}
            backGround={colorForCards[5]}
            link="/private/supportHS/vrGuide"
            svg={<SiEgghead style={{ fontSize: sizeSvg, color: colorSvg }} />}
          />
          <SupportCard
            title={"Installation HS"}
            backGround={colorForCards[3]}
            link="/private/supportHS/hsGuide"
            svg={
              <RiInstallLine style={{ fontSize: sizeSvg, color: colorSvg }} />
            }
          />
          <SupportCard
            title={"Contact"}
            backGround={colorForCards[2]}
            link="/private/supportHS/contactHelp"
            svg={<BiSupport style={{ fontSize: sizeSvg, color: colorSvg }} />}
          />
        </div>
      )}
    </>
  );
};

export default HeaderNavSupportHS;
