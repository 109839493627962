import React, { useState } from 'react';
import styles from './MiniCardStats.module.scss';

const MiniCardStats = ({ data, title, description, image, backContent }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={styles.cardFlipContainer} onMouseEnter={handleFlip} onMouseLeave={handleFlip}>
      <div className={`${styles.card} ${isFlipped ? styles.isFlipped : ''}`}>
        {/* Face avant */}
        <div className={`${styles.cardFace} ${styles.cardFaceFront}`}>
          <div className={styles.cardImage}>
            <img src={image} alt="" />
            <span className={styles.cardTitle}>{title}</span>
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardText}>
              <strong>{description}</strong>: <span>{data}</span>
            </div>
          </div>
        </div>
        {/* Face arrière */}
        <div className={`${styles.cardFace} ${styles.cardFaceBack}`}>
          <div className={styles.backContent}>
            {/* Insérez ici le contenu supplémentaire pour l'arrière de la carte */}
            {backContent}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniCardStats;
