import React from "react";
import styles from "./Infos.module.scss";

export const InfosRole = () => {
  return (
    <div className={styles.infosRole}>
      <ul>
        <li>
          <span> Consultation: </span>
          L'utilisateur peut effectuer des tests avec Hearing Space.
        </li>
        <li>
          <span>Calibration: </span>
          L'utilisateur à les droits de consultations et peut également calibrer Hearing Space.
        </li>
        <li>
          <span>Admin: </span>
          L'utilisateur peut gérer la liste des utilisateurs invités.
        </li>
      </ul>
    </div>
  );
};

export const InfosChangeRole = () => {
  return (
    <div className={styles.infosRole}>
      Pour modifier les droits, Une ou plusieurs cabines doivent-être autorisé
    </div>
  );
};
