import React from "react";
import styles from "./Loader.module.scss";
import logo from "../../../pics/favicon.png";

const Loader = () => {
  return (
    <img className={styles.loader} src={logo} alt="" />

    // <div className={styles.ldsEllipsis}><div></div><div></div><div></div><div></div></div>
  );
};

export default Loader;
