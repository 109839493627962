import React from 'react';
import { MdOutlineWavingHand } from "react-icons/md";
import styles from './WelcomeCard.module.scss'


export  const WelcomeCardOwner = ({owner}) => {
    return (
        <div className={styles.welcome}>
        <h1>
      Bonjour, {owner}.
          <MdOutlineWavingHand />
        </h1>
        <p>Ici vous pouvez gérer les droits de votre équipe</p>
      </div>
    );
};

export  const WelcomeCardAdmin = ({admin,owner}) => {
    return (
        <div className={styles.welcome}>
        <h1>
      Bonjour,{admin}.
          <MdOutlineWavingHand />
        </h1>
        <p>En tant qu'Admin des cabines de {owner} vous pouvez gérer les droits de votre équipe</p>
      </div>
    );
};

