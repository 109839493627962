import React, { useState } from "react";
import { FiRefreshCcw } from "react-icons/fi";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  BlueLedSolo,
  GreenLedSolo,
  RedLedSolo,
  YellowLedSolo,
} from "../../Team/userCard/Led";
import InputForms from "../../UiComponents/forms/InputForms";
import styles from "./HeaderArrayCertificat.module.scss";
const HeaderArrayCertifcat = ({ searchCabCertif, setSearchCabCertif }) => {


  const [displayInfosCodeLed, setDisplayInfosCodeLed] = useState(false);
  const sizeForLed = "12px";

  return (
    <div className={styles.container}>
      <div className={styles.containerSearch}>
        <InputForms
          type={"text"}
          placeholder={"recherche certificat"}
          value={searchCabCertif}
          onChange={(e) => setSearchCabCertif(e.target.value.toLowerCase())}
          paddingLeft={'10px'}
        />
        {searchCabCertif !== "" && (
          <FiRefreshCcw
            className={styles.refreshSvg}
            onClick={() => setSearchCabCertif("")}
          />
        )}

        <IoMdInformationCircleOutline
          size={"25px"}
          style={{ cursor: "pointer", padding: "15px" ,color:'#6d5dfc'}}
          onClick={() => setDisplayInfosCodeLed(!displayInfosCodeLed)}
          onMouseOver={() => setDisplayInfosCodeLed(true)}
          onMouseLeave={() => setDisplayInfosCodeLed(false)}
        />
      </div>

      {displayInfosCodeLed && (
        <div className={styles.containerCodeLed}>
          <div className={styles.colorCodeLed}>
            <GreenLedSolo
              className={styles.led}
              width={sizeForLed}
              height={sizeForLed}
              margin={"0px"}
            />
            <h3>Calibration à jour</h3>
          </div>
          <div className={styles.colorCodeLed}>
            <YellowLedSolo
              width={sizeForLed}
              height={sizeForLed}
              margin={"0px"}
            />
            <h3>Calibration à faire bientôt</h3>
          </div>
          <div className={styles.colorCodeLed}>
            <RedLedSolo width={sizeForLed} height={sizeForLed} margin={"0px"} />
            <h3>Calibration en retard</h3>
          </div>
       
        </div>
      )}
    </div>
  );
};

export default HeaderArrayCertifcat;
