import React from 'react';
import styles from './DeleteUserWarningModal.module.scss'
import Button from '../Button';
import {BsFillExclamationTriangleFill } from "react-icons/bs";


const DeleteUserWarningModals = ({deleteUser,userId}) => {
    return (
        <div className={styles.wrapper}>
            <BsFillExclamationTriangleFill style={{color:'red', fontSize:'50px'}} />
            <h2> Attention vous êtes sur le point de supprimer un utilisateur. Il ne pourra donc plus accéder à vos cabines.</h2>

            
<Button styles={'btnValidation'} text={'Confirmez la suppression'} onPress={()=>deleteUser(userId)}/>

            
        </div>
    );
};

export default DeleteUserWarningModals;