import React, { useState } from "react";
import styles from "./HeaderInviteUser.module.scss";
import { MdInfoOutline } from "react-icons/md";
import { CgChevronUpO, CgChevronDownO } from "react-icons/cg";
import BubbleInfos from "../../UiComponents/bubleInfos/BubbleInfos";
import { InfosRole } from "../../UiComponents/bubleInfos/Infos";

const HeaderInviteUser = ({ setSorted }) => {
  const [infosBubble, setInfosBubble] = useState(false);

  const sorted = (order) => {
    setSorted(order);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.name}>Nom/prénom</h3>
          <div className={styles.chevronFilterName}>
            <CgChevronUpO
              className={styles.arrow}
              onClick={() => sorted("ascLogin")}
            />
            <CgChevronDownO
              className={styles.arrow}
              onClick={() => sorted("dscLogin")}
            />
          </div>
        </div>
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.email}>Email</h3>
          <div className={styles.chevronFilterEmail}>
            <CgChevronUpO
              className={styles.arrow}
              onClick={() => sorted("ascName")}
            />
            <CgChevronDownO
              className={styles.arrow}
              onClick={() => sorted("dscName")}
            />
          </div>
        </div>
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.dateInvitation}>
            {" "}
            Date <br />
            d'invitation
          </h3>
          <div className={styles.chevronFilterInvitation}></div>
        </div>
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.dateExpiration}>
            {" "}
            Date <br /> d'expiration{" "}
          </h3>
          <div className={styles.chevronFilterExpiration}>
            <CgChevronUpO
              className={styles.arrow}
              onClick={() => sorted("ascExpiration")}
            />
            <CgChevronDownO
              className={styles.arrow}
              onClick={() => sorted("dscExpiration")}
            />
          </div>
        </div>
        <div>
          <h3 className={styles.actions}>Actions</h3>
        </div>

        {infosBubble && <BubbleInfos children={<InfosRole />} />}
      </div>
    </>
  );
};

export default HeaderInviteUser;
