import React, { useEffect, useState } from "react";
import axios from "axios";
import styles from "./Password.module.scss";
import Error from "../UiComponents/error/Error";
import Loader from "../UiComponents/loader/Loader";
import { FaChevronRight, FaUserAlt } from "react-icons/fa";
import {
  AiFillLock,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from "react-icons/ai";

import {
  handleValidation,
  mediumRegex,
  strongRegex,
} from "../../utils/formValidation";
import Success from "../UiComponents/sucess/Success";
import logoHS from "../../pics/logo-hearingspace.png";
import ContainerForm from "../UiComponents/forms/ContainerForm";
import InputPassword from "../UiComponents/forms/InputPassword";
import BtnForms from "../UiComponents/forms/BtnForms";
import RulesPassword from "../UiComponents/forms/RulesPassword";
import logo from "../../pics/logo-hearingspace.png";

const ResetPassword = () => {
  const [password, setPassword] = useState("password");
  const [password2, setPassword2] = useState("password");

  //DISPLAY

  const [displayForms, setDisplayForms] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setEror] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorValidation, setErrorValidation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  //VERIFY URL TOKEN
  useEffect(() => {
    setLoading(true);
    let params = new URLSearchParams(document.location.search);
    let token = params.get("token"); // token verify in backend
    let id = params.get("id");

    axios
      .post(`${process.env.REACT_APP_API_URL}api/verifyToken`, {
        data: {
          token,
          id,
        },
      })

      .then(function (response) {
        if (response.data.msg === "url ok") {
          setDisplayForms(true);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //FORMS SUBMIT
  const submitChangePassword = (e) => {
    e.preventDefault();

    let params = new URLSearchParams(document.location.search);
    let token = params.get("token"); // token verify in backend
    let id = params.get("id");

    if (password !== password2) {
      setEror(true);
      setErrorMessage("Les mots de passe ne correspondent pas");
      return;
    }

    if (strongRegex.test(password) === false) {
      setEror(true);
      setErrorMessage("Le mot de passe n'est pas assez fort");
      return;
    }
    setLoading(true);
    axios
      .put(`${process.env.REACT_APP_API_URL}api/resetPassword`, {
        data: {
          token,
          id,
          password,
          password2,
        },
      })

      .then(function (response) {
        setLoading(false);
        if (response.data.error) {
          setErrorMessage(response.data.message);
        }
        if (!response.data.error) {
          setSuccess(true);
          setEror(false);
          setSuccessMessage(response.data.message);
          setShowSuccessMessage(true); // Show success message instead of form
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      {loading && <Loader />}
      {showSuccessMessage ? (
        // Success message when password has been changed
        <div className={styles.successMessage}>
          <img src={logoHS} alt="Hearing Space Logo" />
          <h2>Votre mot de passe a été changé avec succès !</h2>
          <p>
            Vous pouvez maintenant retourner sur Hearing Space. Si vous avez
            besoin d'assistance, n'hésitez pas à contacter le support.
          </p>
         
        </div>
      ) : displayForms && !loading ? (
        // Password reset form
        <div className="fixed-container-modals">
          <img className={styles.logo} src={logoHS} alt="Hearing Space Logo" />
          <ContainerForm
            onSubmit={submitChangePassword}
            modal={true}
            children={
              <>
                <InputPassword
                  onChange={(e) => setPassword(e.target.value.trim())}
                  placeholder="Nouveau mot de passe"
                  onKeyUp={(e) => setErrorValidation(handleValidation(e))}
                />
                <InputPassword
                  onChange={(e) => setPassword2(e.target.value.trim())}
                  placeholder="Confirmer le nouveau mot de passe"
                />
                {errorValidation !== "" && errorValidation !== null && (
                  <Error error={errorValidation} />
                )}
                {errorValidation === "" && (
                  <Success success={"Mot de passe valide"} />
                )}
                <BtnForms
                  text="Réinitialiser mon mot de passe"
                  svg={<FaChevronRight />}
                />
                {success && <Success success={successMessage} />}
                {error && <Error error={errorMessage} />}
                {loading && <Loader />}
                <RulesPassword />
              </>
            }
          />
        </div>
      ) : (
        // Error message when token verification fails
        <div className={styles.error_token}>
          <img src={logoHS} alt="Hearing Space Logo" />
          <p>
            Vous êtes sur cette page car vous souhaitez modifier votre mot de
            passe Hearing Space cependant une erreur est survenue. Veuillez
            refaire la démarche de réinitialisation afin de recevoir un nouveau
            lien.
          </p>
          <a href={process.env.REACT_APP_HOME}>
            Accueil DashBoard Hearing Space
          </a>
        </div>
      )}
    </>
  );
};

export default ResetPassword;
// import React, { useEffect, useState } from "react";
// import axios from "axios";

// import Error from "../UiComponents/error/Error";
// import Loader from "../UiComponents/loader/Loader";
// import { FaChevronRight, FaUserAlt } from "react-icons/fa";
// import {
//   AiFillLock,
//   AiOutlineEyeInvisible,
//   AiOutlineEye,
// } from "react-icons/ai";

// import {
//   handleValidation,
//   mediumRegex,
//   strongRegex,
// } from "../../utils/formValidation";
// import Success from "../UiComponents/sucess/Success";
// import logoHS from '../../pics/logo-hearingspace.png'

// const ResetPassword = () => {
//   const [passwordType, setPasswordType] = useState("password");
//   const [password, setPassword] = useState("password");
//   const [password2, setPassword2] = useState("password");

//   //DISPLAY

//   const [displayForms, setDisplayForms] = useState(false);
//   const [errorMessage, setErrorMessage] = useState(false);
//   const [successMessage, setSuccessMessage] = useState("");
//   const [error, setEror] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [errorValidation, setErrorValidation] = useState(null);
//   const [loading, setLoading] = useState(false);

//   //VERIFY URL TOKEN
//   useEffect(() => {
//     let params = new URLSearchParams(document.location.search);
//     let token = params.get("token"); // token verify in backend
//     let id = params.get("id");

//     axios
//       .post(`${process.env.REACT_APP_API_URL}api/verifyToken`, {
//         data: {
//           token,
//           id,
//         },
//       })

//       .then(function (response) {
// if(response.data.msg ==='url ok'){

//   setDisplayForms(true)
// }

//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }, []);

//   const submitChangePassword = (e) => {
//     e.preventDefault();

//     let params = new URLSearchParams(document.location.search);
//     let token = params.get("token"); // token verify in backend
//     let id = params.get("id");

//     if (password !== password2) {
//       setEror(true);
//       setErrorMessage("Les mots de passe ne correspondent pas");
//       return;
//     }
//     console.log(mediumRegex.test(password));

//     if (strongRegex.test(password) === false) {
//       console.log("ii");
//       setEror(true);
//       setErrorMessage("Le mot de passe n'est pas assez fort");
//       return;
//     }
//     setLoading(true);
//     axios
//       .put(`${process.env.REACT_APP_API_URL}api/resetPassword`, {
//         data: {
//           token,
//           id,
//           password,
//           password2,
//         },
//       })

//       .then(function (response) {
//         setLoading(false);
//         if (response.data.error) {
//           setErrorMessage(response.data.message);
//         }
//         if (!response.data.error) setSuccess(true);
//         setSuccessMessage(response.data.message);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   const togglePassword = () => {
//     if (passwordType === "password") {
//       setPasswordType("text");
//       return;
//     }
//     setPasswordType("password");
//   };

//   return (
//     <>
//     {displayForms ? (
//       <>

//       <div className="fixed-container-modals">

//         <div className="container">
//           <div className="screen">
//             <div className="screen__content">
//               <form onSubmit={submitChangePassword} className="login">
//                 <div className="login__field password">
//                   <AiFillLock />
//                   <input
//                     type={passwordType}
//                     className="login__input"
//                     placeholder="Nouveau mot de passe"
//                     onChange={(e) => setPassword(e.target.value.trim())}
//                     onKeyUp={(e) => setErrorValidation(handleValidation(e))}
//                     name="pwd"
//                     required
//                   />

//                   <div onClick={togglePassword}>
//                     {passwordType === "password" ? (
//                       <AiOutlineEyeInvisible />
//                     ) : (
//                       <AiOutlineEye />
//                     )}
//                   </div>
//                 </div>
//                 <div className="login__field password">
//                   <AiFillLock />
//                   <input
//                     type={passwordType}
//                     className="login__input"
//                     placeholder="Confirmation mot de passe"
//                     onChange={(e) => setPassword2(e.target.value.trim())}
//                     name="pwd"
//                     required
//                   />
//                   <div onClick={togglePassword}>
//                     {passwordType === "password" ? (
//                       <AiOutlineEyeInvisible />
//                     ) : (
//                       <AiOutlineEye />
//                     )}
//                   </div>
//                 </div>
//                 {errorValidation !== null && <Error error={errorValidation} />}
//                 {errorValidation === "" && (
//                   <Success success={"mot de passe valide"} />
//                 )}
//                 <button className="button login__submit">
//                   <div>Réinialiser mon mot de passe</div>
//                   <FaChevronRight />
//                 </button>
//                 {success && <Success success={successMessage} />}
//                 {error && <Error error={errorMessage} />}
//                 {loading && <Loader />}
//                 {/* {loaderLogin && <Loader />} */}
//                 <div className="rules_password">
//                   <span className="title_rules">
//                     Votre mot de passe doit contenir:
//                   </span>
//                   <span>* au moins 8 caractères</span>
//                   <span>* au moins 1 majuscule</span>
//                   <span>* au moins 1 minuscule</span>
//                   <span>* au moins 1 nombre</span>
//                   <span>* au moins 1 caractère spéciale</span>
//                 </div>
//               </form>
//             </div>
//             <div className="screen__background">
//               <span className="screen__background__shape screen__background__shape4"></span>
//               <span className="screen__background__shape screen__background__shape3"></span>
//               <span className="screen__background__shape screen__background__shape2"></span>
//               <span className="screen__background__shape screen__background__shape1"></span>
//             </div>
//           </div>
//         </div>
//       </div>
//    </> ):
//       (<>
//       <div className="error_token">
//         <img src={logoHS} alt="" />

//         <div>Vous êtes sur cette page car vous souhaitez modifier votre mot de passe Hearing Space cépendant une erreur est survenu. On vous invite à refaire la démarche de réinitialisation afin de recevoir un nouveau lien.</div>
//          <a href={process.env.REACT_APP_HOME}>Accueil DashBoard Hearing Space</a>
//          </div>
//       </>
//       )}
//     </>
//   );
// };

// export default ResetPassword;
