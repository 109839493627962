import React from "react";
import styles from './ContactSupportOnStartHs.module.scss'
import reset from "../../../../pics/resetsoundcard.webp";



const ContactSupportOnStartHS = () => {
  return (
    <div className={styles.container}>
    <p>Aller dans parametre audio puis ouvrir le melangeur de volume puis réinitialiser les périphériques audio...</p> 
      <img src={reset} alt=""  style={{}}/>

      <a href="https://www.youtube.com/watch?v=i5LbYVpX7Hk">
Voir la vidéo d'explication
      </a>
    </div>
  );
};

export default ContactSupportOnStartHS;
