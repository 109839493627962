import moment from "moment";

export const transformDataMonthlyOrYearly = (
  data,
  interval,
  includeSaturday = false
) => {
  const groupedData = {};

  data.forEach((item) => {
    // Construire la date à partir de l'année, du mois et du jour
    const date = moment({
      year: item.dateyear,
      month: item.datemonth - 1,
      day: item.dateday,
    });
    // Vérifier si la date est un samedi (6) ou un dimanche (0), et si on doit l'exclure
    if (date.day() !== 0 && (includeSaturday || date.day() !== 6)) {
      let key;
      switch (interval) {
        case "monthly":
          key = `${item.dateyear}-${String(item.datemonth).padStart(2, "0")}`;
          break;
        case "yearly":
          key = `${item.dateyear}`;
          break;
        case "weekly":
          // Utiliser week() pour obtenir la semaine de l'année, et year() pour s'assurer que l'année est correcte
          key = `Semaine ${date.week()} de ${date.year()}`;
          break;
        default:
          key = date.format("DD-MM-YYYY"); // Utiliser format de moment.js pour garder le format cohérent
          break;
      }

      if (!groupedData[key]) {
        groupedData[key] = {};
      }

      if (!groupedData[key][item.mname]) {
        groupedData[key][item.mname] = 0;
      }

      groupedData[key][item.mname] += item.totalValue;
    }
  });

  return Object.entries(groupedData).map(([date, metrics]) => ({
    date,
    ...metrics,
  }));
};

export const transformComparisonData = (
  data,
  usersMap,
  cabinesMap,
  selectedMetrics,
  timeInterval
) => {
  // Regroupe les données par intervalle de temps spécifié et par uid ou cabid
  
  const groupedData = data.reduce(
    (acc, { dateyear, datemonth, dateday, uid, mname, totalValue, cabid }) => {
      if (!selectedMetrics.includes(mname)) return acc; // Filtre par métriques sélectionnées

      let dateKey;
      switch (timeInterval) {
        case "daily":
          dateKey = `${dateyear}-${datemonth
            .toString()
            .padStart(2, "0")}-${dateday.toString().padStart(2, "0")}`;
          break;
        case "weekly":
          const weekNumber = moment(
            `${dateyear}-${datemonth}-${dateday}`,
            "YYYY-MM-DD"
          ).isoWeek();
          dateKey = `Semaine ${weekNumber}, ${dateyear}`;
          break;
        case "monthly":
          dateKey = `${dateyear}-${datemonth.toString().padStart(2, "0")}`;
          break;
        case "yearly":
          dateKey = `${dateyear}`;
          break;
        default:
          dateKey = `${dateyear}-${datemonth
            .toString()
            .padStart(2, "0")}-${dateday.toString().padStart(2, "0")}`;
      }

      const userKey = uid
        ? `User ${usersMap[uid].displayName} (${usersMap[uid].userLogin})`
        : `Cabine ${cabinesMap[cabid] || cabid}`;


      if (!acc[dateKey]) acc[dateKey] = {};
      if (!acc[dateKey][userKey]) acc[dateKey][userKey] = {};

      acc[dateKey][userKey][mname] =
        (acc[dateKey][userKey][mname] || 0) + totalValue;

      return acc;
    },
    {}
  );

  // Convertit les données regroupées en format adapté pour le graphique
  return Object.entries(groupedData).map(([date, usersData]) => {
    const entry = { date };
    for (const [userKey, metrics] of Object.entries(usersData)) {
      for (const [metricName, value] of Object.entries(metrics)) {
        entry[`${userKey} - ${metricName}`] = value; // Clé unique par utilisateur et métrique
      }
    }
    return entry;
  });
};

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const calculateAverages = (data, metrics) => {
  return metrics.reduce((acc, metric) => {
    const sum = data.reduce(
      (sum, point) => sum + (point[metric.value] || 0),
      0
    );
    const avg = data.length ? sum / data.length : 0;
    acc[metric.value] = avg;
    return acc;
  }, {});
};

export const calculateAveragesForComparisonUserOrCabine = (transformedData) => {
  const totals = {};
  const counts = {};

  transformedData.forEach((entry) => {
    Object.entries(entry).forEach(([key, value]) => {
      if (key !== "date") {
        // Sépare l'identifiant utilisateur/cabine et la métrique
        const [userOrCabineId, metric] = key.split(" - ");

        // Initialise les objets si nécessaire
        totals[userOrCabineId] = totals[userOrCabineId] || {};
        counts[userOrCabineId] = counts[userOrCabineId] || {};

        // Accumule les totaux et les nombres d'occurrences
        totals[userOrCabineId][metric] =
          (totals[userOrCabineId][metric] || 0) + value;
        counts[userOrCabineId][metric] =
          (counts[userOrCabineId][metric] || 0) + 1;
      }
    });
  });

  // Calcul des moyennes
  const averages = Object.keys(totals).reduce((acc, userOrCabineId) => {
    acc[userOrCabineId] = acc[userOrCabineId] || {};
    Object.entries(totals[userOrCabineId]).forEach(([metric, total]) => {
      acc[userOrCabineId][metric] = total / counts[userOrCabineId][metric];
    });
    return acc;
  }, {});

  return averages;
};
