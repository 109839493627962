import React from "react";
import styles from "./SupportCard.module.scss";
import { MdOutlineWavingHand } from "react-icons/md";

import { Link } from "react-router-dom";

const SupportCard = ({ backGround, title, link, img, text, svg, onClick }) => {
  return (
    <>
      <Link to={`${link}`}>
        <div className={styles.card} onClick={onClick}>
          <div className={styles.box} style={{ background: `${backGround}` }}>
            <div className={styles.content}>
              <h3>{title}</h3>
              {svg}

              <h4>{text}</h4>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default SupportCard;
