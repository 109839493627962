import React from "react";

const ProgressBarDownload = ({ progress }) => {
  return (
    <div>
      {progress > 0 && (
        <div
          style={{
            width: "200px",
            height: "20px",
            backgroundColor: "lightgray",
          }}
        >
          <div
            style={{
              width: `${progress}%`,
              height: "100%",
              backgroundColor: "#6d5dfc",
            }}
          ></div>
          {progress === 100 ? (
            <span
              style={{
                fontWeight: 500,
              }}
            >
              Téléchargement terminée
            </span>
          ) : (
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {progress} % Téléchargement en cours...
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default ProgressBarDownload;
