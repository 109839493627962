import React from "react";

import styles from "./SuccessLinked.module.scss";
import Button from "../../UiComponents/Button";

const SuccessLinked = ({ userName, senderOwner }) => {
  return (
    <div className={styles.container}>
      <p>
        Votre compte est bien lié à <span>{senderOwner}.</span>{" "}
      </p>
      <p>
       Nous activerons votre compte dès que possible. Vous recevrez un email de confirmation.
      </p>

      
    </div>
  );
};

export default SuccessLinked;
