import axios from "axios";
import { useContext, useEffect } from "react";
import NavContext from "../context/NavContext";

//get token in local storage
const jwt = localStorage.getItem("jwt");

//AXIOS CONFIG

const Axios = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api`,
  withCredentials: true
});

/**
 * Interceptor token
 */
Axios.interceptors.request.use((request) => {
  request.headers.authorization = "Bearer " + jwt;

  return request;
});

export default Axios;


