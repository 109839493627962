import React, { useState } from "react";
import CardHeaderPage from "../../UiComponents/card/CardHeaderPage";
import HeaderNavSupportHS from "../headerNav/HeaderNavSupportHS";
import ErrorMessageHS from "../_components/ErrorMessageHS";
import styles from "./MessageError.module.scss";
import { dataMessageError } from "./DataMessageError";
import SearchError from "./SearchError";

const MessageError = () => {
  const [search, setSearch] = useState("");

  return (
    <div>
      <HeaderNavSupportHS />
      <CardHeaderPage
        text={
          "Entrez le message d'erreur afficher dans Hearing space puis suivez les instructions"
        }
        textAlign={"center"}
      />

      <SearchError setSearch={setSearch} search={search} />
      <div className={styles.containerAllMessageError}>
        {dataMessageError
          .filter((message,index) => message.title.toLowerCase().includes(search))
          .map((message,index) => {
            return (
              <ErrorMessageHS
                key={index}
                title={message.title}
                children={message.children}
              />
            );
          })}
      </div>
    </div>
  );
};

export default MessageError;
