import React, { useRef, useContext, useState, useEffect } from "react";
import { ticksToDate } from "../../calibration/functionDataSorted";
import NavContext from "../../../context/NavContext";
import Button from "../../UiComponents/Button";
import { BiMailSend } from "react-icons/bi";
import styles from "./InviteUser.module.scss";
import Success from "../../UiComponents/sucess/Success";
import Loader from "../../UiComponents/loader/Loader";
import Error from "../../UiComponents/error/Error";
import InputForms from "../../UiComponents/forms/InputForms";
import ContainerForm from "../../UiComponents/forms/ContainerForm";
import Axios from "../../../_services/caller.service";
import { BsToggle2Off } from "react-icons/bs";

const InviteForms = ({
  setReloadWaitingInvite,
  reloadWaitingInvite,
  toggle,
}) => {
  //CONTEXT
  const { ownerId, ownerProfile, realOwnerId, realOwnerProfile, superAdmin } =
    useContext(NavContext);
  //FORMS
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  //DISPLAY
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const form = useRef();

  //PROFILE OWNER INFOS
  const senderOwner = ownerProfile.display_name;
  const emailOwner = isChecked
    ? realOwnerProfile.user_email
    : ownerProfile.user_email;

  const sendEmail = (e) => {
    e.preventDefault();

    if (lastName === "" || name === "" || email === "") {
      setError(true);
      setErrorMessage("Merci de compléter tous les champs");
    } else {
      form.current.reset();
      setError(false);
      setLoader(true);
      Axios.post(`/inviteUser/${ownerId}`, {
        data: {
          name,
          lastName,
          email,
          ownerId,
          senderOwner,
          emailOwner,
          firstSend: true,
        },
      })

        .then(function (response) {
          if (response.data.message === "invite already send") {
            setLoader(false);
            setError(true);
            setErrorMessage("Invitation déja envoyée");

            return;
          }
          if (response.data.message === "L'email saisie est déjà lié a votre compte") {
            setLoader(false);
            setError(true);
            setErrorMessage("L'email saisie est déjà lié a votre compte");

            return;
          }
          if (response.data.message === "email blacklist") {
            setLoader(false);
            setError(true);
            setErrorMessage("L'utilisateur est dans une blacklist");

            return;
          }
          if (
            response.data.message ===
            "une erreur s'est produite pendant l'envoie de l'email"
          ) {
            setLoader(false);
            setError(true);
            setErrorMessage(
              "une erreur s'est produite pendant l'envoie de l'email"
            );

            return;
          }

          const hash = response.data.data.hash;
          const linkSend = response.data.data.link;

          setLoader(false);
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            toggle();
          }, 2500);

          // Create rows in table invite list
          Axios.post(`/inviteList/${ownerId}`, {
            data: { hash, ownerId, email, lastName, name, linkSend },
          })

            .then(function (response) {
              setReloadWaitingInvite(reloadWaitingInvite + 1);
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  return (
    <form ref={form} className={styles.formInvite} onSubmit={sendEmail}>
      <InputForms
        type="text"
        placeholder={"Prénom"}
        onChange={(e) => setName(e.target.value)}
        required={true}
      />
      <InputForms
        type="text"
        placeholder={"Nom"}
        onChange={(e) => setLastName(e.target.value)}
        required={true}
      />
      <InputForms
        type="email"
        placeholder={"Email"}
        onChange={(e) => setEmail(e.target.value)}
        required={true}
      />
      {superAdmin && (
        <div className={styles.containerCheckOwnerMail}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label>
            Réponse une fois invitation acceptée à <span>{realOwnerProfile.user_email}</span>
          </label>
        </div>
      )}

      <Button
        type={"submit"}
        styles={"btnPrimary"}
        svg={<BiMailSend />}
        text={"Inviter un utilisateur"}
      />
      {error && <Error error={errorMessage} />}
      {loader && <Loader />}
      {success && <Success success={"Invitation envoyée"}></Success>}
    </form>
  );
};

export default InviteForms;
