import React from 'react';
import styles from './InitialisationErrorSoundCard.module.scss'
const InitialisationErrorSoundCard = () => {



    return (
        <div className={styles.container}>
            Dans ce cas, il faut essayer de brancher les sorties d'une autre manière ( sur d'autres port usb), jusqu'à obtenir qqch de stable. 
           <br/>
            On peut aussi changer la sortie par défault.
        </div>
    );
};

export default InitialisationErrorSoundCard;