import React, { useEffect, useState, useRef } from "react";
import Button from "../../UiComponents/Button";
import styles from "./SoundCard.module.scss";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";
import Axios from "../../../_services/caller.service";
import { ticksToDate } from "../../calibration/functionDataSorted";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { decodedB64, encodedB64 } from "../../../CustomHooks/DecodedBase64";
import ModalSoundCard from "./ModalSoundCard";

const OneSoundCard = ({
  soundCard,

  statusGen,
  statusTonal,
  statusVocal,
  dateGen,
  dateTonal,
  dateVocal,
  transductor,
  image,
  updateSoundCard,
  setUpdateSoundCard,
  soundCardCalibName,
  soundCardCalibGuid,
  cabId,
  maj,
  setMaj,
  guidAllCard,
  setDisplayModalConfirm,
  setTransductorNameForUpdate,
}) => {
  //CHILDREN COMPONENT TO SOUNDCARD

  const [loading, setLoading] = useState(false);
  const [sucess, setSuccess] = useState(false);

  //REF
  const selectRef = useRef(null);
  const [hasInteracted, setHasInteracted] = useState(false);

  const updateSoundCardWithSelect = (e, transductor) => {
    setHasInteracted(true); // permet de dire qu'un onChange a eu lieu
    const soundCardName = e.target.options[e.target.selectedIndex].text;

    console.log("OnChange " + soundCardName);
    console.log("tranducteur onChange " + transductor);
    if (transductor === "CO") {
      setUpdateSoundCard((prevState) => ({ ...prevState, CO: soundCardName }));
    }
    if (transductor === "Casque") {
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        Casque: soundCardName,
      }));
    }
    if (transductor === "Inserts") {
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        Inserts: soundCardName,
      }));
    }
    if (transductor === "HSSoundcardUse") {
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        HSSoundcardUse: soundCardName,
      }));
    }
    if (transductor === "DiracSoundcardUse") {
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        DiracSoundcardUse: soundCardName,
      }));
    }
    if (transductor === "Operator") {
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        Operator: soundCardName,
      }));
    }
  };

  //SI PAS DE ONCHANGE DANS LE SELECT ALORS ON PREND LE USEREF POUR METTRE A JOUR L'état Au clic MAJ
  const updateStateWithCurrentSelectValue = (transductor) => {
    if (!hasInteracted) {
      console.log("je suis dans le UseRef");
      const soundCardName =
        selectRef.current[selectRef.current.selectedIndex].text;

      console.log("reference du select " + soundCardName);
      console.log("ref transduteur " + transductor);

      if (transductor === "CO") {
        setUpdateSoundCard((prevState) => ({
          ...prevState,
          CO: soundCardName,
        }));
      }
      if (transductor === "Casque") {
        setUpdateSoundCard((prevState) => ({
          ...prevState,
          Casque: soundCardName,
        }));
      }
      if (transductor === "Inserts") {
        setUpdateSoundCard((prevState) => ({
          ...prevState,
          Inserts: soundCardName,
        }));
      }
      if (transductor === "HSSoundcardUse") {
        setUpdateSoundCard((prevState) => ({
          ...prevState,
          HSSoundcardUse: soundCardName,
        }));
      }
      if (transductor === "DiracSoundcardUse") {
        setUpdateSoundCard((prevState) => ({
          ...prevState,
          DiracSoundcardUse: soundCardName,
        }));
      }
      if (transductor === "Operator") {
        setUpdateSoundCard((prevState) => ({
          ...prevState,
          Operator: soundCardName,
        }));
      }

      // ... autres conditions pour Casque, Inserts, etc.
    }
  };

  // // Pour exclure dirac dans la liste dirac
  const filteredSoundCard = soundCard.filter((card) => {
    const cards = card.speaker;
    const decoded = decodedB64(cards).toLocaleLowerCase();

    if (transductor !== "HSSoundcardUse") {
      return !decoded.includes("dirac");
    }
    return true;
  });

  return (
    <div className={styles.containerSoundCard}>
      <img className={styles.soundImage} src={image} alt="" />

      {statusGen.class === "passed" &&
      statusGen.class !== "Dirac" &&
      soundCardCalibName !== undefined ? (
        <>
          <div className={styles.soundCardName}>
            {decodedB64(soundCardCalibName)}
          </div>{" "}
        </>
      ) : statusGen.class === "Dirac" ? (
        ""
      ) : (
        <div className={styles.noCalib}>Aucune calibration </div>
      )}
      {statusGen.class === "Dirac" && soundCardCalibName !== undefined && (
        <>
          <div className={styles.soundCardName}>
            {decodedB64(soundCardCalibName)}
          </div>{" "}
        </>
      )}

      <select
        onChange={(e) => updateSoundCardWithSelect(e, transductor)}
        ref={selectRef}
      >
        {soundCard.length >= 1 &&
          soundCard !== undefined &&
          filteredSoundCard.map((card, index) => {
            return (
              <option
                key={index}
                value={card.guid}
                selected={
                  card.speaker === soundCardCalibName ||
                  card.guid === soundCardCalibGuid
                }
              >
                {decodedB64(card.speaker)}
              </option>
            );
          })}
      </select>
      <AiOutlineCheckCircle
        className={styles.btnUpdateSoundCard}
        onClick={() => {
          setTransductorNameForUpdate(transductor);
          updateStateWithCurrentSelectValue(transductor);
          setDisplayModalConfirm(true);
        }}
      />

      {loading && <Loader />}
      {sucess && <Success success={"Carte son MAJ"} />}
    </div>
  );
};

export default OneSoundCard;
