import React, { useContext, useEffect, useState } from "react";
import Axios from "../../_services/caller.service";
import axios from "axios";
//STYLES
import stylesCalib from "./Calibration.module.scss";
//CONTEXT
import { useCabineContext } from "../../context/CabineContext";
import NavContext from "../../context/NavContext";
import { useUserInviteContext } from "../../context/UserInviteContext";
//IMG
import microears from "../../pics/ears.png";
import umik1 from "../../pics/umik1.png";
//ANIMATION
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
//COMPONENT
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";
import CalibrationArray from "./certificatDownload/CalibrationArray";
import HeaderArrayCertificat from "./certificatDownload/HeaderArrayCertifcat";
import ManageLateCalibration from "./manageLateCalibration/ManageLateCalibration";
//HOOK
import useModal from "../../hooks/useModal";

const Calibration = () => {
  const token = localStorage.getItem("jwt");
  // Context
  const {
    ownerId,
    realOwnerId,
    userAuth,
    superAdmin,
    ownerProfile,
    adminProfile,
  } = useContext(NavContext);
  const { inviteId, userInvite } = useUserInviteContext();
  const { cabine } = useCabineContext();
  //display bouton certif or not
  const [cabineWithCalib, setCabineWithCalib] = useState([]);
  const [cabineAuthToGetCerticat, setCabineAuthToGetCerticat] = useState([]);
  const [ownerRole, setOwnerRole] = useState(false);

  //SEND PDF BY EMAIL

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { isShowing, toggle } = useModal();

  //SEARCH CABINE STATE
  const [searchCabCertif, setSearchCabCertif] = useState("");

  // SLIDER
  const [sliderCertif, setSliderCertif] = useState(0);
  const [sliderAdvice, setSliderAdvice] = useState(0);
  const [radioNameCertif, setRadioNameCertif] = useState(false);

  //VERFY IF CABINE HAVE CALIBRATION DATA
  const verifyIfDataCalibIsInCabine = async (cabine) => {
    const cabineWithCalibrationData = [];

    const responses = await Promise.all(
      cabine.map(async (cab) => {
        const res = await axios
          .get(
            `${process.env.REACT_APP_API_URL}api/calibrationGeneral/${cab.id}`,
            {
              headers: { authorization: "Bearer " + token },
            }
          )

          .then(async (response) => {
            if (response.data.message === "aucune donnée") {
            } else {
              cabineWithCalibrationData.push(cab.id);
              //setCalibGeneral(response.data.data);
            }
          })

          .catch(function (error) {
            console.log(error);
          });
      })
    );

    setCabineWithCalib(cabineWithCalibrationData);
    setSliderAdvice(cabineWithCalibrationData.length);
    setSliderCertif(cabineWithCalibrationData.length);
    return cabineWithCalibrationData;
  };

  useEffect(() => {
    console.log(realOwnerId);
  }, []);

  //VERIFY IF CABINE AUTH FOR DISPLAY CALIBRATION CERTIFICAT
  useEffect(() => {
    const userCabine = ownerId != null ? ownerId : realOwnerId;

    Axios.get(`/cabinesCenter/${userCabine}`)

      .then((response) => {
        const cabIdAuthForDisplayCertif = response.data.data.map(
          (cab) => cab.cabid
        );

        const owner = response.data.data.map((cab) => cab.role);

        setCabineAuthToGetCerticat(cabIdAuthForDisplayCertif);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    verifyIfDataCalibIsInCabine(cabine);
  }, []);

  ///////********************BAKC END ********************************** */

  const generatePdfBack = (cabId) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}api/generatePdf/${cabId}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
      },
      responseType: "blob",
      data: {},
      // sendByMail:false,
      //   apiKey:'qGhDkgLCakctRjegPQdcjdXDTbxoCHRYR3z47@iP' ,
      //   userId:13
      // },
    })
      .then((response) => {
        if (response.data.message === "email bien envoyé") {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
            toggle();
          }, 1500);
        } else if (response.data.message === "une erreur s'est produite") {
          setError(true);
          setTimeout(() => {
            setError(false);
            toggle();
          }, 1500);
        } else if (response.data instanceof Blob) {
          // si pas email alors c'est qu'on souhaite genérer sur le navigateur
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "certificat_calibration.pdf");
          document.body.appendChild(link);
          link.click();
        }
      })

      .catch((error) => {
        console.log(error);
        console.error("Erreur lors du téléchargement du fichier PDF : ", error);
        if (error.data.message === "une erreur s'est produite") {
          setError(true);
        }
      });
  };
  /**************************************************************************************************** */

  // filtrer certificat
  const filteredCertificat = cabine
    .filter((cab) => {
      if (ownerRole) {
        if (cabineWithCalib.includes(cab.id)) {
          return cab;
        }
      } else if (!ownerRole) {
        if (
          cabineWithCalib.includes(cab.id) &&
          cabineAuthToGetCerticat.includes(cab.id)
        ) {
          return cab;
        }
      }
    })
    .filter((cab) => cab.cabname.toLowerCase().includes(searchCabCertif))
    .sort((a, b) => {
      if (radioNameCertif) {
        return b.cabname.localeCompare(a.cabname);
      } else {
        return a.cabname.localeCompare(b.cabname);
      }
    })

    .slice(0, sliderCertif);

  return (
    <>
      <CardHeaderPage
        text={"Télécharger vos certificats de calibration en toute simplicité !"}
      />
      <motion.div
        className={stylesCalib.containerPageCalibration}
        variants={animatePage}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <img className={stylesCalib.umik} src={umik1} alt="" />
        <img src={microears} alt="" className={stylesCalib.ears} />
        <div className={stylesCalib.containerCalibration}>
          <HeaderArrayCertificat
            setSearchCabCertif={setSearchCabCertif}
            searchCabCertif={searchCabCertif}
          />

          {cabine &&
            cabineWithCalib.length >= 1 &&
            filteredCertificat.map((cab) => {
              return (
                <CalibrationArray
                  key={cab.id}
                  cab={cab}
                  cabine={cabine}
                  cabname={cab.cabname}
                  onPressBack={() => generatePdfBack(cab.id)} // certif back with sendMail false
                  toggle={toggle}
                  isShowing={isShowing}
                  error={error}
                  success={success}
                />
              );
            })}

          {cabineWithCalib.length === 0 && (
            <h1 style={{ textAlign: "center", marginTop: "15px" }}>
              Vous n'avez pas de certicat de calibration pour le moment
            </h1>
          )}
        </div>

      </motion.div>
    </>
  );
};

export default Calibration;
