import React from 'react';
import CardHeaderPage from '../../UiComponents/card/CardHeaderPage';
import HeaderNavSupportHS from '../headerNav/HeaderNavSupportHS';

const ContactHelp = () => {
    return (
        <div>
   <HeaderNavSupportHS/>
            <CardHeaderPage text={'Retrouvez ici tous les contacts utiles'} textAlign={'center'}/>
        </div>
    );
};

export default ContactHelp;