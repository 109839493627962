import React from "react";
import styles from "./SummaryOfFilters.module.scss";
import statsFilter from "../../../pics/statsFilter.webp";

export function SummaryOfFilters({
  startDate,
  endDate,
  selectedUsers,
  linkedUsers,
  selectedCabines,
  allUsers,
  allCabines,
  comparisonMode,
}) {
  const formatDate = (date) => {
    return date ? date.toLocaleDateString() : "";
  };

  const selectedUsersLabels = allUsers
    .filter((user) => selectedUsers.includes(user.value))
    .map((user) => user.label);
  const selectedCabinesLabels = allCabines
    .filter((cab) => selectedCabines.includes(cab.value))
    .map((cab) => cab.label);
  const linkedUsersLabels = linkedUsers
    .filter((user) => selectedUsers.includes(user.value))
    .map((user) => user.label);

  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img src={statsFilter} alt="Filters Visualization" />
        <span className={styles.card_price}>
          <span>Filtres </span>Appliqués
        </span>
      </div>
      <div className={styles.card_content}>
        <div className={styles.card_text}>
          <ul className={styles.summaryList}>
            {comparisonMode !== "none" && (
              <>
                <li>
                  <strong>Mode de comparaison : </strong>
                  {comparisonMode === "users" ? "Utilisateurs" : "Cabines"}
                </li>
                <hr />
              </>
            )}

            {startDate && endDate && (
              <>
                <li>
                  <strong>Date: De</strong> {formatDate(startDate)} à{" "}
                  {formatDate(endDate)}
                </li>
                <hr />
              </>
            )}
            {selectedUsers.length > 0 && (
              <>
                <li>
                  <strong>Utilisateurs: </strong>
                  {selectedUsersLabels.join(", ")}
                </li>
                <hr />
              </>
            )}
            {linkedUsers.length > 0 && (
              <>
                <li>
                  <strong>Utilisateurs liés : </strong>{" "}
                  {linkedUsersLabels.join(", ")}
                </li>
                <hr />
              </>
            )}
            {selectedCabines.length > 0 && (
              <li>
                <strong>Cabines : </strong> {selectedCabinesLabels.join(", ")}
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
