import React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import styles from "./Error.module.scss";

const Error = ({error}) => {
    return (
        <div className={styles.containerError}>
            <MdErrorOutline style={{color:'#ba3939', padding:'5px'}}/>
            <span>{error}</span>
        </div>
    );
};

export default Error;