// metricsConfig.js

const metricsConfig = {
    HSStarted: { title: "HS Started", color: "#8884d8" },
    StartedDoubleScreen: { title: "Double Screen Started", color: "#82ca9d" },
    SavedAudiogram: { title: "Audiograms Saved", color: "#ffc658" },
    SavedJournal: { title: "Journals Saved", color: "#a4de6c" },
    OpenedMenuSpace: { title: "Menu Space Opened", color: "#d0ed57" },
    OpenedTonalSpace: { title: "Tonal Space Opened", color: "#ff9e54" },
    OpenedVocalSpace: { title: "Vocal Space Opened", color: "#8dd1e1" },
    OpenedVNTSpace: { title: "VNT Space Opened", color: "#dc75c5" },
    OpenedLocalSpace: { title: "Local Space Opened", color: "#fa8072" },
    OpenedMediaSpace: { title: "Media Space Opened", color: "#6a5acd" },
    SelectedVocalNoise: { title: "Vocal Noise Selected", color: "#20b2aa" },
    SelectedVocalSilence: { title: "Vocal Silence Selected", color: "#778899" },
    SelectedVNTNoise: { title: "VNT Noise Selected", color: "#b0c4de" },
    SelectedVNTSilence: { title: "VNT Silence Selected", color: "#4682b4" },
    SpeakersCalibrationCompleted: { title: "Speakers Calibration", color: "#9acd32" },
    HeadphonesCalibrationCompleted: { title: "Headphones Calibration", color: "#40e0d0" },
    InsertsCalibrationCompleted: { title: "Inserts Calibration", color: "#ff6347" },
    OperatorCalibrationCompleted: { title: "Operator Calibration", color: "#da70d6" },
    TonalAudiogramSaved: { title: "Tonal Audiogram Saved", color: "#eee8aa" },
    VocalAudiogramSaved: { title: "Vocal Audiogram Saved", color: "#98fb98" },
    VocalNoiseAudiogramSaved: { title: "Vocal Noise Audiogram", color: "#afeeee" },
    TSTAudiogramSaved: { title: "TST Audiogram Saved", color: "#db7093" },
    TSTNoiseAudiogramSaved: { title: "TST Noise Audiogram", color: "#ffa07a" },
    LocalisationTestSaved: { title: "Localisation Test Saved", color: "#dda0dd" },
    LocalisationTrainingSaved: { title: "Localisation Training", color: "#b0e0e6" },
    LipSyncVocalSaved: { title: "Lip Sync Vocal Saved", color: "#cd853f" },
    LipSyncVocalNoiseSaved: { title: "Lip Sync Vocal Noise", color: "#ffc0cb" },
    LipSyncTSTSaved: { title: "Lip Sync TST Saved", color: "#dda0dd" },
    LipSyncTSTNoiseSaved: { title: "Lip Sync TST Noise", color: "#b0c4de" },
    ReportsGenerated: { title: "Reports Generated", color: "#87cefa" },
    Count: { title: "Count", color: "#ff4500" },
  };
  
  export default metricsConfig;
  