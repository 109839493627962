import React, { useContext } from "react";

import { Outlet, Navigate } from "react-router-dom";
import NavContext from "../../context/NavContext";

export default function Private() {
  const { userAuth, loading } = useContext(NavContext);
  // console.log("PRIVATE", userAuth);
  // console.log(userAuth);
  // console.log(loading);

  if (!userAuth && !loading) {
    return <Navigate to="/" />;
  }

  return <div className="container">{!loading && <Outlet />}</div>;
}
