import React, { useEffect, useState } from "react";
import Axios from "../../../_services/caller.service";
import { useCabineContext } from "../../../context/CabineContext";
import Button from "../../UiComponents/Button";
import styles from "./ChangeAdressCab.module.scss";
import { encodedB64 } from "../../../CustomHooks/DecodedBase64";
import Success from "../../UiComponents/sucess/Success";
import Error from "../../UiComponents/error/Error";
import { FaRegAddressCard } from "react-icons/fa";
import Modal from "../../UiComponents/modals/Modal";
import useModal from "../../../hooks/useModal";
import { toast } from "react-toastify";

const ChangeAdressCab = ({
  cabId,
  majCabine,
  setMajCabine,
  setDisplaySettingCab,
  displaySettingCab,
}) => {
  const { cabine } = useCabineContext(); //all cabine owner
  //DATA CABINE ADRESS
  const [dataAdress, setDataAdress] = useState(null);
  const [updatedDataAdress, setUpdatedDataAdress] = useState({});
  const [displayInfosAdress, setDisplayInfosAdress] = useState(false);

  const [count, setCount] = useState(0);
  const [noChangement, setNoChangement] = useState(false);

  //MODAL
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    const getCabineAdress = () => {
      if (cabine !== "") {
        Axios.get(`/cabineAdress/${cabId}`)

          .then(function async(response) {
            // Si des données sont reçues, mettez à jour l'état dataAdress avec ces données
            if (response.data.data) {
              const newDataAdress = {
                Responsable: response.data.data.Responsable || "",
                Tel: response.data.data.Tel || "",
                Adress: response.data.data.Adress || "",
                Adress2: response.data.data.Adress2 || "",
                CodePostal: response.data.data.CodePostal || "",
                Ville: response.data.data.Ville || "",
                Pays: response.data.data.Pays || "",
              };
              setDataAdress(newDataAdress);
            } else {
              // Sinon, réinitialisez l'état dataAdress avec les valeurs par défaut
              setDataAdress({
                Responsable: "",
                Tel: "",
                Adress: "",
                Adress2: "",
                CodePostal: "",
                Ville: "",
                Pays: "",
              });
            }
          })
          .catch(function (error) {
            // console.log(error);
          });
      }
    };
    getCabineAdress();
  }, [cabId, cabine, majCabine, count]);

  const handleChangeAdress = (e) => {
    setUpdatedDataAdress({
      ...updatedDataAdress,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async () => {
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        const base64EncodedData = Object.fromEntries(
          Object.entries(updatedDataAdress).map(([key, value]) => [
            key,
            encodedB64(value), // Encode value in base64
          ])
        );

        Axios.put(`/updateCabineAdress/${cabId}`, {
          data: base64EncodedData,
        })

          .then((response) => {
            if (response.data.message === "aucun changement effectués") {
              console.log("aucun changement");
              toast.error("Aucun changement dans les données saisies");
            } else {
              toggle();
              toast.success("Nouvelles données enregistrer");
              setMajCabine(!majCabine);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {});
  };

  const closeModalChangeAdress = () => {
    setDisplaySettingCab(!displaySettingCab);
    toggle();
  };

  return (
    <div className={styles.infosAdress}>
      {displayInfosAdress && (
        <div className={styles.containerInfos}>
          {dataAdress &&
            Object.entries(dataAdress).map(([key, value]) => (
              <div className={styles.adress} key={key}>
                <div className={styles.containerLabel}>
                  <label>{key}:</label>
                </div>
                <div className={styles.containerInput}>
                  <span>{value}</span>
                </div>
              </div>
            ))}
        </div>
      )}

      <div className={styles.containerModal}>
      
        {dataAdress &&
          Object.entries(dataAdress).map(([key, value]) => (
            <div className={styles.adress} key={key}>
              <div className={styles.containerLabel}>
                <label>{key}:</label>
              </div>
              <div className={styles.containerInput}>
                <input
                  name={key}
                  defaultValue={value}
                  onChange={handleChangeAdress}
                />
              </div>
            </div>
          ))}

        <div className={styles.btnUpdate}>
<button className={styles.btnUpdate} onClick={handleUpdate}>
                Mettre à jour
              </button>
   
        </div>
      </div>
    </div>
  );
};

export default ChangeAdressCab;
