import React, { useState } from "react";
import Axios from "../../_services/caller.service";
import { toast } from "react-toastify";
import styles from "./UploadDataMics.module.scss";
function UploadDataMics({maj,setMaj}) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert("Veuillez sélectionner un fichier avant de soumettre.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    console.log(file);

    Axios.post("/uploadDataMics", formData, {
      headers: {
        // Ajoutez cet en-tête pour indiquer que le corps de la requête contient des données FormData
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log(response);
        toast.success(response.data.message, { autoClose: false });
        setMaj(!maj) //maj de la liste des micros
      })
      .catch((error) => {
        if (error.response) {
          // Construire un message incluant les expectedKeys si elles sont disponibles
          let errorMessage = error.response.data.message;
          if (error.response.data.expectedKeys) {
            errorMessage += ` Clés attendues: ${error.response.data.expectedKeys.join(
              ", "
            )}`;
          }

          toast.error(errorMessage, { autoClose: false }); // Ce toast nécessite un clic pour disparaître
          console.error(errorMessage);
        } else {
          console.error("Erreur de requête : ", error.request);
          toast.error("Aucune réponse du serveur.", { autoClose: false });
        }
      });
  };

  return (
    <div className={styles.formContainer}>
      <h2 className={styles.formTitle}>Importer des données JSON</h2>
      <form onSubmit={handleSubmit} className={styles.uploadForm}>
        <input
          type="file"
          onChange={handleFileChange}
          accept=".json"
          className={styles.fileInput}
        />
        <button type="submit" className={styles.submitButton}>
          Envoyer
        </button>
      </form>
    </div>
  );
}

export default UploadDataMics;
