export const dateJavascriptToTicks = (date) => {
  const epochOffset = 621355968000000000;
  const ticksPerMillisecond = 10000;

  const ticks = date.getTime() * ticksPerMillisecond + epochOffset;

  return ticks;
};


export const convertDateToTicks = () => {
  var currentTime = new Date().getTime();

  // 10,000 ticks in 1 millisecond
  // jsTicks is number of ticks from midnight Jan 1, 1970
  var jsTicks = currentTime * 10000;

  // add 621355968000000000 to jsTicks
  // netTicks is number of ticks from midnight Jan 1, 01 CE
  var netTicks =
    jsTicks + 621355968000000000 - new Date().getTimezoneOffset() * 600000000;

  return netTicks;
};




// CALCUL FOR GET DAY BEFORE NEXT CALIB FOR EACH OUTPOUT
export const dayEmailAlreadySend = (date) => {
  const dateNowInTicks = dateJavascriptToTicks(new Date()); //date now in ticks

  const ticksPerDay = 864000000000;

  const NumberTicksBeetweenNowAndEmailSend = dateNowInTicks - date;
  const numberDay = Math.round(
    NumberTicksBeetweenNowAndEmailSend / 864000000000
  );
console.log(numberDay);
  return numberDay;
};
