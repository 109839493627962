export const output = [
  {
    Speakers: "Speakers",
    Headphones: "Headphones",
    Bone: "Bone",
    Inserts: "Inserts",
    Operator: "Operator",
  },
];
export const categoriesName = [
  {
    Passed: "Passed",
    Tried: "Tried",
    Soundcard: "Soundcard",
    SoundcardName: "SoundcardName",
    Value: "Value",
  },
];
