import React, { useEffect, useState } from "react";
import styles from "./SendEmailConfirmationAccessHS.module.scss";
import Button from "../../UiComponents/Button";
import Modal from "../../UiComponents/modals/Modal";
import { toast } from "react-toastify";
import Axios from "../../../_services/caller.service";
import useModal from "../../../hooks/useModal";
import { MdOutlineMail } from "react-icons/md";

const SendEmailConfirmationAccessHS = ({ login, userMail }) => {
  const [userToSendEmail, setUserToSendEmail] = useState(null);
  const { isShowing, toggle } = useModal();

  useEffect(() => {
    console.log("Props reçues :", {login, userMail }); // Vérifiez les valeurs ici
    if (userMail && login) {
      setUserToSendEmail({ email: userMail, login:login });
    }
  }, [userMail,login]);
  

  // Open modal with validation
  const openModal = () => {
    if (!userMail || !login) {
      toast.error("Veuillez sélectionner un utilisateur complet");
      return;
    }
    setUserToSendEmail({ email: userMail, login:login });
    toggle();
  };

  // Function to send email confirmation
const sendConfirmationByEmail = async () => {
  if (!userToSendEmail) {
    toast.error("Aucun utilisateur sélectionné pour l'envoi");
    return;
  }

  try {
    // Appel API pour envoyer l'email de confirmation
    const response = await Axios.post(`/sendMailToUserToConfirmAccount`, {
      emailToSend: userToSendEmail.email,
      login: userToSendEmail.login
    });

    // Vérifie la réponse du serveur pour confirmer le succès
    if (response.status === 200) {
      toast.success("Email envoyé avec succès");
      toggle(); // Ferme le modal
    } else {
      // Si le statut n'est pas 200, une erreur s'est produite
      toast.error("Erreur lors de l'envoi de l'email. Veuillez réessayer.");
    }
  } catch (error) {
    // Gestion des erreurs
    console.error("Erreur lors de l'envoi de l'email :", error);
    toast.error("Une erreur est survenue lors de l'envoi de l'email");
  }
};


  return (
    <>
      <div className={styles.containerSelectUser}>
        <Button
          onPress={openModal}
          styles={"btnPrimary"}
          text={"Envoyer un email de confirmation de création de compte"}
          svg={<MdOutlineMail />}
        />
      </div>

      <Modal isShowing={isShowing} hide={toggle}>
        <div className={styles.containerModal}>
          <h2>Envoi d'un email de confirmation d'accès à Hearing Space</h2>
          <p>
            Nom/prénom: <span>{userToSendEmail?.login}</span>
          </p>
          <p>
            Email: <span>{userToSendEmail?.email}</span>
          </p>

          <Button
            onPress={sendConfirmationByEmail}
            styles={"btnValidation"}
            text={"Je confirme l'envoi de la validation d'inscription"}
            svg={<MdOutlineMail />}
          />
        </div>
      </Modal>
    </>
  );
};

export default SendEmailConfirmationAccessHS;
