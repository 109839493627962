import React, { useEffect, useState } from "react";
import styles from "./BoneCalib.module.scss";
import Axios from "../../../_services/caller.service";

//COMPONENTS
import Button from "../../UiComponents/Button";
import Loader from "../../UiComponents/loader/Loader";
import Success from "../../UiComponents/sucess/Success";
//IMG
import conduction from "../../../pics/ConductionOsseusePdf.png";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";

//UTILS
import {
  displayCalibValue,
  ticksToDate,
  ticksToDateYearMonthDay,
} from "../../calibration/functionDataSorted";

import { decodedB64, encodedB64 } from "../../../CustomHooks/DecodedBase64";

//IMPORT EXPORT CALIB IN TXT
import download from "downloadjs";
import DropZone from "../../UiComponents/dropZone/DropZone";
import { GreenLedSolo, RedLedSolo } from "../../Team/userCard/Led";
import {
  convertDateToTicks,
  dateJavascriptToTicks,
} from "../../../utils/handleDateTicks";
import ModalConfirmRegisterNewValue from "./Modal/ModalConfirmRegisterNewValue";
import ModalComfirmImport from "./Modal/ModalComfirmImport";
import ModalNotGoodOupout from "./Modal/ModalNotGoodOupout";
import { toast } from "react-toastify";
import LoaderLogin from "../../UiComponents/loader/LoaderLogin";

const BoneCalib = ({ cabId, setCabId, cabname }) => {
  const token = localStorage.getItem("jwt");
  // CALIBRATION
  const generalValue = 20;
  const [calibTonal, setCalibTonal] = useState([]);
  const [calibVocal, setCalibVocal] = useState([0]);
  const [dateCalibration, setDateCalibration] = useState("");

  // SERIAL NUMBER CO AND SOUND CARD SERIAL
  const [serialNumber, setSerialNumber] = useState("");
  const [serialSoundcardNumber, setSerialSoundcardNumber] = useState("");


  const [editSerialNumber, setEditSerialNumber] = useState("");
  const [maj, setMaj] = useState(false);

  //DISPLAY
  const [displayBone, setDisplayBone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);

  //LOADING

  const [loadingVoc, setloadingVoc] = useState(true);
  const [loadingTon, setloadingTon] = useState(true);
  const [loadingCalibrationTonalForDate, setLoadingCalibrationTonalForDate] =
    useState(true);
  const [loadingAllData, setLoadingAllData] = useState(true);
  //WARNING
  const [warningMessage, setWarningMessage] = useState("");

  //IMPORT EXPORT FILE TXT
  const [selectedFile, setSelectedFile] = useState();
  const [modalConfirmImport, setModalConfirmImport] = useState(false);
  const [modalConfirmRegisterNewValue, setModalConfirmRegisterNewValue] =
    useState(false);
  const [modalNotGoodOutpout, setModalNotGoodOutpout] = useState(false);
  const [overlay, setOverlay] = useState(false);
  const [isCheckedDateUpdate, setIsCheckedDateUpdate] = useState(false);

  //CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;

  const frequencies = [
    "125HZ",
    "250Hz",
    "500Hz",
    "750Hz",
    "1Khz",
    "1.5Khz",
    "2Khz",
    "3Khz",
    "4Khz",
    "6KHZ",
    "8KHZ",
  ];

  useEffect(() => {
    if (cabId !== "") {
      const controller = new AbortController();
      const signal = controller.signal;

      const fetchTonal = Axios.get(`/calibrationTonal/${cabId}`, { signal })
        .then((response) => {
          setloadingTon(false);
          if (response.data.message === "aucune donnée") {
            setCalibTonal([
              "-200",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "0",
              "-200",
              "-200",
            ]);
          } else {
            const allValueBone = response.data.data;
            const serialNumber = displayCalibValue(
              allValueBone,
              "Bone_Serial"
            )[0];
            setSerialNumber(decodedB64(serialNumber));

            const serialNumberCard = displayCalibValue(
              allValueBone,
              "Bone_Soundcard_Serial"
            )[0];
            setSerialSoundcardNumber(decodedB64(serialNumberCard));

            const valueTonal = displayCalibValue(allValueBone, "Bone_Value");
            if (valueTonal.length >= 1) {
              const data = valueTonal[0].split(",");
              setCalibTonal(data);
            } else {
              setCalibTonal([
                "-200",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "0",
                "-200",
                "-200",
              ]);
            }

            const dateTonal = displayCalibValue(allValueBone, "Bone_Passed")[0];
            console.log(dateTonal);
            return dateTonal;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      const fetchVocal = Axios.get(`/calibrationVocal/${cabId}`, { signal })
        .then((response) => {
          setloadingVoc(false);
          if (response.data.message === "aucune donnée") {
            setCalibVocal(["0"]);
          } else {
            const allValueBone = response.data.data;
            const valueVocal = displayCalibValue(allValueBone, "Bone_Value");
            if (valueVocal.length >= 1) {
              setCalibVocal(valueVocal[0]);
            } else {
              setCalibVocal(["0"]);
            }

            const dateVocal = displayCalibValue(allValueBone, "Bone_Passed")[0];

            return dateVocal;
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Promise.all([fetchTonal, fetchVocal])
        .then(([dateTonal, dateVocal]) => {
          const anyDateExists = dateTonal || dateVocal;
          // Vérifier si toutes les dates sont présentes
          const allDatesExist = dateTonal && dateVocal;

          if (allDatesExist) {
            setDateCalibration(dateTonal); // Utiliser dateGeneral comme exemple
            setWarningMessage(""); // Pas de message d'avertissement nécessaire
            setLoadingCalibrationTonalForDate(false);
          } else if (anyDateExists) {
            setDateCalibration("");
            setWarningMessage(
              "Il manque une ou plusieurs date de calibration ( ton/gen/voc)"
            );
            setLoadingCalibrationTonalForDate(false);
          } else {
            setDateCalibration("");
            setLoadingCalibrationTonalForDate(false);
            // Ne pas définir de message d'avertissement si toutes les dates sont absentes
          }
        })
        .finally(() => {
          setLoadingCalibrationTonalForDate(false);
        });
    }
    return () => {
      controller.abort();
    };
  }, [cabId, setCabId, token, maj]);

  //UPDATE CALIB TONAL STATE
  const updateFrequenciesValue = (id, e) => {
    const newState = calibTonal.map((obj, index) => {
      if (id === index) {
        //index-1 si on veut enlever les valeur: car ne pas oublié qu'on 3 valeurs à 0 une à 125hz et deux à 6 et 8khz
        const newValue = e.target.value; //valeur de l'input
        return newValue;
      }

      return obj;
    });

    setCalibTonal(newState);
  };

  const registerNewValueBone = (params) => {
    const serialNumberEncoded = encodedB64(serialNumber);
    const serialNumberSoundCardEncoded = encodedB64(serialSoundcardNumber);

    const date =
      isCheckedDateUpdate || !dateCalibration
        ? convertDateToTicks()
        : dateCalibration; // date now or date old

    setLoading(true);
    //create catid if they don't exist in DB
    Axios.post(`/createCatid/${cabId}`)

      .then((response) => {
        toast.success(response.data.message);
        // if they exist or if they are created we register calib data
        Axios.put(`/newValueBoneGeneral/${cabId}`, {
          data: { generalValue: generalValue, date: date },
        })

          .then((response) => {
            setLoading(false);
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 1500);
          })
          .catch(function (error) {});
        Axios.put(`/newValueBoneTonal/${cabId}`, {
          data: { calibTonal: calibTonal, date: date },
        })

          .then((response) => {
            setLoading(false);
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 1500);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueBoneVocal/${cabId}`, {
          data: { calibVocal: calibVocal, date: date },
        })

          .then((response) => {
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 2000);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueBoneSoundcardSerial/${cabId}`, {
          data: serialNumberSoundCardEncoded,
        })

          .then((response) => {
            setSucess(true);
            setTimeout(() => {
              setSucess(false);
            }, 2000);
          })
          .catch(function (error) {
            console.log(error);
          });
        Axios.put(`/newValueBoneSerial/${cabId}`, {
          data: serialNumberEncoded,
        })

          .then((response) => {
            console.log(response);
            setSucess(true);
            setSerialNumber("");
            setOverlay(false); // dans le cas d'import
            setModalConfirmImport(false); // dans le cas d'import
            setModalConfirmRegisterNewValue(false); // dans le cas de nouvelle valeur
            setMaj(!maj);
            toast.success("Toutes les données sont enregistrés");
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        if (error.response) {
          // Si une réponse avec un message d'erreur est reçue du backend
          const errorMessage = error.response.data.message;

          toast.error(errorMessage, {
            autoClose: false,
          });
        } else {
          toast.error(
            "une erreur s'est produite lors de la création des catid",
            {
              autoClose: false,
            }
          );
        }
      });
  };

  const checkHandler = () => {
    setIsCheckedDateUpdate(!isCheckedDateUpdate);
  };

  //IMPORT AND EXPORT CALIBRATION DATA
  const exportCalibDataBone = async () => {
    const calibDate = ticksToDateYearMonthDay(dateCalibration);

    // Create a TXT file
    const data = {
      Eoutpout: "Bone",
      date: dateCalibration,
      general: generalValue,
      vocal: calibVocal,
      tonal: calibTonal,
      serialNumber: serialNumber, // Add serial number
      serialSoundcardNumber: serialSoundcardNumber, // Add soundcard serial number
    };
    // Bone Calibration Data Ref - 221572109 - DFCB0100136098 - 12_10_2023
    const dataStr = JSON.stringify(data);
    const filename = `Bone Calibration Data - ${serialNumber} - ${serialSoundcardNumber} - [${cabname}(${cabId})] - ${calibDate}.txt`;
    const filetype = "text/plain";

    // Download the file
    download(dataStr, filename, filetype);
  };

  const fileChangedHandler = (file) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (selectedFile) {
      importCalibDataBone();
    }
  }, [selectedFile]);

  const importCalibDataBone = () => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      // The file's text will be printed here
      const text = e.target.result;

      const data = JSON.parse(text);

      if (data.Eoutpout !== "Bone") {
        setModalNotGoodOutpout(true);
        setOverlay(true);
        return;
      }

      const boneTonal = data.tonal;
      const boneVocal = data.vocal;
      const date = data.date;
      const importedSerialNumber = data.serialNumber || "";
      const importedSerialSoundcardNumber = data.serialSoundcardNumber || "";

      setCalibTonal(boneTonal);
      setCalibVocal(boneVocal);
      setDateCalibration(date);
      setSerialNumber(importedSerialNumber); // Set the serial number
      setSerialSoundcardNumber(importedSerialSoundcardNumber); // Set the soundcard serial number
      setModalConfirmImport(true);
      setOverlay(true);
    };

    reader.readAsText(selectedFile);
  };

  // Surveiller tous les états de chargement
  useEffect(() => {
    if (!loadingTon && !loadingVoc && !loadingCalibrationTonalForDate) {
      setLoadingAllData(false); // Tout est chargé
    }
  }, [loadingCalibrationTonalForDate, loadingTon, loadingVoc]);

  return (
    <>
      {loadingAllData && <LoaderLogin />}
      <div
        onClick={() => setDisplayBone(!displayBone)}
        className={styles.containerTitleBone}
      >
        <div className={styles.titleBone}>
          <span>Configuration CO</span>
          <img src={conduction} alt="" />
          {dateCalibration !== "" ? (
            <>
              <GreenLedSolo /> <span>{ticksToDate(dateCalibration)}</span>
            </>
          ) : (
            <>
              <RedLedSolo />
              <span>{warningMessage}</span>
            </>
          )}
        </div>
        {displayBone ? (
          <IoIosArrowDropup className={styles.iconDropDown} />
        ) : (
          <IoIosArrowDropdown className={styles.iconDropDown} />
        )}
      </div>
      {/* /********************IMPORT EXPORT */}
      {displayBone && (
        <div className={styles.containerBoneCalib}>
          {overlay && <div className={styles.overlay}></div>}
          <div className={styles.containerBtnExport}>
            <Button
              onPress={exportCalibDataBone}
              styles={"btnExport"}
              text={"Export données de Calibration"}
            />
            <div className={styles.containerImport}>
              <div className={styles.containerInputImport}>
                <DropZone onFileUpload={fileChangedHandler} />
                <Button
                  onPress={() => {
                    setMaj(!maj);
                  }}
                  styles={"btnPrimary"}
                  text={"Reset"}
                />
              </div>
            </div>
          </div>

          {modalNotGoodOutpout && (
            <ModalNotGoodOupout
              setOverlay={setOverlay}
              setModalNotGoodOutpout={setModalNotGoodOutpout}
            />
          )}

          {modalConfirmImport && (
            <ModalComfirmImport
              setModalConfirmImport={setModalConfirmImport}
              setOverlay={setOverlay}
              isCheckedDateUpdate={isCheckedDateUpdate}
              checkHandler={checkHandler}
              setMaj={setMaj}
              maj={maj}
              registerNewValue={registerNewValueBone}
            />
          )}
          {/* /********************IMPORT EXPORT */}
          <h2>Calibration CO</h2>
          <h3>
            {cabname}({cabId})
          </h3>

          <h4>Correction en Tonale</h4>
          <div className={styles.containerValue}>
            <div className={styles.frequencies}>
              {frequencies.map((freq, index) => {
                return <span key={index}>{freq}</span>;
              })}
            </div>
            <div className={styles.value}>
              {/* slice(1,9) pour enlever les value 125 et 6 et 8 */}
              {calibTonal.map((value, index) => {
                return (
                  <input
                    onChange={(e) => {
                      updateFrequenciesValue(index, e);
                    }}
                    onWheel={() => document.activeElement.blur()}
                    key={index}
                    type="number"
                    min="-200"
                    max="200"
                    step="0.1"
                    value={value}
                  ></input>
                );
              })}
            </div>
          </div>
          <div className={styles.containerVocale}>
            <h4>Vocal:</h4>

            <input
              onChange={(e) => setCalibVocal(e.target.value)}
              onWheel={() => document.activeElement.blur()}
              type="number"
              step="0.1"
              value={calibVocal}
            ></input>
          </div>
          <div className={styles.containerAllSerial}>
            <div className={styles.containerSerial}>
              <div className={styles.serial}>
                <h4>n° de serie CO</h4>

                <input
                  onChange={(e) => setSerialNumber(e.target.value)}
                  type="text"
                  value={serialNumber}
                ></input>
              </div>

              <div className={styles.serial}>
                <h4>n° de série carte son</h4>
                <input
                  onChange={(e) => setSerialSoundcardNumber(e.target.value)}
                  type="text"
                  value={serialSoundcardNumber}
                ></input>
              </div>
            </div>
          </div>
          <div className={styles.containerBtn}>
            <div className={styles.useCurrentDate}>
              <input
                type="checkbox"
                checked={isCheckedDateUpdate}
                onChange={checkHandler}
              />
              <label htmlFor="">Mettre à jour avec la date du jour</label>
            </div>
            <Button
              onPress={() => {
                setOverlay(true);
                setModalConfirmRegisterNewValue(true);
              }}
              styles={"btnValidation"}
              text={"Enregistrer les nouvelles valeurs"}
            />

            {modalConfirmRegisterNewValue && (
              <ModalConfirmRegisterNewValue
                setOverlay={setOverlay}
                setModalConfirmRegisterNewValue={
                  setModalConfirmRegisterNewValue
                }
                isCheckedDateUpdate={isCheckedDateUpdate}
                checkHandler={checkHandler}
                registerNewValue={registerNewValueBone}
              />
            )}

            {loading && <Loader />}
            {success && <Success success={"Données de calibration MAJ"} />}
          </div>
        </div>
      )}
    </>
  );
};

export default BoneCalib;
