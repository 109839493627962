import React from 'react';

const ReinstallHS = () => {
    return (
        <div>
    
    Dans c:/programme/hearing/Space <br/>
    Si besoin de réinstaller HS on peut renommer le dossier Game en 'GameOld'<br/>
    Ensuite On fait updater comme çela HS se reinstalle completement.<br/>
    Suppression ensuite de 'GameOld'. <br/>
    
       - RESTORE LTS == seulement VYV  ( jamais d'update directement) <br/>
       - RESTORE== remet en place met sans update <br/>




        </div>
    );
};

export default ReinstallHS;