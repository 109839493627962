import React, { useEffect, useState } from "react";
import styles from "./InfosUser.module.scss";
import { RiAdminFill, RiUserAddFill } from "react-icons/ri";
import { GrUserAdmin } from "react-icons/gr";

import Button from "../../UiComponents/Button";
import axios from "axios";
import cabineImg from "../../../pics/cabine.png";
import Axios from "../../../_services/caller.service";

const InfosUser = ({
  infosUser,
  setOwnerId,
  ownerLink,
  ownerId,
  userType,
  displaySelectConnexion,
  setDisplaySelectConnexion,
  setDisplayConnectingUser,
  displayConnectingUser,
  cabineAccess,
}) => {
  //DISPLAY
  const [displayInfos, setDisplayInfos] = useState(false);
  const [infosCabAccess, setInfosCabAccess] = useState([]);
  const [noAccess, setNoAccess] = useState("");
  const [keepValueId, setKeepValueId] = useState("");

  //CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;

  // GET INFOS USER ACCESS TO CABINE

  useEffect(() => {
    Axios.get(`/infosCabAccess/${ownerId}`, { signal })

      .then(function async(response) {
        if (response.data.message === "aucun acces") {
          setNoAccess("Aucun accès aux cabines pour cet utilisateur");
          setInfosCabAccess([]);
        } else {
          setInfosCabAccess(response.data.data);
          setNoAccess("");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return () => {
      controller.abort();
    };
  }, [ownerId, setOwnerId]);

  // display user's role
  const displayRole = (role) => {
    if (role === 8) {
      return "Calibration";
    }
    if (role === 1) {
      return "Consultation";
    }
    if (role === 256) {
      return "Admin";
    }
  };

  const ownerAndInviteOptions = Array.isArray(ownerLink)
    ? ownerLink.map((option) => ({
        id: option.ID, // Assurez-vous que cette propriété existe dans vos objets
        label: `Owner user link: ${option.display_name} - Email: ${option.user_email}`,
        role: "userOfOwner",
        // ... autres informations nécessaires de l'option
      }))
    : []; // Retourne un tableau vide si infosUser n'est pas un tableau

  // Ajoutez maintenant l'option pour le owner lui-même
  const ownerOption = {
    id: infosUser.ID,
    label: `Owner: ${infosUser.display_name} - Email: ${infosUser.user_email}`,
    role: "owner",
    // ... autres informations nécessaires du owner
  };

  // Fusionnez les options dans un seul tableau
  const usersOptions = [...ownerAndInviteOptions, ownerOption];

  const shouldIncludeOwnerOption =
    userType !== "Utilisateur des cabines d'un owner";

  const filteredUserOptions = shouldIncludeOwnerOption
    ? usersOptions
    : ownerAndInviteOptions; // Exclut ownerOption si l'utilisateur est un utilisateur des cabines d'un owner

  const handleSelectChange = (selectedId) => {
    setKeepValueId(selectedId);
  };

  return (
    <>
      {userType !== "Owner" && displaySelectConnexion && cabineAccess && (
        <div className={styles.containerInfosUser}>
          <div className={styles.accountUserConnexion}>
            <span>Voici les comptes de l'utilisateur</span>
            <select
              onChange={(e) => handleSelectChange(e.target.value)}
              className={styles.yourSelectStyle}
            >
              <option value="">Selectionner un utilisateur</option>
              {filteredUserOptions.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
            <Button
              onPress={() => {
                setOwnerId(keepValueId);
                setDisplaySelectConnexion(false);
                setDisplayConnectingUser(true);
              }}
              styles={"btnValidation"}
              text={"Se connecter "}
            />
          </div>
        </div>
      )}

      {!cabineAccess && (
        <div className={styles.noAccessCabine}>
          {infosUser.display_name}({infosUser.user_email}) ne dispose d'aucun
          accès aux cabines.
        </div>
      )}
    </>
  );
};

export default InfosUser;
