import React, { useEffect, useState } from "react";
import { MdDownloadForOffline } from "react-icons/md";
import { GrAddCircle } from "react-icons/gr";
import { useCabineContext } from "../../../context/CabineContext";
import {
  GreenLedSolo,
  BlueLedSolo,
  RedLedSolo,
  YellowLedSolo,
} from "../../Team/userCard/Led";
import Button from "../../UiComponents/Button";
import styles from "./CalibrationArray.module.scss";
//IMAGE
import conductionImg from "../../../pics/ConductionOsseusePdf.png";
import HPImg from "../../../pics/speakersPdff.png";
import casqueImg from "../../../pics/casquePdf.png";
import insertImg from "../../../pics/InsertPdf.png";
import useModal from "../../../hooks/useModal";
import Modal from "../../UiComponents/modals/Modal";
import InputForms from "../../UiComponents/forms/InputForms";
import { BiMailSend } from "react-icons/bi";
import Success from "../../UiComponents/sucess/Success";
import Error from "../../UiComponents/error/Error";
import Select from "react-select";
import { MdOutlineMail } from "react-icons/md";
import { GrCertificate } from "react-icons/gr";

const CalibrationArray = ({
  cabname,
  onPress,
  onPressBack,
  onPressEmail,
  cab,
  cabine,
  setEmailToSendPdf,
  success,
  error,
  isShowing,
  toggle,
}) => {
  const [selectedId, setSelectedId] = useState(null);
  const [isVisible, setIsVisible] = useState({
    co: false,
    head: false,
    insert: false,
    speakers: false,
  });

  //MOBILE DISPLAY
  const [mobile, setMobile] = useState(false);
  const [displayLed, setDisplayLed] = useState(false);
  const sizeForLed = "12px";

  const displayGoodColorLed = (number) => {
    if (number <= 0 && number !== null)
      return <RedLedSolo width={sizeForLed} height={sizeForLed} />;
    else if (number <= 30 && number !== null)
      return <YellowLedSolo width={sizeForLed} height={sizeForLed} />;
    else if (number >= 30 && number !== null)
      return <GreenLedSolo width={sizeForLed} height={sizeForLed} />;
    else if (number == null || undefined)
      return <BlueLedSolo width={sizeForLed} height={sizeForLed} />;
  };

  const displayNumberDayInBubble = (id, type) => {
    if (selectedId === id) {
      setSelectedId(null);
      setIsVisible(false);
    } else {
      setIsVisible((prevState) => {
        return {
          ...prevState,
          [type]: !prevState[type],
        };
      });
      setSelectedId(id);
    }
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 950) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const displayLedMobile = (params) => {
    if (!mobile) return true;
    else if (mobile && displayLed) return true;
    else return false;
  };

  return (
    <div className={styles.download}>
      <div className={styles.nameAndAdd}>
        <h3 className={styles.cabname}>{cabname}</h3>
        {/* <h3 className={styles.cabname}>{cab.id}</h3> */}
        {/* <span>{cab.id}</span> */}
        {mobile && (
          <GrAddCircle
            onClick={() => setDisplayLed(!displayLed)}
            style={{ fontSize: "18px", cursor: "pointer", marginRight: "10px" }}
          />
        )}
      </div>
      {displayLedMobile() && (
        <div className={styles.containerAllLed}>
          {/* BONE */}
          {cab.numberDayBone !== null && (
            <div
              className={styles.containerNextCalibrationLed}
              onClick={() => displayNumberDayInBubble(cab.id, "co")}
              onMouseEnter={() => displayNumberDayInBubble(cab.id, "co")}
              onMouseLeave={() => displayNumberDayInBubble(null, "co")}
            >
              {/* <h3>CO</h3> */}
              <img src={conductionImg} alt="" />
              {displayGoodColorLed(cab.numberDayBone)}

              <div
                className={
                  selectedId === cab.id &&
                  isVisible.co &&
                  cab.numberDayBone !== null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                <h3>
                  {selectedId === cab.id &&
                    isVisible.co &&
                    cab.numberDayBone !== null &&
                    Math.abs(cab.numberDayBone)}
                </h3>
                {selectedId === cab.id &&
                  isVisible.co &&
                  cab.numberDayBone !== null &&
                  cab.numberDayBone >= 0 && <span>Jours restants</span>}
                {selectedId === cab.id &&
                  isVisible.co &&
                  cab.numberDayBone !== null &&
                  cab.numberDayBone <= 0 && <span>Jours de retard</span>}
              </div>

              {/* PAS DE CALIBRATION  BONE  */}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.co &&
                  cab.numberDayBone === null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                {selectedId === cab.id &&
                  isVisible.co &&
                  cab.numberDayBone === null && <h3>pas de calibration</h3>}
              </div>
            </div>
          )}

          {/* HEADPHONES */}
          {cab.numberDayHead !== null && (
            <div
              className={styles.containerNextCalibrationLed}
              onClick={() => displayNumberDayInBubble(cab.id, "head")}
              onMouseEnter={() => displayNumberDayInBubble(cab.id, "head")}
              onMouseLeave={() => displayNumberDayInBubble(null, "head")}
            >
              {/* <h3>Casque</h3> */}
              <img src={casqueImg} alt="" />
              {displayGoodColorLed(cab.numberDayHead)}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.head &&
                  cab.numberDayHead !== null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                <h3>
                  {selectedId === cab.id &&
                    isVisible.head &&
                    cab.numberDayHead !== null &&
                    Math.abs(cab.numberDayHead)}
                </h3>
                {selectedId === cab.id &&
                  isVisible.head &&
                  cab.numberDayHead !== null &&
                  cab.numberDayHead >= 0 && <span>Jours restants</span>}
                {selectedId === cab.id &&
                  isVisible.head &&
                  cab.numberDayHead !== null &&
                  cab.numberDayHead <= 0 && <span>Jours de retard</span>}
              </div>
              {/* PAS DE CALIBRATION  HEAD  */}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.head &&
                  cab.numberDayHead === null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                {selectedId === cab.id &&
                  isVisible.head &&
                  cab.numberDayHead === null && <h3>pas de calibration</h3>}
              </div>
            </div>
          )}

          {/* INSERT */}
          {cab.numberDayInsert !== null && (
            <div
              className={styles.containerNextCalibrationLed}
              onClick={() => displayNumberDayInBubble(cab.id, "insert")}
              onMouseEnter={() => displayNumberDayInBubble(cab.id, "insert")}
              onMouseLeave={() => displayNumberDayInBubble(null, "insert")}
            >
              {/* <h3>Inserts</h3> */}

              <img src={insertImg} alt="" />
              {displayGoodColorLed(cab.numberDayInsert)}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.insert &&
                  cab.numberDayInsert !== null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                <h3>
                  {selectedId === cab.id &&
                    isVisible.insert &&
                    cab.numberDayInsert !== null &&
                    Math.abs(cab.numberDayInsert)}
                </h3>
                {selectedId === cab.id &&
                  isVisible.insert &&
                  cab.numberDayInsert !== null &&
                  cab.numberDayInsert >= 0 && <span>Jours restants</span>}
                {selectedId === cab.id &&
                  isVisible.insert &&
                  cab.numberDayInsert !== null &&
                  cab.numberDayInsert <= 0 && <span>Jours de retard</span>}
              </div>
              {/* PAS DE CALIBRATION  INSERT  */}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.insert &&
                  cab.numberDayInsert === null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                {selectedId === cab.id &&
                  isVisible.insert &&
                  cab.numberDayInsert === null && <h3>pas de calibration</h3>}
              </div>
            </div>
          )}
          {/* CHAMP LIBRE */}
          {cab.numberDaySpeakers !== null && (
            <div
              className={styles.containerNextCalibrationLed}
              onClick={() => displayNumberDayInBubble(cab.id, "speakers")}
              onMouseEnter={() => displayNumberDayInBubble(cab.id, "speakers")}
              onMouseLeave={() => displayNumberDayInBubble(null, "speakers")}
            >
              {/* <h3>Champ libre</h3> */}
              <img src={HPImg} alt="" />

              {displayGoodColorLed(cab.numberDaySpeakers)}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.speakers &&
                  cab.numberDaySpeakers !== null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                <h3>
                  {selectedId === cab.id &&
                    isVisible.speakers &&
                    cab.numberDaySpeakers !== null &&
                    Math.abs(cab.numberDaySpeakers)}
                </h3>

                {selectedId === cab.id &&
                  isVisible.speakers &&
                  cab.numberDaySpeakers !== null &&
                  cab.numberDaySpeakers >= 0 && <span>Jours restants</span>}
                {selectedId === cab.id &&
                  isVisible.speakers &&
                  cab.numberDaySpeakers !== null &&
                  cab.numberDaySpeakers <= 0 && <span>Jours de retard</span>}
              </div>
              {/* PAS DE CALIBRATION  SPEAKERS  */}
              <div
                className={
                  selectedId === cab.id &&
                  isVisible.speakers &&
                  cab.numberDaySpeakers === null
                    ? styles.containerBubbleNumberDay
                    : null
                }
              >
                {selectedId === cab.id &&
                  isVisible.speakers &&
                  cab.numberDaySpeakers === null && <h3>pas de calibration</h3>}
              </div>
            </div>
          )}
        </div>
      )}

      <Button
        onPress={onPressBack}
        styles={"btnPrimary"}
        text={"certificat de calibration"}
        svg={<MdDownloadForOffline />}
      />
    </div>
  );
};

export default CalibrationArray;
