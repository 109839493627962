import React, { useEffect, useState } from "react";
import InputForms from "../../UiComponents/forms/InputForms";
import styles from "./SelectConfigCabine.module.scss";

const SelectConfigCabine = ({
  cabId,
  cabine,
  setCabId,
  isSearchingSettingCab,
  setIsSearchingSettingCab,
}) => {
  const [searchCab, setSearchCab] = useState("");

  // change de cabine avec le select
  const cabIdValueSpeaker = (e) => {
    setCabId(e.target.value);
    setSearchCab("");
  };

  useEffect(() => {
    setSearchCab("");
  }, [cabine]);


  return (
    <div
      className={styles.selectCab}
      onClick={() => setIsSearchingSettingCab(false)}
    >
      <select
        value={cabId}
        onChange={cabIdValueSpeaker}
        size={isSearchingSettingCab ? 10 : 0}
        onBlur={() => setIsSearchingSettingCab(false)}
      >
       <option value="">-- Sélectionnez une cabine --</option>
        {cabine &&
          cabine
            .filter((c) => c.cabname.toLowerCase().includes(searchCab))
            .map((cab, index) => {
              return (
                <option key={index} value={cab.id}>
                  {cab.cabname} ({cab.id})
                </option>
              );
            })}
      </select>

      <InputForms
        value={searchCab}
        onChange={(e) => {
          setSearchCab(e.target.value);
          setIsSearchingSettingCab(true);
        }}
        placeholder={"Recherche cabines"}
      />
    </div>
  );
};

export default SelectConfigCabine;
