import React, { useContext, useRef, useEffect, useState } from "react";
import styles from "../InviteUserAfterMail/UserAccountLinker.module.scss";
import { useNavigate } from "react-router-dom";
import Loader from "../UiComponents/loader/Loader";
import Success from "../UiComponents/sucess/Success";
import axios from "axios";
import { MdClose, MdOutlineAssignmentInd } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import Button from "../UiComponents/Button";
import ContainerForm from "../UiComponents/forms/ContainerForm";
import BtnForms from "../UiComponents/forms/BtnForms";
import InputForms from "../UiComponents/forms/InputForms";
import { handleValidation, strongRegex } from "../../utils/formValidation";
import Error from "../UiComponents/error/Error";
import InputPassword from "../UiComponents/forms/InputPassword";
import RulesPassword from "../UiComponents/forms/RulesPassword";
export default function SignUpModal({
  setDisplaySignUpModal,
  setAccountCreate,
  setUserPasswordSignIn,
  confirmInvitation,
  setNameForEmailSuccess,
  setLastNameForEmailSucess,
}) {
  const tokenInviteLogin = localStorage.getItem("invite");
  const navigate = useNavigate();
  //
  const [toggleModal, setToggleModal] = useState("");

  // form register
  const [validation, setValidation] = useState("");

  const [name, setName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  // loading and error
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  //fixed value
  const registered = new Date().toISOString().slice(0, 19).replace("T", " ");
  const status = 1;

  // first letter for name and lastName in capital letter
  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

  const handleForm = async (e) => {
    e.preventDefault();
    const login = `${name}_${lastName}`.toLowerCase();


    // validation côté front
    if (login.length < 6) {
      setValidation("votre speudo doit contenir au moins 6 caractères");

      return;
    } else if (password !== password2) {
      setValidation("les mots de passe ne sont pas identiques");

      return;
    }
    if (strongRegex.test(password) === false) {
      setValidation("Le mot de passe n'est pas assez fort");
      return;
    }
    setLoading(true);
    //Ne pas modifier axios par AXIOS
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/registerInviteUser/`,
        {
          login,
          name,
          lastName,
          email,
          password,
          password2,
          registered,
          status,
        },
        {
          headers: { authorization: "Bearer " + tokenInviteLogin },
          withCredentials: true,
        }
      )

      .then(function (response) {
        if (response.data.msg === "email already use") {
          setValidation("Email déjà utilisé");
          setLoading(false);
          return;
        }
        if (response.data.msg === "login name already use") {
          setValidation("Nom utilisateur déjà utilisé");
          setLoading(false);
          return;
        }
        setLoading(false);
        setSuccess(true);
        const userIdCreate = response.data.data;

        confirmInvitation(userIdCreate); // lance la fonction de link user to owner

        setTimeout(() => {
          setSuccess(false);
          setDisplaySignUpModal(false);
          setAccountCreate(true);
        }, 2000);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // ferme la modale et remet les forms à zéro
  const closeModal = (e) => {
    setValidation("");
    setDisplaySignUpModal(false);
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEscape);
  }, []);
  const handleEscape = (evt) => {
    if (evt.key === "Escape") closeModal();
  };

  return (
    <>
      <ContainerForm
        onSubmit={handleForm}
        children={
          <>
            <InputForms
              type={"text"}
              placeholder={"Email"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              required={true}
              svg={<AiOutlineMail />}
              //startCursor='15px'
              // endCursor={25}
            />

            <InputForms
              type={"text"}
              placeholder={"Nom"}
              onChange={(e) => {
                setlastName(capitalize(e.target.value));
                setLastNameForEmailSucess(capitalize(e.target.value));
              }}
              required={true}
              svg={<FaUserAlt />}
            />
            <InputForms
              type={"text"}
              placeholder={"Prénom"}
              onChange={(e) => {
                setName(capitalize(e.target.value));
                setNameForEmailSuccess(capitalize(e.target.value));
              }}
              required={true}
              svg={<FaUserAlt />}
            />
            <InputPassword
              placeholder={"mot de passe"}
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => setValidation(handleValidation(e))}
              required={true}
              id={styles.accountCreatePassWord}
            />
            <InputPassword
              placeholder={"confirmation mdp"}
              onChange={(e) => {
                setPassword2(e.target.value);
                setUserPasswordSignIn(e.target.value);
              }}
              onKeyUp={(e) => setValidation(handleValidation(e))}
              required={true}
              id={styles.accountCreatePassWord2}
            />

            {validation !== "" && <Error error={validation} />}

            <BtnForms
              text="Je crée mon compte"
              svg={<MdOutlineAssignmentInd />}
            />
            <RulesPassword />
            {loading && <Loader />}
            {success && (
              <div style={{ width: "80%", margin: "auto" }}>
                <Success success={"Votre compte est bien enregistrer"} />
              </div>
            )}
          </>
        }
      />
    </>
  );
}
