import axios from "axios";
import React, { useState, useRef, useContext } from "react";
import { MdSend, MdCancel } from "react-icons/md";
import NavContext from "../../../context/NavContext";
import Button from "../../UiComponents/Button";
import styles from "./ResendInviteCard.module.scss";
import Success from "../../UiComponents/sucess/Success";
import Loader from "../../UiComponents/loader/Loader";
import Axios from "../../../_services/caller.service";

const ResendInviteCard = ({
  inviteName,
  inviteEmail,
  inviteWaiting,
  invit,
  setNewInvite,
  newInvite,
  dateInvitation,
  dateExpiration,
  hashId,
}) => {
  //CONTEXT
  const { ownerId, ownerProfile } = useContext(NavContext);
  //FORMS
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  //DISPLAY
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);
  const [annulationSuccess, setAnnulationSuccess] = useState(false);
  const [confirmResend, setConfirmResend] = useState(false);
  const [confirmAnnulation, setConfirmAnnulation] = useState(false);
  //OWNER PROFIL
  const senderOwner = ownerProfile.display_name;
  const emailOwner = ownerProfile.user_email;

  //  Constant for insert into inviteList db
  const hash = Math.random();

  const resendInvitation = () => {
    setLoader(true);
    Axios.post(`/inviteUser/${ownerId}`, {
      data: { name, lastName, email, ownerId, senderOwner, emailOwner },
    })

      .then(function (response) {
        const linkSend = response.data.data.link; // get link send by mail

        setLoader(false);
        setSuccess(true);
        setConfirmResend(false);
        setTimeout(() => {
          setSuccess(false);
          setConfirmResend(false);
        }, 2500);

        Axios.put(`/updateInviteList/${ownerId}`, {
          data: { hash, ownerId, email, lastName, name, linkSend },
        })

          .then(function (response) {
            setNewInvite(newInvite + 1); // useeffect components
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const annulationInvitation = (params) => {
    setLoader(true);

    //hash Id est l'id stocker à l'envoie du forms
    Axios.delete(`/deleteInviteList/${email}/${hashId}`)

      .then(function (response) {
        //  const linkSend = response.data.data; // get link send by mail
        setLoader(false);
        setAnnulationSuccess(true);
        setConfirmAnnulation(false);
        setTimeout(() => {
          setAnnulationSuccess(false);
          setNewInvite(newInvite + 1);
        }, 2500);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectedInvitation = (type) => {
    const currentInvit = inviteWaiting
      .filter((oneInvite) => oneInvite.hash === invit.hash)
      .map((u) => {
        setEmail(u.email);
        setName("");
        setLastName(u.display_name);
      });

    type === "annulation" ? setConfirmAnnulation(true) : setConfirmResend(true);
  };

  const annulation = () => {
    setConfirmResend(false);
    setConfirmAnnulation(false);
  };

  return (
    <div className={styles.userInvite}>
      <h3 className={styles.InviteName}>{inviteName}</h3>
      <h3 className={styles.InviteEmail}>{inviteEmail}</h3>

      <h3 className={styles.dateInvitation}>{dateInvitation}</h3>
      <h3 className={styles.dateExpiration}>{dateExpiration}</h3>
      <div className={styles.containerBtn}>
        {!confirmAnnulation && (
          <Button
            onPress={() => selectedInvitation("annulation")}
            type={"submit"}
            styles={"btnDelete"}
            svg={<MdCancel />}
            text={"Annuler l'invitation"}
          />
        )}

        {!confirmResend && (
          <Button
            onPress={() => selectedInvitation("resend")}
            type={"submit"}
            styles={"btnValidation"}
            svg={<MdSend />}
            text={"Renvoyer l'invitation"}
          />
        )}
      </div>

      {confirmResend && (
        <div className={styles.confirm}>
          <Button
            onPress={annulation}
            type={"submit"}
            styles={"btnDelete"}
            svg={<MdCancel />}
            text={"annuler"}
          />
          <Button
            onPress={resendInvitation}
            type={"submit"}
            styles={"btnValidation"}
            svg={<MdSend />}
            text={"Je confirme l'envoie"}
          />
        </div>
      )}
      {confirmAnnulation && (
        <div className={styles.confirm}>
          <Button
            onPress={annulation}
            type={"submit"}
            styles={"btnDelete"}
            svg={<MdCancel />}
            text={"retour"}
          />
          <Button
            onPress={annulationInvitation}
            type={"submit"}
            styles={"btnValidation"}
            svg={<MdSend />}
            text={"Je confirme l'annulation"}
          />
        </div>
      )}
      <div className={styles.success}>
      {success && <Success success={"Invitation renvoyée"} />}

       {annulationSuccess &&<Success success={"Vote invitation est bien annulée"} />}
</div>

      {loader && <Loader />}
    </div>
  );
};

export default ResendInviteCard;
