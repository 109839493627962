import React from "react";
import CardHeaderPage from "../../UiComponents/card/CardHeaderPage";
import HeaderNavSupportHS from "../headerNav/HeaderNavSupportHS";

const Dirac = () => {
  return (
    <>
      <HeaderNavSupportHS />
      <CardHeaderPage text={'Guide Dirac'} textAlign={'center'}/>
      <div>Je suis composante Dirac</div>
    </>
  );
};

export default Dirac;
