import React from "react";
import styles from "./SpeakersGraph.module.scss";
import head from "../../../pics/person.png";
import { displayAngleValueSvg } from "../../calibration/functionDataSorted";
import SpeakersSvg from "./SpeakersSvg";

const SpeakersGraph = ({ data, numberSpeakers }) => {
  const numberSpeak = numberSpeakers.toString(); // number of speakers config

  return (
    <>
      <div className={styles.circle}>
        <img src={head} alt="" />

        {numberSpeak === "MONO" && (
          <SpeakersSvg
            value={displayAngleValueSvg(data, "FRONT_LEFT", "black")}
          />
        )}
        {numberSpeak === "STEREO" && (
          <>
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_RIGHT", "red")}
            />
          </>
        )}
        {numberSpeak === "LRC" && (
          <>
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_RIGHT", "red")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_CENTER", "black")}
            />
          </>
        )}
        {numberSpeak === "QUAD" && (
          <>
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_RIGHT", "red")}
            />

            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_RIGHT", "red")}
            />
          </>
        )}
        {(numberSpeak === "_5POINT0" || numberSpeak === "_5POINT1") && (
          <>
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_RIGHT", "red")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_CENTER", "black")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_RIGHT", "red")}
            />
          </>
        )}

        {(numberSpeak === "_6POINT0" || numberSpeak === "_6POINT1") && (
          <>
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_RIGHT", "red")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_CENTER", "black")}
            />
       
       <SpeakersSvg
         value={displayAngleValueSvg(data, "SURROUND_LEFT", "blue")}
       />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_RIGHT", "red")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "BACK_LEFT", "blue")}
            />
         
          </>
        )}

        {(numberSpeak === "_7POINT0" || numberSpeak === "_7POINT1") && (
          <>
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_RIGHT", "red")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "FRONT_CENTER", "black")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "BACK_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "BACK_RIGHT", "red")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_LEFT", "blue")}
            />
            <SpeakersSvg
              value={displayAngleValueSvg(data, "SURROUND_RIGHT", "red")}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SpeakersGraph;
