import React from 'react';
import styles from './CategoriesFaq.module.scss';

const CategoriesFaq = ({svg,text,onPress}) => {
    return (
        <div onClick={onPress} className={styles.card}>
            {svg}
           <h3>{text}</h3> 
        </div>
    );
};

export default CategoriesFaq;