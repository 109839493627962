import axios from "axios";
import React, { useMemo, useEffect, useState, useContext } from "react";

import NavContext from "../context/NavContext";
import Axios from "../_services/caller.service";

const token = localStorage.getItem("jwt");


//get only ID user's invite

export function useUserInviteId() {
  const { userAuth, ownerId } = useContext(NavContext);
  const [userLinkedId, setUserLinkedId] = useState([]); // all user linked to owner

  useEffect(() => {
    if (userAuth) {
      Axios.get(`/getUserAccountLinked/${ownerId}`,)

        .then((response) => {
          const userAccountLinked = response.data.data.map((account) => {
            return account.linked;
          });
          setUserLinkedId(userAccountLinked);
        })
        .catch(function (error) {
          console.log(error);
        });
  
    }
  }, [userAuth, ownerId]);

  return userLinkedId;
}
