import React from 'react';
import styles from './InsertsGuideCalibration.module.scss'

const InsertsGuideCalibration = () => {
    return (
        <div>
            Je suis le guide de la calibration INSERTS
        </div>
    );
};

export default InsertsGuideCalibration;