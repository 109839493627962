import React, { useEffect, useState } from "react";

import styles from "./TutorialUploadDataMics.module.scss";
import tuto1 from "../../pics/tuto_1.png";
import tuto2 from "../../pics/tuto_2.png";
import tuto3 from "../../pics/tuto_3.png";
import { GoPlus } from "react-icons/go";
import Slider from "react-slick";

import { IoMdInformationCircleOutline } from "react-icons/io";

//style
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TutorialUploadDataMics = ({ toggle }) => {
  const [showMobile, setShowMobile] = useState(false);
  const [displayGuideInvitation, setDisplayGuideInvitation] = useState(false);

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
  };

  useEffect(() => {
    if (window.innerWidth < 1300) {
      setShowMobile(true);
    } else {
      setShowMobile(false);
    }
  }, []);

  return (
<>
  <div className={styles.container}>
    <a href="https://products.aspose.app/cells/fr/conversion" target="_blank" rel="noopener noreferrer" className={styles.h2LikeLink}>
      Aller sur ce site pour convertir Excel en JSON
    </a>

    <div className={styles.containerSlider}>
      <Slider {...settings}>
        <div className={styles.containerImg}>
          <img src={tuto1} alt="" />
        </div>
        <div className={styles.containerImg}>
          <img src={tuto2} alt="" />
        </div>
        <div className={styles.containerImg}>
          <img src={tuto3} alt="" />
        </div>
       
      </Slider>
    </div>
  </div>
</>

  );
};

export default TutorialUploadDataMics;
