import React, { useEffect, useState } from "react";
import Axios from "../../_services/caller.service";
import styles from "./ManageMics.module.scss";
import { MICRO_TYPES, MICRO_COLORS } from "./configMics";

import InputForms from "../../components/UiComponents/forms/InputForms";
import { FiRefreshCcw } from "react-icons/fi";
import {
  BsExclamationTriangleFill,
  BsFillExclamationTriangleFill,
  BsCheckCircle,
} from "react-icons/bs";
import { IoMdAddCircle } from "react-icons/io";
import Button from "../../components/UiComponents/Button";
import Modal from "../../components/UiComponents/modals/Modal";
import HeaderManageMics from "./HeaderManageMics";
import InviteForms from "../Team/InvitUser/InviteForms";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";
import UploadDataMics from "./UploadDataMics";
import TutorialUploadDataMics from "./TutorialUploadDataMics";

const ManageMics = () => {
  //USERS
  const [micros, setMicros] = useState([]);
  const [updateMicros, setUpdateMicros] = useState({});
  const [maj, setMaj] = useState(false);
  // RECHERCHE ET FILTRE
  const [searchTerm, setSearchTerm] = useState(""); // Pour la recherche micro
  const [filteredMicros, setFilteredMicros] = useState(micros);
  const [selectedMicros, setSelectedMicros] = useState("all");

  //PAGINATION
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(10);

  //INSERT A NEW MICS
  const [serial, setSerial] = useState("");
  const [micType, setMicType] = useState("Umik1");
  const [correctionL, setCorrectionL] = useState("");
  const [correctionR, setCorrectionR] = useState("");
  //MODAL
  const { isShowing, toggle } = useModal();
  const [activeForm, setActiveForm] = useState(null); // 'addNewMics', 'warningChangeValueMics', or null
  const [currentUpdate, setCurrentUpdate] = useState(null);
  //active l'un ou l'aautre du contenu modal
  const customToggle = () => {
    setActiveForm(null);
    toggle();
  };

  //GET ALL MICROS
  useEffect(() => {
    Axios.get("/getMicsSerials")
      .then((response) => {
        setMicros(response.data.results);
        setFilteredMicros(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [maj]);

  //UPDATE MICROS
  const handleUpdate = (serial, mictype) => {
    // Récupérer le micro d'origine en fonction du serial
    const originalMicro = filteredMicros.find(
      (mic) => mic.serial === serial && mic.mictype === mictype
    );

    // Récupérer les mises à jour pour ce serial spécifique (cela pourrait être un objet vide si aucune mise à jour n'a été apportée)
    const updatesForThisMicro = updateMicros[serial] || {};

    setCurrentUpdate({
      ...originalMicro,
      ...updatesForThisMicro,
    });

    // Montrer la modal de warning
    setActiveForm("warningChangeValueMics");
    toggle();
  };

  const confirmUpdate = () => {
    if (currentUpdate) {
      const microToSend = currentUpdate;
      console.log(microToSend);
      Axios.put("/updateMicSerial", microToSend)
        .then((response) => {
          toast.success("Micro mis à jour");
          // Mettre à jour l'état global de micros

          setMicros((prevMicros) => {
            const updatedMicros = prevMicros.map((mic) =>
              mic.serial === microToSend.serial &&
              mic.mictype === microToSend.mictype
                ? microToSend
                : mic
            );

            // Réappliquer les filtres pour conserver les filtres après la mise à jour
            const updatedFilteredMicros = filterMicros(
              updatedMicros,
              searchTerm,
              selectedMicros
            );
            setFilteredMicros(updatedFilteredMicros);

            return updatedMicros;
          });

          // Effacer updateMicros pour le serial spécifique
          setUpdateMicros((prevState) => {
            const newState = { ...prevState };
            delete newState[serial];
            return newState;
          });
          setCurrentUpdate(null);
          customToggle();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  //REMETTRE LES FILTRES APRES UNE MAJ DES DONNEES MICRO
  const filterMicros = (microsArray, search, selected) => {
    let result = microsArray;

    // Appliquer le filtre de recherche
    if (search) {
      result = result.filter(
        (mic) =>
          mic.mictype.includes(search) ||
          mic.serial.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Appliquer le filtre de sélection
    if (selected !== "all") {
      result = result.filter((mic) => mic.mictype === selected);
    }

    return result;
  };
  //Permet de conserver les filtres si il y a un maj des données micros
  useEffect(() => {
    // Si currentUpdate est null, cela signifie que la mise à jour vient d'être confirmée
    if (!currentUpdate && micros.length > 0) {
      const updatedFilteredMicros = filterMicros(
        micros,
        searchTerm,
        selectedMicros
      );
      setFilteredMicros(updatedFilteredMicros);
    }
  }, [currentUpdate, micros, searchTerm, selectedMicros]);

  //INSERT NEW MICS
  const addNewMics = () => {
    if (serial.length !== 7) {
      toast.error("Le serial doit avoir exactement 7 caractères.");
      // Vous pouvez également afficher un message d'erreur à l'utilisateur à ce stade
      return;
    }

    Axios.post("/addNewMics", {
      serial: serial,
      micType: micType,
      correctionL: correctionL,
      correctionR: correctionR,
    })
      .then((response) => {
        if (response.data.error) {
          console.error(response.data.message);
          toast.error(response.data.message);
        } else {
          setCorrectionL("");
          setCorrectionR("");
          setSerial("");
          setMicType("Umik1");
          toast.success("Nouveau micros ajouter !");
          customToggle();
        }

        console.log(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          console.error(error.request);
          toast.error("Aucune réponse du serveur.");
        }
      });
  };

  //RECHERCHE
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term)
    console.log(`Term for search: ${term}`);
  
    const searchedMicros = micros.filter((mic) => {
      const serialString = mic.serial.toString().toLowerCase();
      const match = serialString.includes(term);
      console.log(`Checking mic serial: ${serialString}, Match: ${match}`);
      return match;
    });
  
    console.log(`Filtered micros: ${searchedMicros.length}`);
    setFilteredMicros(searchedMicros);
  };
  

  //FILTRE DE RECHERCHE
  const handleFilterChange = () => {
    let newFilteredMicros;

    if (selectedMicros === "all") {
      newFilteredMicros = [...micros]; // return all users if no filters are applied
    } else {
      newFilteredMicros = micros.filter((mic) => {
        return selectedMicros !== "all" ? mic.mictype === selectedMicros : true;
      });
    }

    setFilteredMicros(newFilteredMicros);
  };

  useEffect(() => {
    handleFilterChange();
  }, [selectedMicros, micros]);

  // FONCTION POUR CHANGER LES VALUES DANS LE STATE UPDATE MICROS
  const handleInputChange = (serial, mictype, field, value) => {
    // Construire une clé unique basée sur le serial et le mictype
    const uniqueKey = `${serial}-${mictype}`;

    // Mettre à jour l'état de updateMicros
    setUpdateMicros((prevState) => ({
      ...prevState,
      [uniqueKey]: {
        ...prevState[uniqueKey],
        [field]: value,
      },
    }));

    // Mettre à jour l'état de micros pour refléter les changements dans l'UI
    setFilteredMicros((prevMicros) => {
      return prevMicros.map((mic) => {
        if (mic.serial === serial && mic.mictype === mictype) {
          return { ...mic, [field]: value };
        }
        return mic;
      });
    });
  };

  //REFRESH FILTER

  const refreshfilter = () => {
    setFilteredMicros(micros);
    setSelectedMicros("all");
    setUsersPerPage(10);
    setSearchTerm("");
  };

  //PAGINATION

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  const currentMicros = filteredMicros.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  // Juste après avoir calculé currentUsers
  const totalPages = Math.ceil(filteredMicros.length / usersPerPage);
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.containerUser}>
          <div
            className={styles.containerSearchAndFilter}
            id={styles.inputSearchUser}
          >
            <InputForms
              id={styles.inputSearchAudio}
              onChange={handleSearch}
              value={searchTerm}
              placeholder="Rechercher"
            />
            <select
              value={usersPerPage}
              onChange={(e) => {
                setUsersPerPage(e.target.value);
                setCurrentPage(1); // réinitialiser la page actuelle
              }}
            >
              {[5, 10, 20, 30, 50].map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
            </select>

            {/* Sélecteur de groupe */}
            <select
              value={selectedMicros}
              onChange={(e) => {
                setSelectedMicros(e.target.value);
                handleFilterChange();
              }}
            >
              <option value="all">Tout les micros</option>
              {MICRO_TYPES.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>

            <FiRefreshCcw
              onClick={refreshfilter}
              style={{ color: "#6d5dfc", fontSize: "30px", cursor: "pointer" }}
            />

            <IoMdAddCircle
              className={styles.iconAddNewMics}
              onClick={() => {
                setActiveForm("addNewMics");
                toggle();
              }}
              style={{ color: "#6d5dfc", fontSize: "50px", cursor: "pointer" }}
            />
          </div>
          <HeaderManageMics />

          {/* ********************************************************************************* */}
          <ul>
            {currentMicros.map((mic, index) => (
              <li
                key={index}
                className={styles.containerInfosMicro}
                style={{
                  backgroundColor: MICRO_COLORS[mic.mictype],
                }}
              >
                <span className={styles.serial}>{mic.serial}</span>

                <h3> {mic.mictype}</h3>

                <InputForms
                  id={styles.inputValueLeft}
                  onChange={(e) =>
                    handleInputChange(
                      mic.serial,
                      mic.mictype,
                      "correction_L",
                      e.target.value
                    )
                  }
                  value={mic.correction_L}
                />
                <InputForms
                  id={styles.inputValueRight}
                  onChange={(e) =>
                    handleInputChange(
                      mic.serial,
                      mic.mictype,
                      "correction_R",
                      e.target.value
                    )
                  }
                  value={mic.correction_R}
                />

                <BsCheckCircle
                  onClick={() => handleUpdate(mic.serial, mic.mictype)}
                  style={{
                    fontSize: "25px",
                    color: "#344e41",
                    cursor: "pointer",
                  }}
                />
              </li>
            ))}
          </ul>

          {/* //PAGINATION */}
          <div className={styles.containerPagination}>
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
            >
              Première
            </button>
            <button
              onClick={() => {
                if (currentPage > 1) setCurrentPage(currentPage - 1);
              }}
              disabled={currentPage === 1}
            >
              Précédent
            </button>
            <button
              onClick={() => {
                if (currentPage < pageNumbers.length)
                  setCurrentPage(currentPage + 1);
              }}
              disabled={currentPage === pageNumbers.length}
            >
              Suivant
            </button>

            <button
              onClick={() => setCurrentPage(pageNumbers.length)}
              disabled={currentPage === pageNumbers.length}
            >
              Dernière
            </button>
          </div>
          <div className={styles.numberPage}>
            Page {currentPage} sur {pageNumbers.length}
          </div>
        </div>
        <Modal isShowing={isShowing} hide={customToggle}>
          {activeForm === "addNewMics" && (
            <div className={styles.containerModal}>
              <InputForms
                id={styles.inputSearchAudio}
                onChange={(e) => setSerial(e.target.value)}
                placeholder="N° de serie"
              />
              <select
                value={micType}
                className={styles.selectMicro}
                onChange={(e) => setMicType(e.target.value)}
              >
                {MICRO_TYPES.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
              <InputForms
                id={styles.inputSearchAudio}
                onChange={(e) => setCorrectionL(e.target.value)}
                placeholder="Correction_L"
              />

              <InputForms
                id={styles.inputSearchAudio}
                onChange={(e) => setCorrectionR(e.target.value)}
                placeholder="Correction_R"
              />

              <Button
                onPress={addNewMics}
                text={"Enregistrer"}
                styles={"btnValidation"}
              />
            </div>
          )}
          {activeForm === "warningChangeValueMics" && (
            <div className={styles.containerModal}>
              <BsFillExclamationTriangleFill
                style={{ color: "red", fontSize: "50px" }}
              />
              <h2>Confirmer la mise à jour</h2>
              <p>Serial: {currentUpdate?.serial}</p>
              <p>MicroType: {currentUpdate?.mictype}</p>
              <p>Correction_L: {currentUpdate?.correction_L}</p>
              <p>Correction_R: {currentUpdate?.correction_R}</p>
              <Button
                onPress={confirmUpdate}
                text={"Confirmer"}
                styles={"btnValidation"}
              />
            </div>
          )}
        </Modal>
      </div>
      <div className={styles.container}>
        <UploadDataMics maj={maj} setMaj={setMaj} />
        <TutorialUploadDataMics />
      </div>
    </>
  );
};

export default ManageMics;
