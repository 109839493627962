import React, { useContext } from "react";
import styles from "./OwnerWithoutCab.module.scss";

import NavContext from "../../../context/NavContext";

import Button from "../../UiComponents/Button";
import InfosConnexion from "./InfosConnexion";

const OwnerWithoutCab = () => {
  const { ownerProfile, ownerId } = useContext(NavContext);

  return (
    <>
      <div className={styles.container}>
        <InfosConnexion
          userIsOwner={true}
          ownerName={ownerProfile.display_name}
          login={ownerProfile.user_login}
          email={ownerProfile.user_email}
          date={ownerProfile.user_registered}
          ownerId={ownerId}
        />

        <div className={styles.cabine}>
          <h1>Ce compte n'a pas encore de cabine</h1>
          <Button
            onPress={() => window.location.reload()}
            text={"Retour à la page superAdmin"}
            styles={"btnValidation"}
          />
        </div>
      </div>
    </>
  );
};

export default OwnerWithoutCab;
