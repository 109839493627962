export const emptyDataAngle = [
  {
    fname: "FMOD_Speaker_Mode",
    fval: "_7POINT1",
  },
  {
    fname: "FRONT_LEFT",
    fval: "0",
  },
  {
    fname: "FRONT_RIGHT",
    fval: "0",
    
  },
  {
    fname: "FRONT_CENTER",
    fval: "0",
  },
  {
    fname: "SURROUND_LEFT",
    fval: "0",
  },
  {
    fname: "SURROUND_RIGHT",
    fval: "0",
  },
  {
    fname: "BACK_LEFT",
    fval: "0",
  },
  {
    fname: "BACK_RIGHT",
    fval: "0",
  },
];
