import React from "react";
import styles from "../UserAccountLinker.module.scss";
const AlreadyLinked = ({ senderOwner, emailUser }) => {
  return (
    <div className={styles.layout}>
      <h2>Vote compte est déjà lié à  {senderOwner}</h2>
      <div style={{ textAlign: "center", marginTop: "50px" }}>
        Vous avez suivi un lien pour lier votre compte à celui de 
        <span> {senderOwner}</span> mais votre compte est déjà lié à ce compte.
    </div>
    </div>
  );
};

export default AlreadyLinked;
