import React, { useContext, useEffect, useState } from "react";
import Axios from "../../_services/caller.service";
import axios from "axios";
//STYLES
import stylesCalib from "./ManageCertificat.module.scss";
//IMG
import microears from "../../pics/ears.png";
import umik1 from "../../pics/umik1.png";
//ANIMATION
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
//COMPONENT
import HeaderArrayCertificat from "../calibration/certificatDownload/HeaderArrayCertifcat";
import OneCertificat from "./OneCertificat";
//HOOK
import useModal from "../../hooks/useModal";
import LoaderLogin from "../UiComponents/loader/LoaderLogin";
import { toast } from "react-toastify";
import NavContext from "../../context/NavContext";
import ManageLateCalibration from "../calibration/manageLateCalibration/ManageLateCalibration";
import Button from "../UiComponents/Button";
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";

const ManageCertificat = () => {
  const token = localStorage.getItem("jwt");

  //DATA
  const [allCabines, setAllCabines] = useState([]);
  const [cabineWithCalib, setCabineWithCalib] = useState([]);
  const [users, setUsers] = useState([]);
  const [userToSendEmail, setUserToSendEmail] = useState({});

  //SEARCH CABINE STATE
  const [searchCabCertif, setSearchCabCertif] = useState("");
  const [radioNameCertif, setRadioNameCertif] = useState(false);

  //LOADING
  const [loadingAll, setLoadingAll] = useState(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingCalibration, setLoadingCalibration] = useState(true);
  const [loadingCabines, setLoadingCabines] = useState(true);
  const [loadingDataCalib, setLoadingDataCalib] = useState(true);

  //CONTEXT
  const {
    ownerId,
    realOwnerId,
    userAuth,
    superAdmin,
    ownerProfile,
    adminProfile,
  } = useContext(NavContext);
  //GESTION affichage du nombre de certificat
  const [visibleCount, setVisibleCount] = useState(3);
  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  const handleShowAll = () => {
    setVisibleCount(filteredCertificat.length);
  };
  const handleShowLess = () => {
    // Ajouté
    setVisibleCount(53);
  };

  //VERFY IF CABINE HAVE CALIBRATION DATA
  const verifyIfDataCalibIsInCabine = async (cabine) => {
    const cabineWithCalibrationData = [];

    const responses = await Promise.all(
      allCabines.map(async (cab) => {
        const res = await axios
          .get(
            `${process.env.REACT_APP_API_URL}api/calibrationGeneral/${cab.id}`,
            {
              headers: { authorization: "Bearer " + token },
            }
          )

          .then(async (response) => {
            setLoadingCalibration(false);
            if (response.data.message === "aucune donnée") {
            } else {
              cabineWithCalibrationData.push(cab.id);
              //setCalibGeneral(response.data.data);
            }
          })

          .catch(function (error) {
            console.log(error);
          });
      })
    );

    setCabineWithCalib(cabineWithCalibrationData);

    return cabineWithCalibrationData;
  };

  //GET ALL CABINE AND HANDLE THIS
  useEffect(() => {
    Axios.get(`/allCabines/`)

      .then(async function (response) {
        const cabineID = response.data.data;
        const allCabid = cabineID.map((cab) => cab.id.toString());
        const dataCab = response.data.data;

        const filledArray = await fillArrayAsync(allCabid); //récupères les données de date de calibration des cabines
        const finalArrayForDay = filledArray.filter((day) => day.length !== 0); // filter result null

        const mergedArray = dataCab.concat(finalArrayForDay); // fusion des deux tableaux d'objets

        // retournes les objets fusionner par leur id
        let mergedObjects = mergedArray.reduce((acc, obj) => {
          let id = obj.id;
          if (!acc[id]) {
            acc[id] = {};
          }
          for (let key in obj) {
            acc[id][key] = obj[key];
          }
          return acc;
        }, {});
        // remet sous forme de tableaux
        let result = [];
        for (let key in mergedObjects) {
          result.push(mergedObjects[key]);
        }
        setLoadingCabines(false);
        setAllCabines(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function fillArrayAsync(array) {
    let filledArray = [];

    // Créez un tableau de promesses en exécutant une promesse pour chaque élément du tableau
    const promises = array.map(async (element) => {
      // Exécutez une tâche asynchrone ici (par exemple, un appel à une API)
      const result = await Axios.get(
        `/dateOfLastCalibrationAndNumberDays/${element}`
      )
        .then(function (response) {
          const dataDays = response.data.data;
          return dataDays;
        })
        .catch(function (error) {
          console.log(error);
        });

      return result;
    });

    // Attendre que toutes les promesses soient terminées avant de continuer
    filledArray = await Promise.all(promises);
    return filledArray;
  }

  // GET ALL USERS
  useEffect(() => {
    Axios.get("/getAllUserForSuperAdmin")
      .then((response) => {
        setLoadingUser(false);
        setUsers(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //DISPLAY ONLY CALIBRATION AVAILABLE CABINE
  useEffect(() => {
    if (allCabines && allCabines.length > 0) {
      verifyIfDataCalibIsInCabine(allCabines);
      setLoadingDataCalib(false);
    }
  }, [allCabines]);

  //STATE CABID PAR CABID
  const handleSetUserToSendEmail = (email, cabId) => {
    setUserToSendEmail((prevState) => ({
      ...prevState,
      [cabId]: email,
    }));
  };

  // Fonction pour réinitialiser le state pour un cabId spécifique
  const handleResetUserToSendEmail = (cabId) => {
    setUserToSendEmail((prevState) => {
      const newState = { ...prevState };
      delete newState[cabId]; // Supprimer l'entrée pour ce cabId
      return newState;
    });
  };

  //FONCTION ENVOIE PDF PAR MAIL
  const sendCertifByMail = (cabId) => {
    const emailToSend = userToSendEmail[cabId];

    // ICI ON ENVOIE UN EMAIL AU USER ET NON PAS GENERER UN PDF MAIS LA ROUTE PEUX GERER LES DEUX
    Axios.post(`/generatePdf/${cabId}`, {
      sendByMail: true,
      emailToSend: emailToSend,
    })

      .then(function async(response) {
        toast.success("email envoyée avec succés");

        handleResetUserToSendEmail(cabId);
      })
      .catch(function (error) {
        toast.error("une erreur est survenue lors de l'envoie du mail ");
        console.log(error);
      });
  };

  //FONCTION POUR FILTRER LES CABINES
  const filteredCertificat = allCabines
    .filter((cab) => cabineWithCalib.includes(cab.id)) // Filtre les cabines basé sur l'inclusion dans cabineWithCalib
    .filter((cab) => cab.cabname.toLowerCase().includes(searchCabCertif)) // Filtre supplémentaire basé sur le nom de la cabine
    .sort((a, b) => {
      // Tri basé sur le nom de la cabine
      if (radioNameCertif) {
        return b.cabname.localeCompare(a.cabname);
      } else {
        return a.cabname.localeCompare(b.cabname);
      }
    });

  // LOADING
  useEffect(() => {
    if (
      !loadingUser &&
      !loadingCalibration &&
      !loadingCabines &&
      !loadingDataCalib
    ) {
      setLoadingAll(false); // Tout est chargé
    }
  }, [loadingUser, loadingCalibration, loadingCabines, loadingDataCalib]);
  return (
    <>
      {loadingAll && <LoaderLogin />}
      <div className={stylesCalib.title}>
        <CardHeaderPage
          text={"Envoie des certificats de calibration par mail"}
          backgroundColor={"#ceeaf7"}
          textAlign={'center'}
          width={'300px'}
        />
      </div>
      <motion.div
        className={stylesCalib.containerPageCalibration}
        variants={animatePage}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <img className={stylesCalib.umik} src={umik1} alt="" />
        <img src={microears} alt="" className={stylesCalib.ears} />
        <div className={stylesCalib.containerCalibration}>
          <HeaderArrayCertificat
            setSearchCabCertif={setSearchCabCertif}
            searchCabCertif={searchCabCertif}
          />

          {allCabines.length > 0 &&
            cabineWithCalib.length >= 1 &&
            filteredCertificat.slice(0, visibleCount).map((cab) => {
              return (
                <OneCertificat
                  key={cab.id}
                  cab={cab}
                  cabine={allCabines}
                  cabname={cab.cabname}
                  sendCertifByMail={() => sendCertifByMail(cab.id)}
                  users={users}
                  email={userToSendEmail[cab.id]}
                  setUserToSendEmail={(email) =>
                    handleSetUserToSendEmail(email, cab.id)
                  }
                />
              );
            })}
            <div className={stylesCalib.buttonDisplayCertificat}> 
          {visibleCount < filteredCertificat.length && (
            <Button
              onPress={handleLoadMore}
              text={"Afficher plus"}
              styles={"btnPrimary"}
            />
          )}
          {visibleCount < filteredCertificat.length && (
            <Button
              onPress={handleShowAll}
              text={"Afficher Tout"}
              styles={"btnPrimary"}
            />
          )}
          {visibleCount >= filteredCertificat.length && ( // Ajouté
            <Button
              onPress={handleShowLess}
              text={"Afficher moins"}
              styles={"btnPrimary"}
            />
          )}
          </div>
        </div>
        {/* {superAdmin && <ManageLateCalibration />} */}
      </motion.div>
    </>
  );
};

export default ManageCertificat;
