import React, { useState } from "react";
import styles from "./HeaderManageMics.module.scss";
import { MdInfoOutline } from "react-icons/md";
import { CgChevronUpO, CgChevronDownO } from "react-icons/cg";

const HeaderManageMics = () => {
  return (
    <>
      <div className={styles.container}>

        <h3 className={styles.serials}>Serials</h3>

        <h3 className={styles.type}>Type</h3>

        <h3 className={styles.correctionL}>Correction Left</h3>

        <h3 className={styles.correctionR}>Correction Right</h3>

        <h3 className={styles.register}>Enregistrer</h3>

      </div>
    </>
  );
};

export default HeaderManageMics;
