import React, { useState } from "react";
import Button from "../../UiComponents/Button";
import { AiFillSetting } from "react-icons/ai";
import { IoIosArrowDropup, IoIosArrowDropdown } from "react-icons/io";
import DeleteUser from "./DeleteUser";
import styles from "./UserSettings.module.scss";
import useClickOutside from "../../../CustomHooks/ClickOutside";

const UserSettings = ({
  setDisplaySettings,
  displaySettings,

}) => {
  const displayCabByCabAuth = () => {
    setDisplaySettings(!displaySettings);
  };
  return (
    <div>
      {displaySettings ? (
        <IoIosArrowDropup
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={displayCabByCabAuth}
          className={styles.iconDropDown}
        />
      ) : (
        <IoIosArrowDropdown
          style={{ fontSize: "30px", cursor: "pointer" }}
          onClick={displayCabByCabAuth}
          className={styles.iconDropDown}
        />
      )}

    </div>
  );
};

export default UserSettings;
