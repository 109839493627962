import React, { useState } from "react";

import InputForms from "../../UiComponents/forms/InputForms";

import styles from "./SearchUser.module.scss";
const SearchCabines = ({
  searchCabines,
  setSearchCabines,
 allCabines,
  infosCabId,
changeCabIdWithSelect,
  isSearchingCab,
  setIsSearchingCab,
}) => {
  const filteredCabines = allCabines.filter(
    (c) =>
      c.cabname.toLowerCase().includes(searchCabines.toLowerCase()) ||
      c.id.toString().includes(searchCabines)
  );

  return (
    <div className={styles.containerUser}>
      <InputForms
        value={searchCabines}
        onChange={(e) => {
       
          setSearchCabines(e.target.value.toLowerCase());
          setIsSearchingCab(true);
        }}
        placeholder={"Cabines"}
      />

      <select
        value={infosCabId}
        onChange={changeCabIdWithSelect}
        size={isSearchingCab ? 10 : 0}
        onBlur={() => setIsSearchingCab(false)}
      >
        <option value="">-- Sélectionnez une cabine --</option>
        {filteredCabines.length === 0 && (
          <option value="" disabled>
            -- Aucune option disponible --
          </option>
        )}
        {allCabines &&
          filteredCabines

            .sort((a, b) => a.cabname.localeCompare(b.cabname))

            .map((c) => {
              return (
                <option key={c.id} value={c.id}>
                  {c.cabname} ({c.id})
                </option>
              );
            })}
      </select>
    </div>
  );
};

export default SearchCabines;
