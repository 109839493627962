// config.js

export const MICRO_TYPES = ['Umik1', 'EARS', 'Audyx', 'Umik2'];


export const MICRO_COLORS = {
    Umik1: '#ebebeb',  
    EARS: '#edede9',  
    Audyx: '#dad7cd',  
    Umik2: '#d5bdaf'  
  };


