import React from "react";

const SpeakersSvg = ({ value }) => {
  const degToRadian = (deg) => {
    return deg * (Math.PI / 180);
  };

  return (
    <div>
      {value.map((deg, index) => {
        const radValue = degToRadian(deg.angle);
        const x = Math.cos(radValue) * 75;
        const y = -Math.sin(radValue) * 75;

        const rotateSpeakers = -90 + deg.angle;
        return (
          <div
            key={index}
            style={{
              position: "absolute",
              left : isNaN(y) ? 0 : y + 55,
              top : isNaN(x) ? 0 : x + 54,
              

              transform: `rotate(${rotateSpeakers}deg)`,
            }}
          >
            <svg
              version="1"
              xmlns="http://www.w3.org/2000/svg"
              height="40px"
              width="40px"
              viewBox="0 0 48 48"
             // enable-background="new 0 0 48 48"
            >
              <path
                style={{ fill: `${deg.color}` }}
                d="M14,32H7c-1.1,0-2-0.9-2-2V18c0-1.1,0.9-2,2-2h7V32z"
              />
              <polygon
                style={{ fill: `${deg.color}` }}
                points="26,42 14,32 14,16 26,6"
              />
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default SpeakersSvg;
