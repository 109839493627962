import { createContext } from "react";

const NavContext = createContext({
  nav: false, // nav bar context
  setNav: () => {},
  user: null, // user simple
  setUser: () => {},
  userGroup: null, // user group context
  setUserGroup: () => {},
  ownerId: null, // keep the user's Id
  setOwnerId: () => {},
  realOwnerId: null, // First connection id superAdmin
  setRealOwnerId: () => {},
  realOwnerProfile: null, // first connexion profile superadmin
  setRealOwnerProfile: () => {},

  loginUser: () => {}, // function login
  errorLogin: "", // state for error backend
  setErrorLogin: () => {},
  loaderLogin: "",
  setLoaderLogin: () => {}, // state for loader login
  userAuth: false,
  setUserAuth: () => {}, // state for status User ( auth ? or not)
  loading: true, // loading before to come back home or stay on page (if user refresh page)
  setLoading: () => {},
  ownerProfile: "",
  setOwnerProfile: () => {},
  adminProfile: "",
  setAdminProfile: () => {},
  superAdmin: false,
  setSuperAdmin: () => {},
});
export default NavContext;
