import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import styles from "./ModalConfirmNewPreset.module.scss";
import Button from "../../../UiComponents/Button";
import Success from "../../../UiComponents/sucess/Success";

const ModalConfirmNewPreset = ({
  setOverlay,
success,
  setModalConfirmRegisterNewPreset,
  registerNewValue,
}) => {
  return (
    <div className={styles.modalConfirm}>
      <AiOutlineClose
        className={styles.closeBtnWarningOutpout}
        onClick={() => {
          setOverlay(false);
          setModalConfirmRegisterNewPreset(false);
        }}
      />
      <h3>Souhaitez-vous enregistrer les nouveaux presets ?</h3>
 
      <div className={styles.btnConfirmModal}>
        <Button
          onPress={() => {
            setModalConfirmRegisterNewPreset(false);
            setOverlay(false);
          }}
          styles={"btnDelete"}
          text={"Revenir au données précédente"}
        />
        <Button
          onPress={registerNewValue}
          styles={"btnValidation"}
          text={"j'enregistre"}
        />
      </div>
      {success && <Success success={"enregistrement ok"} />}
    </div>
  );
};

export default ModalConfirmNewPreset;
