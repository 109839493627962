import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
//import { UserContext } from "../../context/UserContext";
import NavContext from "../../context/NavContext";
import Error from "../UiComponents/error/Error";
import LoaderLogin from "../UiComponents/loader/LoaderLogin";
import { FaChevronRight, FaUserAlt } from "react-icons/fa";
import {
  AiFillLock,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from "react-icons/ai";
import ForgotPassword from "./ForgotPassword";
import ContainerForm from "../UiComponents/forms/ContainerForm";
import InputForms from "../UiComponents/forms/InputForms";
import BtnForms from "../UiComponents/forms/BtnForms";
import InputPassword from "../UiComponents/forms/InputPassword";
import { RiFontSize } from "react-icons/ri";
import styles from "./SignInModal.module.scss";
import Radio from "../UiComponents/neumorphism/Radio";

export default function SignInModal({ setRememberMe }) {
  //CONTEXT
  const { loginUser, errorLogin, loaderLogin } = useContext(NavContext);
  //FORMS
  const [userMail, setUserMail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  //DISPLAY

  const [showForgot, setShowForgot] = useState(false);

  axios.defaults.withCredentials = true;

  const submitForms = async (e) => {
    e.preventDefault();
    await loginUser(userMail, userPassword);
  };

  const rememberMe = (e) => {
    const check = e.target.checked;

    setRememberMe(check);
  };

  return (
    <>
      <div>
        {!showForgot && (
          <ContainerForm
            onSubmit={submitForms}
            modal={false}
            children={
              <>
                <div id={styles.containerInputUser}>
                  <InputForms
                    type={"text"}
                    placeholder={"User name/ Email"}
                    onChange={(e) => setUserMail(e.target.value)}
                    required={true}
                    svg={<FaUserAlt />}
                  />
                </div>
                <div id={styles.containerInputPassword}>
                  <InputPassword
                    placeholder={"Mot de passe"}
                    onChange={(e) => setUserPassword(e.target.value)}
                  />
                </div>
                {/* <div className={styles.remember}>
                  <h3>Rester connecté</h3>
                  <Radio
                    onChange={rememberMe}
                    id={"remember"}
                    htmlFor={"remember"}
                  />
                </div> */}
                <BtnForms id={styles.btnConnexion} text="Connexion" />
                {loaderLogin && <LoaderLogin />}
                <div
                  className={styles.forgot}
                  onClick={() => setShowForgot(true)}
                >
                  Mot de passe oublié ?
                </div>{" "}
              </>
            }
          />
        )}
      </div>

      {/* FORGOT PASSWORD */}

      {showForgot && (
        <ForgotPassword showForgot={showForgot} setShowForgot={setShowForgot} />
      )}
    </>
  );
}
