import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import Select from "react-select";
import styles from "./GlobalSelectMetrics.module.scss";
//FUNCTION
import metricsConfig from "../metricsConfig";
import {
  calculateAverages,
  transformComparisonData,
  transformDataMonthlyOrYearly,
} from "../functionUtilsStats";

export default function GlobalSelectMetrics({ dataForChart,timeInterval,setTimeInterval,selectedMetrics,setSelectedMetrics }) {


  const [includeSaturday, setIncludeSaturday] = useState(false);
  const [transformedData, setTransformedData] = useState([]);

  const metricOptions = Object.keys(metricsConfig).map((key) => ({
    value: key,
    label: metricsConfig[key].title,
  }));

  const timeIntervalOptions = [
    { value: "daily", label: "Jour par jour" },
    { value: "weekly", label: "Semaine par semaine" },
    { value: "monthly", label: "Mois par mois" },
    { value: "yearly", label: "Année par année" },
  ];

  const handleMetricsChange = (selectedOptions) => {
    setSelectedMetrics(selectedOptions || []);
  };

  const handleTimeIntervalChange = (selectedOption) => {
    setTimeInterval(selectedOption.value);
  };

  const handleSaturdayChange = (e) => {
    setIncludeSaturday(e.target.checked);
  };

  useEffect(() => {
    const transformed = transformDataMonthlyOrYearly(
      dataForChart,
      timeInterval,
      includeSaturday
    );
    setTransformedData(transformed);
  }, [dataForChart, timeInterval, includeSaturday]);

  const renderLines = () => {
    return selectedMetrics.map(({ value }) => {
      if (metricsConfig[value]) {
        const { color, title } = metricsConfig[value];
        return (
          <Line
            key={value}
            type="monotone"
            dataKey={value}
            stroke={color}
            name={title}
          />
        );
      }
      return null;
    });
  };

  const averages = calculateAverages(transformedData, selectedMetrics);

  const legendText = selectedMetrics
    .map(
      ({ value }) =>
        `${metricsConfig[value].title}: Moyenne = ${averages[value].toFixed(1)}`
    )
    .join(", ");

  return (
    <div className={styles.container}>
      <div className={styles.containerSelect}>
        <Select
          isMulti
          options={metricOptions}
          value={selectedMetrics}
          onChange={handleMetricsChange}
          placeholder="Sélectionnez les métriques..."
        />
        <Select
          options={timeIntervalOptions}
          value={timeIntervalOptions.find(
            (option) => option.value === timeInterval
          )}
          onChange={handleTimeIntervalChange}
          placeholder="Sélectionnez l'intervalle de temps..."
        />
        {timeInterval === "daily" && (
          <label>
            <input
              type="checkbox"
              checked={includeSaturday}
              onChange={handleSaturdayChange}
            />{" "}
            Inclure les samedis
          </label>
        )}
      </div>
      <ResponsiveContainer width="100%" height={600}>
        <LineChart data={transformedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />

          {renderLines()}
          {selectedMetrics.map(({ value }) => (
            <ReferenceLine
              key={`avg-${value}`}
              y={averages[value]}
              label={`Moyenne: ${averages[value].toFixed(2)}`}
              stroke="red"
            />
          ))}
          <Legend
            verticalAlign="top"
            wrapperStyle={{ top: 35, right: 20, position: "absolute", fontWeight:'500'}}
            content={() => (
              <div style={{ textAlign: "right" }}>{legendText}</div>
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
