import { createContext, useContext, useMemo, useState, useEffect } from "react";
import axios from "axios";

import NavContext from "../context/NavContext";

import Loader from "../components/UiComponents/loader/Loader";
import { useUserInvite, useUserInviteId } from "../hooks/useUserInvite";
import Axios from "../_services/caller.service";
import LoaderLogin from "../components/UiComponents/loader/LoaderLogin";

const UserInviteContext = createContext(null);

export const useUserInviteContext = () => {
  const userInvite = useContext(UserInviteContext);

  return userInvite;
};

export const UserInviteProvider = ({ children }) => {
  const token = localStorage.getItem("jwt");
  const { userAuth, ownerId } = useContext(NavContext);
  const [userLinkedId, setUserLinkedId] = useState([]); // all user linked to owner

  const [userInvite, setUserInvite] = useState([]); // all user linked to owner
  const [maj, setMaj] = useState(0); // maj components delete

  // get Invite user's
  useEffect(() => {
    if (userAuth) {
      Axios.get(`/getUserInfos/${ownerId}`)

        .then((response) => {
          const users = response.data.data;
          if (users) {
            setUserInvite(response.data.data);
          }

          if (response.data.message === "pas d'invité") {
            setUserInvite("pas dinvité");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userAuth, maj]);

  // FOR ONLY ID
  const inviteId = useUserInviteId(); // id of user invite in hooks folder

  return userInvite ? (
    <UserInviteContext.Provider value={{ userInvite, inviteId, maj, setMaj }}>
      {children}
    </UserInviteContext.Provider>
  ) : (
    // <></>
    <LoaderLogin />
  );
};
