import React from 'react';

import styles from '../UserAccountLinker.module.scss';
const JwtError = () => {
    return (
        <div className={styles.errorToken}>
        <h2>Impossible de trouver l'invitation</h2>
        <p>
          Si vous êtes arrivé ici en cliquant sur le lien qui vous a été
          envoyé par e-mail, il est fort probable que l'invitation ait déjà
          expiré. Veuillez contacter la personne qui vous a envoyé
          l'invitation pour demander une autre invitation.
        </p>
  
      </div>
    );
};

export default JwtError;