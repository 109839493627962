import React, { useEffect, useRef, useState } from "react";
import styles from "./InputForms.module.scss";

const InputForms = ({
  type,
  placeholder,
  onChange,
  required,
  defaultValue,
  svg,
  value,
  id,
  paddingLeft
}) => {



  const [inputFocus, setInputFocus] = useState(false);

  const handleInputFocus = () => {
    setInputFocus(true);
  };

  const handleInputBlur = () => {
    setInputFocus(false);
  };


 



  return (
    <>
      <div className={styles.search}>
        <input
          id={id}
          type={type}
          className={styles.searchInput}
          placeholder={inputFocus ? '' : placeholder}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          value={value}
          required={required}
          defaultValue={defaultValue}
          style={{paddingLeft:paddingLeft}}
        />
        <div className={styles.search__icon}>{svg}</div>
      </div>
    </>
  );
};

export default InputForms;
