import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import VRGuide from "../components/SupportHS/VRGuide/VRGuide";
import ContactHelp from "../components/SupportHS/contact/ContactHelp";
import CalibrationGuide from "../components/SupportHS/calibrationGuide/CalibrationGuide";
import HSGuide from "../components/SupportHS/HSGuide/HSGuide";
import MessageError from "../components/SupportHS/MessageError/MessageError";
import Dirac from "../components/SupportHS/dirac/Dirac";

const SupportHSRouter = ({ setRememberMe, isLoggedIn }) => {
  return (
    <Routes>
      <Route path="/" element={<MessageError />} />
      <Route path="/vrGuide" element={<VRGuide />} />
      <Route path="/contactHelp" element={<ContactHelp />} />

      <Route path="/calibrationGuide" element={<CalibrationGuide />} />
      <Route path="/dirac" element={<Dirac />} />

      <Route path="/hsGuide" element={<HSGuide />} />
    </Routes>
  );
};

export default SupportHSRouter;
