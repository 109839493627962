import React, { useContext, useEffect, useRef, useState } from "react";

import axios from "axios";
import { decodedB64 } from "../../../CustomHooks/DecodedBase64";
import OneSoundCard from "./OneSoundCard";
import { displayCalibValue } from "../../calibration/functionDataSorted";
import styles from "./SoundCard.module.scss";
//IMG
import coImg from "../../../pics/ConductionOsseusePdf.png";
import operatorImg from "../../../pics/Operator.png";
import casqueImg from "../../../pics/casquePdf.png";
import insertImg from "../../../pics/InsertPdf.png";
import speakerImg from "../../../pics/speakersPdf.png";
import dirac from "../../../pics/dirac.svg";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import soundCardImg from "../../../pics/dragonFly.png";
import Button from "../../UiComponents/Button";
import NavContext from "../../../context/NavContext";
import Axios from "../../../_services/caller.service";
import ModalSoundCard from "./ModalSoundCard";
import LoaderLogin from "../../UiComponents/loader/LoaderLogin";

const SoundCard = ({ cabId, setCabId, cabname }) => {
  const token = localStorage.getItem("jwt");
  //CONTEXT
  const { ownerId, userAuth, majCabine, setMajCabine } = useContext(NavContext);
  //GET SOUND CARD
  const [soundCard, setSoundCard] = useState([
    { guid: "Tm9uZQ==", speaker: "Tm9uZQ==" },
  ]);

  // UPDATE SOUND CARD
  const [updateSoundCard, setUpdateSoundCard] = useState({
    CO: "",
    Casque: "",
    Inserts: "",
    HSSoundcardUse: "",
    DiracSoundcardUse: "",
    Operator: "",
  });
  //TRANSDUCTOR TYPE
  const [allTransductor, setAllTransdutor] = useState([]);
  const [transductorNameForUpdate, setTransductorNameForUpdate] = useState("");
  //CALIBRATION
  const [calibGeneral, setCalibGeneral] = useState([]);
  const [calibTonal, setCalibTonal] = useState([]);
  const [calibVocal, setCalibVocal] = useState([]);

  //DISPLAY
  const [maj, setMaj] = useState(false);
  const [displaySoundCard, setDisplaySoundCard] = useState(false);
  const [displayModalConfirm, setDisplayModalConfirm] = useState(false);
  
  //Loading
  const [loadingAllSoundcard, setLoadingAllSoundcard] = useState(true);
  const [loadingcalibGen, setLoadingCalibGen] = useState(true);
  const [loadingcalibVoc, setLoadingCalibVoc] = useState(true);
  const [loadingcalibTon, setLoadingCalibTon] = useState(true);
  const [loadingAllData, setLoadingAllData] = useState(true);



  //CONTROLLER
  const controller = new AbortController();
  const signal = controller.signal;

  const { output, categoriesName } = useContext(NavContext); // output and categories constant

  // get all Sound Card
  useEffect(() => {
    if (cabId !== "") {
      Axios.get(`/allSoundCard/${cabId}`, { signal })

        .then(async (response) => {
          setLoadingAllSoundcard(false)
          const cardName = response.data.data;
          if (cardName !== undefined) {
            const none = { guid: "Tm9uZQ==", speaker: "Tm9uZQ==" }; // 'None' en B64
            cardName.unshift(none);

            setSoundCard(cardName);
          } else {
            setSoundCard([{ guid: "Tm9uZQ==", speaker: "Tm9uZQ==" }]);
          }
        })

        .catch(function (error) {
          console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, setCabId, maj, setMaj]);

  // get all data calibration
  useEffect(() => {
    if (cabId !== "") {
      Axios.get(`/calibrationGeneral/${cabId}`, { signal })

        .then(async (response) => {
          setLoadingCalibGen(false)
          if (response.data.message === "aucune donnée") {
            setCalibGeneral([]);
          } else {
            setCalibGeneral(response.data.data);
          }
        })

        .catch(function (error) {
          console.log(error);
        });
      Axios.get(`calibrationTonal/${cabId}`)

        .then(async (response) => {
          setLoadingCalibTon(false)
          if (response.data.message === "aucune donnée") {
            setCalibTonal([]);
          } else {
            setCalibTonal(response.data.data);
          }
        })

        .catch(function (error) {
          console.log(error);
        });
      Axios.get(`/calibrationVocal/${cabId}`, { signal })

        .then(async (response) => {
          setLoadingCalibVoc(false)
          if (response.data.message === "aucune donnée") {
            setCalibVocal([]);
          } else {
            setCalibVocal(response.data.data);
          }
        })

        .catch(function (error) {
          console.log(error);
        });
      return () => {
        controller.abort();
      };
    }
  }, [cabId, setCabId, maj, setMaj, token]);

  // AWAIT CALIBDATA AND VERIFY IF CALIB PASSED OR NOT
  useEffect(() => {
 
    if (
      calibGeneral.length >= 1 &&
      calibTonal.length >= 1 &&
      calibVocal.length >= 1
    ) {
      //Check if calibration type is available for display PASSED OR NOT PASSED
      const speakersGen = displayCalibValue(calibGeneral, "Speakers_Value");
      const speakersTonal = displayCalibValue(calibTonal, "Speakers_Value");
      const speakersVocal = displayCalibValue(calibVocal, "Speakers_Value");
      const speakersDateGen = displayCalibValue(
        calibGeneral,
        "Speakers_Passed"
      );
      const speakersDateTonal = displayCalibValue(
        calibTonal,
        "Speakers_Passed"
      );
      const speakersDateVocal = displayCalibValue(
        calibVocal,
        "Speakers_Passed"
      );

      const headPhonesGen = displayCalibValue(calibGeneral, "Headphones_Value");
      const headPhonesTonal = displayCalibValue(calibTonal, "Headphones_Value");
      const headPhonesVocal = displayCalibValue(calibVocal, "Headphones_Value");
      const headPhonesDateGen = displayCalibValue(
        calibGeneral,
        "Headphones_Passed"
      );
      const headPhonesDateTonal = displayCalibValue(
        calibTonal,
        "Headphones_Passed"
      );
      const headPhonesDateVocal = displayCalibValue(
        calibVocal,
        "Headphones_Passed"
      );

      const insertsGen = displayCalibValue(calibGeneral, "Inserts_Value");
      const insertsTonal = displayCalibValue(calibTonal, "Inserts_Value");
      const insertsVocal = displayCalibValue(calibVocal, "Inserts_Value");
      const insertsDateGen = displayCalibValue(calibGeneral, "Inserts_Passed");
      const insertsDateTonal = displayCalibValue(calibTonal, "Inserts_Passed");
      const insertsDateVocal = displayCalibValue(calibVocal, "Inserts_Passed");

      const boneGen = displayCalibValue(calibGeneral, "Bone_Value");
      const boneTonal = displayCalibValue(calibTonal, "Bone_Value");
      const boneVocal = displayCalibValue(calibVocal, "Bone_Value");
      const boneDateGen = displayCalibValue(calibGeneral, "Bone_Passed");
      const boneDateTonal = displayCalibValue(calibTonal, "Bone_Passed");
      const boneDateVocal = displayCalibValue(calibVocal, "Bone_Passed");

      const operatorGen = displayCalibValue(calibGeneral, "Operator_Value");
      const operatorTonal = displayCalibValue(calibTonal, "Operator_Value");
      const operatorVocal = displayCalibValue(calibVocal, "Operator_Value");
      const operatorDateGen = displayCalibValue(
        calibGeneral,
        "Operator_Passed"
      );
      const operatorDateTonal = displayCalibValue(
        calibTonal,
        "Operator_Passed"
      );
      const operatorDateVocal = displayCalibValue(
        calibVocal,
        "Operator_Passed"
      );

      // NAME
      const boneSoundCardName = displayCalibValue(
        calibGeneral,
        "Bone_SoundcardName"
      );

      const operatorSoundCardName = displayCalibValue(
        calibGeneral,
        "Operator_SoundcardName"
      );

      const headphoneSoundCardName = displayCalibValue(
        calibGeneral,
        "Headphones_SoundcardName"
      );
      const insertSoundCardName = displayCalibValue(
        calibGeneral,
        "Inserts_SoundcardName"
      );
      const speakerSoundCardName = displayCalibValue(
        calibGeneral,
        "Speakers_SoundcardName"
      );

      const diracSoundCardName = displayCalibValue(
        calibGeneral,
        "Speakers_DiracSoundcardName"
      );

      // // INITIAL VALUE OF SOUND CARD
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        CO:
          boneSoundCardName[0] !== undefined
            ? decodedB64(boneSoundCardName[0])
            : "",
      }));
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        Casque:
          headphoneSoundCardName[0] !== undefined
            ? decodedB64(headphoneSoundCardName[0])
            : "",
      }));
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        Inserts:
          insertSoundCardName[0] !== undefined
            ? decodedB64(insertSoundCardName[0])
            : "",
      }));
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        HSSoundcardUse:
          speakerSoundCardName[0] !== undefined
            ? decodedB64(speakerSoundCardName[0])
            : "",
      }));
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        DiracSoundcardUse:
          diracSoundCardName[0] !== undefined
            ? decodedB64(diracSoundCardName[0])
            : "",
      }));
      setUpdateSoundCard((prevState) => ({
        ...prevState,
        Operator:
          operatorSoundCardName[0] !== undefined
            ? decodedB64(operatorSoundCardName[0])
            : "",
      }));

      //GUID
      const boneSoundCardGuid = displayCalibValue(
        calibGeneral,
        "Bone_Soundcard"
      );
      const operatorSoundCardGuid = displayCalibValue(
        calibGeneral,
        "Operator_Soundcard"
      );

      const headphoneSoundCardGuid = displayCalibValue(
        calibGeneral,
        "Headphones_Soundcard"
      );
      const insertSoundCardGuid = displayCalibValue(
        calibGeneral,
        "Inserts_Soundcard"
      );
      const speakerSoundCardGuid = displayCalibValue(
        calibGeneral,
        "Speakers_Soundcard"
      );
      const diracSoundCardGuid = displayCalibValue(
        calibGeneral,
        "Speakers_DiracSoundcard"
      );

      const transductor = [
        {
          name: "DiracSoundcardUse",
          img: dirac,
          gen: 0,
          tonal: 0,
          vocal: 0,
          dateGen: 0,
          dateTonal: 0,
          dateVocal: 0,
          soundCardName: diracSoundCardName[0],
          soundCardGuid: diracSoundCardGuid[0],
        },

        {
          name: "HSSoundcardUse",
          img: speakerImg,
          gen: speakersGen,
          tonal: speakersTonal,
          vocal: speakersVocal,
          dateGen: speakersDateGen,
          dateTonal: speakersDateTonal,
          dateVocal: speakersDateVocal,
          soundCardName: speakerSoundCardName[0],
          soundCardGuid: speakerSoundCardGuid[0],
        },
        {
          name: "Casque",
          img: casqueImg,
          gen: headPhonesGen,
          tonal: headPhonesTonal,
          vocal: headPhonesVocal,
          dateGen: headPhonesDateGen,
          dateTonal: headPhonesDateTonal,
          dateVocal: headPhonesDateVocal,
          soundCardName: headphoneSoundCardName[0],
          soundCardGuid: headphoneSoundCardGuid[0],
        },

        {
          name: "Inserts",
          img: insertImg,
          gen: insertsGen,
          tonal: insertsTonal,
          vocal: insertsVocal,
          dateGen: insertsDateGen,
          dateTonal: insertsDateTonal,
          dateVocal: insertsDateVocal,
          soundCardName: insertSoundCardName[0],
          soundCardGuid: insertSoundCardGuid[0],
        },

        {
          name: "CO",
          img: coImg,
          gen: boneGen,
          tonal: boneTonal,
          vocal: boneVocal,
          dateGen: boneDateGen,
          dateTonal: boneDateTonal,
          dateVocal: boneDateVocal,
          soundCardName: boneSoundCardName[0],
          soundCardGuid: boneSoundCardGuid[0], // we get an array with two value (soundcard and sound card name)
        },
        {
          name: "Operator",
          img: operatorImg,
          gen: operatorGen,
          tonal: operatorTonal,
          vocal: operatorVocal,
          dateGen: operatorDateGen,
          dateTonal: operatorDateTonal,
          dateVocal: operatorDateVocal,
          soundCardName: operatorSoundCardName[0],
          soundCardGuid: operatorSoundCardGuid[0], // we get an array with two value (soundcard and sound card name)
        },
      ];

      setAllTransdutor(transductor);
    }
  }, [
    calibGeneral,
    setCalibGeneral,
    calibTonal,
    setCalibTonal,
    calibVocal,
    setCalibVocal,
    cabId,
    setCabId,
    setMaj,
    maj,
  ]);

  const verifyCalibrationPassed = (type) => {
    if (type === 0) {
      return { passed: "Passed", class: "Dirac" };
    }

    if (type.length >= 1) {
      return { passed: "Passed", class: "passed" };
    } else {
      return { passed: "Not Passed", class: "noPassed" };
    }
  };
  const dateCalibrationPassed = (type) => {
    if (type === 0) {
      return { date: 0 };
    }

    if (type.length >= 1) {
      return { date: type[0] };
    } else {
      return { date: "" };
    }
  };


// Surveiller tous les états de chargement
useEffect(() => {
  if (!loadingAllSoundcard && !loadingcalibGen && !loadingcalibVoc && !loadingcalibTon ) {
    setLoadingAllData(false); // Tout est chargé
  }
}, [loadingAllSoundcard, loadingcalibGen,loadingcalibVoc,loadingcalibTon]);


  return (
    <>
    {loadingAllData && <LoaderLogin/>}
      <div
        onClick={() => setDisplaySoundCard(!displaySoundCard)}
        className={styles.containerTitleSound}
      >
        <div className={styles.titleSound}>
          <span>Configuration Cartes sons</span>
          <img src={soundCardImg} alt="" />
        </div>
        {displaySoundCard ? (
          <IoIosArrowDropup className={styles.iconDropDown} />
        ) : (
          <IoIosArrowDropdown className={styles.iconDropDown} />
        )}
      </div>

      {displaySoundCard && (
        <div className={styles.bigContainerSoundCard}>
          {displayModalConfirm && (
            <ModalSoundCard
              transductorNameForUpdate={transductorNameForUpdate}
              setDisplayModalConfirm={setDisplayModalConfirm}
              updateSoundCard={updateSoundCard}
              cabId={cabId}
              cabname={cabname}
              setMaj={setMaj}
              maj={maj}
            />
          )}

          <div className={styles.btnReload}>
            <Button
              onPress={() => setMaj(!maj)}
              styles={"btnValidation"}
              text={"Relancer la liste des cartes sons"}
            />
          </div>
          {allTransductor &&
            calibGeneral.length >= 1 &&
            allTransductor.map((transductor, index) => {
              // ATTENTION AU INDEX NUMERO ICI SI ORDRE D'affichage Change
              //Si il y a une carte son physique et si celle ci contiens pas dirac alors on affiche pas la ligne de
              //carte son dirac
              if (allTransductor[1].soundCardName !== undefined) {
                const diracContains = allTransductor[1].soundCardName;

                if (
                  index === 0 &&
                  !decodedB64(diracContains)
                    .toLocaleLowerCase()
                    .includes("(dirac)")
                ) {
                  return;
                }
              }

              return (
                <OneSoundCard
                  key={transductor.name}
                  transductor={transductor.name}
                  soundCard={soundCard}
                  statusGen={verifyCalibrationPassed(transductor.gen)}
                  statusTonal={verifyCalibrationPassed(transductor.tonal)}
                  statusVocal={verifyCalibrationPassed(transductor.vocal)}
                  dateGen={dateCalibrationPassed(transductor.dateGen)}
                  dateTonal={dateCalibrationPassed(transductor.dateTonal)}
                  dateVocal={dateCalibrationPassed(transductor.dateVocal)}
                  soundCardCalibName={transductor.soundCardName}
                  soundCardCalibGuid={transductor.soundCardGuid}
                  image={transductor.img}
                  updateSoundCard={updateSoundCard}
                  setUpdateSoundCard={setUpdateSoundCard}
                  cabId={cabId}
                  maj={maj}
                  setMaj={setMaj}
                  setDisplayModalConfirm={setDisplayModalConfirm}
                  setTransductorNameForUpdate={setTransductorNameForUpdate}
                />
              );
            })}

          {calibGeneral.length === 0 && (
            <div>Pas de donées de calibration pour cette cabine</div>
          )}
          <div></div>
        </div>
      )}
    </>
  );
};

export default SoundCard;
