import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./PresetCr.module.scss";
import Axios from "../../../../_services/caller.service";
import { AiOutlineClose, AiOutlineExclamationCircle } from "react-icons/ai";

import download from "downloadjs";
import Button from "../../../UiComponents/Button";
import DropZone from "../../../UiComponents/dropZone/DropZone";

import Loader from "../../../UiComponents/loader/Loader";

import ModalConfirmNewPreset from "../modal/ModalConfirmNewPreset";

import SortableTest from "../sortable/SortablePreset";

const PresetCr = ({ userId, setOverlay }) => {
  const [presetCr, setPresetCr] = useState([]);
  const [displayDescription, setDisplayDescription] = useState(null);

  const [maj, setMaj] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSucess] = useState(false);

  // IMPORT EXPORT
  const [selectedFile, setSelectedFile] = useState();
  const [modalConfirmNewPreset, setModalConfirmNewPreset] = useState(false);

  const [isNotGoodImportFileType, setIsNotGoodImportFileType] = useState(false);

  useEffect(() => {
    if (userId !== "") {
      Axios.get(`/presetCr/${userId}`)

        .then(function async(response) {
          setPresetCr(response.data.data);
          setIsNotGoodImportFileType(false);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [userId, maj]);

  //EXPORT
  const exportSelectedCards = () => {
    const dataStr = JSON.stringify(presetCr);
    const filename = `Presets-PDF-id-${userId}.txt`;
    const filetype = "text/plain";

    // Download the file
    download(dataStr, filename, filetype);
  };

  // IMPORT
  const importPresetTest = () => {
    const reader = new FileReader();

    reader.onload = async (e) => {
      // The file's text will be printed here
      const text = e.target.result;

      const data = JSON.parse(text);
      //verifie si c'est le bon type de preset
      const hasPreset = data[0].preset.includes("<PDFPreset>");
      if (!hasPreset) {
        setIsNotGoodImportFileType(true);
        return;
      }

      setPresetCr([...presetCr, ...data]);
    };

    reader.readAsText(selectedFile, "ISO-8859-1");
  };

  const fileChangedHandler = (file) => {
    setSelectedFile(file);
  };

  useEffect(() => {
    if (selectedFile) {
      importPresetTest();
    }
  }, [selectedFile]);

  //ACTIVE MODAL CONFIRM

  const activeModalConfirm = (params) => {
    setOverlay(true);
    setModalConfirmNewPreset(true);
  };

  //REGISTER NEW PRESET
  const registerNewPreset = () => {
    Axios.put(`/updatePresetCr/${userId}`, {
      data: presetCr,
    })

      .then((response) => {
        setLoading(false);
        setSucess(true);
        setTimeout(() => {
          setSucess(false);
          setModalConfirmNewPreset(false);
          setOverlay(false);
        }, 1500);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <h2 className={styles.title}>Preset compte rendus</h2>
        <div className={styles.containerBtnExport}>
          <Button
            onPress={exportSelectedCards}
            styles={"btnExport"}
            text={"Exporter la selection"}
          />

          <div className={styles.containerImport}>
            <div className={styles.containerInputImport}>
              <DropZone onFileUpload={fileChangedHandler} />
              <Button
                onPress={() => {
                  setMaj(!maj);
                }}
                styles={"btnPrimary"}
                text={"Refresh"}
              />
            </div>
          </div>
        </div>

        {isNotGoodImportFileType && (
          <div className={styles.emptySelectedCard}>
            {" "}
            <AiOutlineExclamationCircle
              style={{ color: "orange", fontSize: "25px" }}
            />
            <span>Vous importez pas le bon type de Preset</span>
            <AiOutlineClose
              onClick={() => setIsNotGoodImportFileType(false)}
              style={{ cursor: "pointer", fontSize: "20px" }}
            />
          </div>
        )}
        <SortableTest
          presetType={presetCr}
          setPresetType={setPresetCr}
          displayDescription={displayDescription}
          setDisplayDescription={setDisplayDescription}
        />

        <div className={styles.btnUpdate}>
          <Button
            onPress={activeModalConfirm}
            styles={"btnValidation"}
            text={"Enregistrer les nouvelles valeurs"}
          />
        </div>
        {modalConfirmNewPreset && (
          <ModalConfirmNewPreset
            setOverlay={setOverlay}
            setModalConfirmRegisterNewPreset={setModalConfirmNewPreset}
            registerNewValue={registerNewPreset}
            success={success}
          />
        )}
        {loading && <Loader />}
      </div>
    </>
  );
};

export default PresetCr;
