import React, { useEffect, useRef, useState } from "react";
import styles from "./InputPassword.module.scss";

import {
  AiFillLock,
  AiOutlineEyeInvisible,
  AiOutlineEye,
} from "react-icons/ai";

const InputPassword = ({
  onChange,
  placeholder,
  paddingLeft,
  onKeyUp,
  required,
  id,
}) => {
  const [passwordType, setPasswordType] = useState("password");
  const [inputFocus, setInputFocus] = useState(false);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleInputFocus = () => {
    setInputFocus(true);
  };

  const handleInputBlur = () => {
    setInputFocus(false);
  };



  return (
    <>
      <div className={styles.search}>
        <input
          id={id}
          type={passwordType}
          className={styles.search__input}
          placeholder={inputFocus ? "" : placeholder}
          onChange={onChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          required={required}
          style={{paddingLeft:paddingLeft}}
        />
        <div className={styles.search__icon}>
          <AiFillLock />
        </div>
        <div className={styles.lock} onClick={togglePassword}>
          {passwordType === "password" ? (
            <AiOutlineEyeInvisible />
          ) : (
            <AiOutlineEye />
          )}
        </div>
      </div>
    </>
  );
};

export default InputPassword;
