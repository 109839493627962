import React from 'react';
import styles from './ModalNotGoodOutpout.module.scss'
import { AiOutlineClose } from 'react-icons/ai';
import { BsExclamationTriangleFill } from 'react-icons/bs';



const ModalNotGoodOupout = ({       setOverlay,
    setModalNotGoodOutpout}) => {
    return (
        <div className={styles.modalConfirm}>
        <AiOutlineClose
          className={styles.closeBtnWarningOutpout}
          onClick={() => {
            setOverlay(false);
            setModalNotGoodOutpout(false);
          }}
        />
        <BsExclamationTriangleFill style={{fontSize:'20px', color:'red',marginBottom:'10px'}} />
        <h3>
          Le fichier Txt importés ne correspond pas à la bonne sortie
        </h3>
      </div>
    );
};

export default ModalNotGoodOupout;