import React from 'react';

const LinkToSelf = () => {
    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
        <h3
          style={{
            fontWeight: "900",
            fontSize: "20px",
            marginBottom: "25px",
            marginTop: "50px",
          }}
        >
          Lien avec vous même
        </h3>
        <p>
           Vous avez voulu vous lier à vous même !
          d'autre. <br />
          Il est donc impossible de continuer{" "}
          <span>Ce lien d'invitation a été révoqué</span>
          <span>Demander une autre demande invitation et refaite la procédure</span>
        </p>
      </div>
    );
};

export default LinkToSelf;