import React from 'react';
import CardHeaderPage from '../../UiComponents/card/CardHeaderPage';
import HeaderNavSupportHS from '../headerNav/HeaderNavSupportHS';

const HSGuide = () => {
    return (
        <div>
<HeaderNavSupportHS/>
            <CardHeaderPage text={'Guide pour installation Hearing Space'} textAlign={'center'}/>

        </div>
    );
};

export default HSGuide;