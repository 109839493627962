import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import Button from "../../UiComponents/Button";
import Checkbox from "../../UiComponents/checkbox/Checkbox";
import styles from "./SettingsCab.module.scss";
import Loader from "../../UiComponents/loader/Loader";
import { MdHelpOutline, MdOutlineSettings } from "react-icons/md";

import NavContext from "../../../context/NavContext";
import Axios from "../../../_services/caller.service";
import { useCabineContext } from "../../../context/CabineContext";
import cabineImg from "../../../pics/cabine.png";
import UpdateFeatures from "./UpdateFeatures";
import { AiOutlineClose } from "react-icons/ai";
import SpecificInfosCab from "../infos/SpecificInfosCab";
import ChangeAdressCab from "../infos/ChangeAdressCab";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import LoaderLogin from "../../UiComponents/loader/LoaderLogin";
const SettingsCab = ({
  name,

  cabId,
  setCabId,
  cabType,
  aboId,
  setMaj,
  maj,
  updateCabType,
  setUpdateCabType,
  cabinesByAbo,
  features,
  displaySettingCab,
  setDisplaySettingCab,
}) => {
  //CABINE
  const [cabName, setCabName] = useState(name);

  //DISPLAY
  const [displayAvertissement, setDisplayAvertissement] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [loadingUpdateCab, setLoadingUpdateCab] = useState(false);
  const [optionName, setOptionName] = useState(null);
  const [optionId, setOptionId] = useState(null);
  const [linkMachine, setLinkMachine] = useState("");
  const [HSBuild, setHSBuild] = useState("");
  const [optionalFeatures, setOptionalFeatures] = useState([]);
  const [dispayModalConfirm, setDisplayModalConfirm] = useState(false);

  //LOADING
  const [loadingMachine, setLoadingMachine] = useState(true);
  const [loadingOptional, setLoadingOptional] = useState(true);
  const [loadingLevel, setLoadingLevel] = useState(true);
  const [loadingBuild, setLoadingBuild] = useState(true);
  const [loadingAllData, setLoadingAllData] = useState(true);

  //lEVEL LOG
  const [levelLog, setLevelLog] = useState("");
  //CoNTEXT
  const { cabine } = useCabineContext(); //all cabine owner
  const { majCabine, setMajCabine } = useContext(NavContext);

  const inputCabName = useRef(null);

  const selectCabType = (e) => {
    setUpdateCabType(e.target.value);
  };

  // GET LINK MACHINE
  useEffect(() => {
    Axios.post(`/cabines/`, {
      data: cabId,
    }).then(function (response) {
      const data = response.data.data[0].machine;
      setLoadingMachine(false);
      setLinkMachine(data);
    });
  }, []);

  //DELETE LINK MACHINE
  const deleteLinkMachine = (cabId) => {
    Axios.put(`/deleteLinkMachine/${cabId}`)
      .then((response) => {
        const data = response.data.data[0].machine;
        setLinkMachine(data);
        setDisplayModalConfirm(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // GET A AUTH VALUE OPTIONAL FEATURES
  const optionalValue = [
    "Dirac",
    "AvoidAdminCheck",
    "DisableCalibration",
    "DisableMMDeviceChangeIcon",
  ];

  const verifyOptionalValue = () => {
    let newArrayAuth = [];

    optionalValue.forEach((element) => {
      if (optionalFeatures.includes(element)) {
        newArrayAuth.push({ name: element, auth: true });
      } else {
        newArrayAuth.push({ name: element, auth: false });
      }
    });

    return newArrayAuth;
  };

  useEffect(() => {
    Axios.get(`/optionalFeatures/${cabId}`).then(function (response) {
      const data = response.data.data[0].features.split(",");
      setLoadingOptional(false);
      setOptionalFeatures(data);
    });
  }, []);

  const updateOptionalFeatures = (checked, nameFeatures) => {
    if (checked) {
      optionalFeatures.push(nameFeatures);

      Axios.put(`/updateOptional/${cabId}`, {
        data: { optionalFeatures },
      })

        .then((response) => {
          const data = response.data.data[0].features.split(",");
          setOptionalFeatures(data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const index = optionalFeatures.indexOf(nameFeatures);
      if (index > -1) {
        optionalFeatures.splice(index, 1);

        Axios.put(`/updateOptional/${cabId}`, {
          data: { optionalFeatures },
        })

          .then((response) => {
            const data = response.data.data[0].features.split(",");
            setOptionalFeatures(data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  // GET LEVEL LOG INFOS
  useEffect(() => {
    Axios.get(`/loggingLevel/${cabId}`)

      .then(function async(response) {
        setLevelLog(response.data.data);
        setLoadingLevel(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const changeLevelLog = (e) => {
    const newLevelLog = e.target.value;
    Axios.post(`/createCatidApplicationData/${cabId}`)

      .then((response) => {
        Axios.post(`/setLoggingLevel/${cabId}`, {
          data: newLevelLog,
        })

          .then((response) => {
            setLevelLog(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {});
  };

  // GET HS BUILD INFOS
  useEffect(() => {
    if (cabine !== "") {
      Axios.post(`/HSBuild/`, {
        data: cabId,
      })

        .then(function async(response) {
          setLoadingBuild(false);
          if (response.data.message === "HSBuild none") {
            setHSBuild(response.data.message);
            return;
          } else {
            setHSBuild(response.data.data[0].fval);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [cabId, cabine]);

  // Surveiller tous les états de chargement
  useEffect(() => {
    if (!loadingMachine && !loadingOptional && !loadingLevel && !loadingBuild) {
      setLoadingAllData(false); // Tout est chargé
    }
  }, [loadingMachine, loadingOptional, loadingLevel, loadingBuild]);

  return (
    <>
      {loadingAllData && <LoaderLogin />}
      <div className={styles.containerCab}>
        <ChangeAdressCab
          cabId={cabId}
          majCabine={majCabine}
          setMajCabine={setMajCabine}
          setDisplaySettingCab={setDisplaySettingCab}
          displaySettingCab={displaySettingCab}
        />

        
      

        <div className={styles.containerSettingsCab}>
          {dispayModalConfirm && (
            <>
              <div
                onClick={() => setDisplayModalConfirm(false)}
                className={styles.wrapperModal}
              ></div>
              <div className={styles.containerModal}>
                <AiOutlineClose
                  style={{ fontSize: "25px", cursor: "pointer" }}
                  onClick={() => setDisplayModalConfirm(false)}
                />

                <p>
                  Souhaitez-vous Unlink{" "}
                  <span>
                    {cabName} ({cabId})
                  </span>{" "}
                </p>
                <div className={styles.containerBtn}>
                  <Button
                    onPress={() => setDisplayModalConfirm(false)}
                    styles={"btnDelete"}
                    text={"Annuler"}
                  />
                  <Button
                    onPress={() => deleteLinkMachine(cabId)}
                    styles={"btnValidation"}
                    text={"ok"}
                  />
                </div>
              </div>
            </>
          )}
          <div className={styles.cabName}>
            <h3 className={styles.cabNameTitle}>
              {name} ({cabId})
            </h3>

            <div className={styles.linkToMachine}>
              <h3>
                {" "}
                <span>Linked to machine :</span> {linkMachine}
              </h3>

            
              <Button
                //  onPress={() => deleteLinkMachine(cabId)}
                onPress={() => setDisplayModalConfirm(true)}
                styles={"btnPrimary"}
                text={"Unlink"}
              />
              <h3>HSBuild : {HSBuild}</h3>
            </div>
          </div>

          <div>
            <div className={styles.settingsFeatures}>
              <h3>Optionnal Features :</h3>

              <div className={styles.optional}>
                {cabine &&
                  optionalFeatures.length >= 1 &&
                  verifyOptionalValue().map((feat, index) => {
                    return (
                      <div key={index} className={styles.name} feat={feat}>
                        <div className={styles.containerCheckBox}>
                          <span>{feat.name}</span>

                          <input
                            type="checkbox"
                            onChange={(e) => {
                              updateOptionalFeatures(
                                e.target.checked,
                                feat.name
                              );
                            }}
                            checked={feat.auth}
                            // onClick={onPress}
                          />
                          <label></label>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            <UpdateFeatures
              cabId={cabId}
              majCabine={majCabine}
              setMajCabine={setMajCabine}
              levelLog={levelLog}
              changeLevelLog={changeLevelLog}
            />
          </div>
        </div>
        <SpecificInfosCab
          cabId={cabId}
          majCabine={majCabine}
          setMajCabine={setMajCabine}
          setDisplaySettingCab={setDisplaySettingCab}
          displaySettingCab={displaySettingCab}
        />

      </div>
    </>
  );
};

export default SettingsCab;
