import React from "react";
import { Children } from "react/cjs/react.production.min";
import styles from "./Led.module.scss";
export const GreenLed = ({ Children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.led_box}>
        <p>Autorisé</p>
        {Children}
        <div className={styles.led_green}></div>
      </div>
    </div>
  );
};

export const RedLed = ({ Children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.led_box}>
        <p>Non autorisé</p>
        {Children}
        <div className={styles.led_red}></div>
      </div>
    </div>
  );
};

export const YellowLed = ({ Children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.led_box}>
        <p>Autorisé</p>
        {Children}
        <div className={styles.led_yellow}></div>
      </div>
    </div>
  );
};

//ONLY LED WITHOUT CONTAINER
export const GreenLedSolo = ({ height,width,margin}) => {
  return (
    <div className={styles.led_box_solo}>
      <div className={styles.led_green} style={{width:width,height:height,margin:margin}}></div>
    </div>
  );
};

export const RedLedSolo = ({ height,width,margin }) => {
  return (
    <div className={styles.led_box_solo}>
      <div className={styles.led_red} style={{width:width,height:height,margin:margin}}></div>
    </div>
  );
};

export const YellowLedSolo = ({ height,width,margin}) => {
  return (
    <div className={styles.led_box_solo}>
      <div className={styles.led_yellow} style={{width:width,height:height,margin:margin}}></div>
    </div>
  );
};
export const BlueLedSolo = ({ height,width,margin }) => {
  return (
    <div className={styles.led_box_solo}>
      <div className={styles.led_blue} style={{width:width,height:height,margin:margin}}></div>
    </div>
  );
};
