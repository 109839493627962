import React from 'react';
import sytles from './ConfigHp.module.scss';


const ConfigHp = () => {



    return (
        <div className={sytles.container}>
            La configuration des hauts parleurs n'est plus en 5.0 
            <a href="https://www.hearing-space.com/configuration-carte-son-windows">Suivre le tutoriel</a>
        </div>
    );
};

export default ConfigHp;