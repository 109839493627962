
export const handleValidation= (evnt)=>{
  
    const passwordInputValue = evnt.target.value.trim();
 
        //for password 

    const uppercaseRegExp   = /(?=.*?[A-Z])/;
    const lowercaseRegExp   = /(?=.*?[a-z])/;
    const digitsRegExp      = /(?=.*?[0-9])/;
    const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
    const minLengthRegExp   = /.{8,}/;
    const passwordLength =      passwordInputValue.length;
    const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
    const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
    const digitsPassword =      digitsRegExp.test(passwordInputValue);
    const specialCharPassword = specialCharRegExp.test(passwordInputValue);
    const minLengthPassword =   minLengthRegExp.test(passwordInputValue);
    let errMsg ="";
  
     if(!uppercasePassword){
            errMsg="au moins 1 majuscule";
    }else if(!lowercasePassword){
            errMsg="au moins 1 minuscule";
    }else if(!digitsPassword){
            errMsg="au moins 1 nombre";
    }else if(!specialCharPassword){
            errMsg="au moins 1 caractère spéciale";
    }else if(!minLengthPassword){
            errMsg="au moins 8 caractères";
    }else{
        errMsg="";
    }

return errMsg
    



}


// REGEX FOR PASSWORD

// 8caractere 1num 1 maj 1 minus 1 speciale
export const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");



// 6caractere 1num 1 maj 1 minus
export const mediumRegex = new RegExp("^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})");
