import React, { useState } from "react";
import styles from "./Questions.module.scss";
import { Link } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";

const Questions = ({ answer, questions, link, textLink, list }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  return (
    <div className={styles.containerQuestions}>
      <div
        className={styles.title}
        onClick={() => {
          setShowAnswer(!showAnswer);
        }}
      >
        <h3>{questions}</h3>
        <AiOutlinePlusCircle style={{ fontSize: "40px" }} />
      </div>
      {showAnswer && (
        <div className={styles.content}>
          <p>
            <span className={styles.answer}>{answer} </span>
            {link ? (
              <li className={styles.link}>
                <a href={link} alt="">
                  {" "}
                  {textLink}
                </a>
              </li>
            ) : null}
            <div className={styles.containerList}>
              {list &&
                list.map((li, index) => {
                  return (
                    <li key={index} className={styles.list}>
                      {li}
                    </li>
                  );
                })}
            </div>
          </p>
        </div>
      )}
    </div>
  );
};

export default Questions;
