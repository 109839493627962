import React, { useState, useContext } from "react";
import SignInModal from "../../authentification/SignInModal";
import Button from "../../UiComponents/Button";
import { Link } from "react-router-dom";
import NavContext from "../../../context/NavContext";
import axios from "axios";
import Error from "../../UiComponents/error/Error";
import Loader from "../../UiComponents/loader/Loader";
import styles from "../UserAccountLinker.module.scss";
import { MdAccountCircle } from "react-icons/md";
import { FaChevronRight, FaUserAlt } from "react-icons/fa";
import ContainerForm from "../../UiComponents/forms/ContainerForm";
import InputForms from "../../UiComponents/forms/InputForms";
import InputPassword from "../../UiComponents/forms/InputPassword";
import BtnForms from "../../UiComponents/forms/BtnForms";
import Axios from "../../../_services/caller.service";

const LoginInvite = ({
  setUserId,
  setUserName,
  senderOwner,
  userMailSignIn,
  userPasswordSignIn,
  setUserMailSignIn,
  setUserPasswordSignIn,
  confirmInvitation,
  setNameForEmailSuccess
}) => {
  let params = new URLSearchParams(document.location.search);
  let token = params.get("token");


  axios.defaults.withCredentials = true;
  const { loginUser, errorLogin, loaderLogin } = useContext(NavContext);
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState(false);


  const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);


  const submitForms = async (e) => {
    e.preventDefault();

    Axios.post(`/inviteUserLogin`, {
      userMailSignIn,
      userPasswordSignIn,
    })

      .then((res) => {
        localStorage.setItem("inviteInfos", res.data.token);
         const userId = res.data.id;
      setUserName(res.data.name);
        confirmInvitation(userId); //fonction link user to owner
      })

      .catch((error) => {
        const errorLogin = error.response.data.msg;
        setErrorMsg(errorLogin);
        setError(true);
        console.log(errorLogin);
      });
  };

  return (
    <div className={styles.account}>
      <h2 style={{ textAlign: "center" }}>
        En vous connectant à votre compte,<br/>
         vous serez automatiquement lier à{" "}
        {senderOwner}
      </h2>
      <ContainerForm
        onSubmit={submitForms}
        children={
          <>
            <InputForms
              id={styles.inputUserAccount}
              type={"text"}
              placeholder={"User name/ Email"}
              onChange={(e) => {
                
                setUserMailSignIn(e.target.value)
                setNameForEmailSuccess(capitalize(e.target.value))
              
              }}
              required={true}
              defaultValue={userMailSignIn}
              svg={<FaUserAlt />}
            
            />
            <InputPassword
              placeholder={"Mot de passe"}
              onChange={(e) => setUserPasswordSignIn(e.target.value)}
             
            />

            <BtnForms svg={<MdAccountCircle />} text="Je me connecte" />
            {error && <Error error={errorMsg} />}

            {loaderLogin && <Loader />}
          </>
        }
      />

    
    </div>
  );
};

export default LoginInvite;
