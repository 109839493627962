import React from "react";
import styles from "./DashboardCard.module.scss";
import { MdOutlineWavingHand } from "react-icons/md";

import { Link } from "react-router-dom";

const DashboardCard = ({color, title, link, img, text, svg }) => {
  return (
    <>
      <Link to={`${link}`}>
        <div className={styles.card}>
          <div className={styles.box}>
            <div className={styles.content} style={{color:color}}>
              {/* <h2>01</h2> */}
              <h3 style={{color}}>{title}</h3>
              {svg}
              {/* <img src={img} alt="" /> */}
              <h4>{text}</h4>

              {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore, totam velit? Iure nemo labore inventore?</p> */}
              {/* <a href="#">Aller à</a> */}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default DashboardCard;
