import React, { useState, useEffect } from "react";
import styles from "./UsersNumberStats.module.scss";
import statsGeneralImg from "../../../pics/bg-login.png";

export default function UsersNumberStats({ totalUsers, totalCabines }) {
  const [usersCount, setUsersCount] = useState(0);
  const [cabinesCount, setCabinesCount] = useState(0);

  useEffect(() => {
    const usersIncrement = totalUsers / 50;
    const cabinesIncrement = totalCabines / 50;

    const usersInterval = setInterval(() => {
      setUsersCount((prevCount) => {
        if (prevCount < totalUsers) return prevCount + usersIncrement;
        clearInterval(usersInterval);
        return totalUsers;
      });
    }, 20); // Ajustez le temps pour changer la vitesse de l'animation

    const cabinesInterval = setInterval(() => {
      setCabinesCount((prevCount) => {
        if (prevCount < totalCabines) return prevCount + cabinesIncrement;
        clearInterval(cabinesInterval);
        return totalCabines;
      });
    }, 20); // Ajustez le temps pour changer la vitesse de l'animation

    return () => {
      clearInterval(usersInterval);
      clearInterval(cabinesInterval);
    };
  }, [totalUsers, totalCabines]); // Dépendances pour re-déclencher l'animation si totalUsers ou totalCabines change

  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img src={statsGeneralImg} alt="Filters Visualization" />
        <span className={styles.card_price}>
          <span>Général </span>Stats
        </span>
      </div>
      <div className={styles.card_content}>
        <div className={styles.card_text}>
          <ul className={styles.summaryList}>
            <li>
              <strong>Utilisateurs : </strong><span className={styles.numbersTotal}>{Math.round(usersCount)} </span> 
            </li>
    
            <li>
              <strong>Nombre de cabines : </strong><span className={styles.numbersTotal}>{Math.round(cabinesCount)} </span> 
            </li>
          
          </ul>
        </div>
      </div>
    </div>
  );
}
