import React from 'react';
import {BsFillExclamationTriangleFill} from "react-icons/bs";
import {MdInsertInvitation} from "react-icons/md"
import Button from '../Button';
import styles from './WarningAdminModal.module.scss'
const WarningAdminModal = ({name,lastName,updateRoleInAdmin}) => {
    return (
        <div className={styles.wrapper}>
             <BsFillExclamationTriangleFill style={{color:'red', fontSize:'50px'}} />
<h2>Vous êtes sur le point de modifier les droits de <span>{name} {lastName}</span>  en Admin. Il/elle pourra désormais accéder à votre dashboard... Vous pouvez re-modifier ses droits à tout moment</h2>


<Button
          onPress={updateRoleInAdmin}
          svg={<MdInsertInvitation/>}
          text={`Je passe  ${lastName} en Admin`}
          styles={"btnValidation"}
        ></Button>
        </div>
    );
};

export default WarningAdminModal;