import React from "react";
import { MdContactPhone, MdOutlineMail } from "react-icons/md";
import styles from "./ContactFaq.module.scss";

const ContactFaq = () => {
  return (
    <div className={styles.container}>
      <h1>Vous avez une autre question ?</h1>

      <div className={styles.containerCard}>
        <div className={styles.contactCard}>
          <a href="">
          <MdContactPhone style={{ fontSize: "40px" }} />
          <h3>02 61 72 06 02</h3>
          <h4>Toujous là pour vous aider</h4>
          </a>
        </div>
        <div className={`${styles.contactCard} ${styles.email}`}>
          <a href="mailto:support@hearing-space.com" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MdOutlineMail className={styles.iconMail}style={{ fontSize: "40px"}} />
            <h3>support@hearing-space.com</h3>
            <h4>Réponse rapide </h4>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactFaq;
