import React, { useEffect, useState } from "react";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import styles from "./Preset.module.scss";
import preset from "../../../pics/les-presets.png";
import SelectUser from "./selectUser/SelectUser";
import PresetTest from "./presetTest/PresetTest";
import Axios from "../../../_services/caller.service";
import PresetCr from "./presetCr/PresetCr";

const Preset = () => {
  const [displayPreset, setDisplayPreset] = useState(true);
  const [allUser, setAllUser] = useState([]);
  const [userId, setUserId] = useState("");
  const [overlay, setOverlay] = useState(false);
  const [maj, setMaj] = useState(false); // MAJ lors de changement

  const [isSearchingCab, setIsSearchingCab] = useState(false);
  const [searchUser, setSearchUser] = useState(""); // search user
  const [isSearchingUser, setIsSearchingUser] = useState(false);

  // GET ALL USER
  useEffect(() => {
    Axios.get(`/getAllUserForSuperAdmin`)

      .then(function async(response) {
        setAllUser(response.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  const changeUserIdWithSelect = (e) => {
    const id = e.target.value;
    setUserId(id);
    setSearchUser("");
  };

  return (
    <>
      <div
        onClick={() => setDisplayPreset(!displayPreset)}
        className={styles.containerTitlePreset}
      >
        <div className={styles.titlePreset}>
          <span>Configuration Preset</span>
          <img src={preset} alt="" />
        </div>
        {displayPreset ? (
          <IoIosArrowDropup className={styles.iconDropDown} />
        ) : (
          <IoIosArrowDropdown className={styles.iconDropDown} />
        )}
      </div>

      {displayPreset && (
        <>
          <div className={styles.containerPreset}>
            {overlay && <div className={styles.overlay}></div>}
            <SelectUser
              allUser={allUser}
              changeUserIdWithSelect={changeUserIdWithSelect}
              searchUser={searchUser}
              setSearchUser={setSearchUser}
              userId={userId}
              isSearchingUser={isSearchingUser}
              setIsSearchingUser={setIsSearchingUser}
            />
            <div className={styles.containerPresetTest}>
              <PresetTest userId={userId} setOverlay={setOverlay} />
            </div>{" "}
            <div className={styles.containerPresetCr}>
              <PresetCr userId={userId} setOverlay={setOverlay} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Preset;
