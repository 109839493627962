import React, { useState } from "react";
import styles from "./HeaderManageCabines.module.scss";
import { MdInfoOutline } from "react-icons/md";
import { CgChevronUpO, CgChevronDownO } from "react-icons/cg";

const HeaderManageCabines = ({ setSorted, sorted }) => {
  return (
    <>
      <div className={styles.container}>
        {/* Pour id */}
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.userId}>id</h3>
          <div className={styles.chevronFilterUserId}>
            <CgChevronUpO
              className={`${styles.arrow} ${
                sorted.field === "id" && sorted.order === "asc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("id", "asc")}
            />
            <CgChevronDownO
              className={`${styles.arrow} ${
                sorted.field === "id" && sorted.order === "desc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("id", "desc")}
            />
          </div>
        </div>

        <h3 className={styles.sub}>Abonnements</h3>
        {/* Pour Login */}
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.cabname}>Cabine</h3>
          <div className={styles.chevronFilterCabname}>
            <CgChevronUpO
              className={`${styles.arrow} ${
                sorted.field === "cabname" && sorted.order === "asc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("cabname", "asc")}
            />
            <CgChevronDownO
              className={`${styles.arrow} ${
                sorted.field === "cabname" && sorted.order === "desc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("cabname", "desc")}
            />
          </div>
        </div>

        <h3 className={styles.newName}>Changer le nom</h3>

        <div className={styles.save}>
          <h3>Sauvegarder</h3>
        </div>
      </div>
    </>
  );
};

export default HeaderManageCabines;
