import React, { useState } from "react";
import styles from "./HeaderUserCard.module.scss";
import { MdInfoOutline } from "react-icons/md";
import { CgChevronUpO, CgChevronDownO } from "react-icons/cg";
import BubbleInfos from "../../UiComponents/bubleInfos/BubbleInfos";
import { InfosRole } from "../../UiComponents/bubleInfos/Infos";

const HeaderUserCard = ({ setSorted }) => {
  const [infosBubble, setInfosBubble] = useState(false);

  const sorted = (order) => {
    setSorted(order);
  };

  return (
    <>
      <div className={styles.container}>

        <div className={styles.containerChevronFilter}>
          <h3 className={styles.login} >
        Login
          </h3>
          <div className={styles.chevronFilterLogin}>
            <CgChevronUpO
              className={styles.arrow}
              onClick={() => sorted("ascLogin")}
            />
            <CgChevronDownO
              className={styles.arrow}
              onClick={() => sorted("dscLogin")}
            />
          </div>
        </div>
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.lastName} >
        Nom
          </h3>
          <div className={styles.chevronFilterLastName}>
            <CgChevronUpO
              className={styles.arrow}
              onClick={() => sorted("ascName")}
            />
            <CgChevronDownO
              className={styles.arrow}
              onClick={() => sorted("dscName")}
            />
          </div>
        </div>
       
        
          <h3 className={styles.email}>Email</h3>
       
   
        <div className={styles.droits}>
          <h3> Droits Actifs</h3>

          <MdInfoOutline
            onMouseEnter={() => setInfosBubble(true)}
            onMouseLeave={() => setInfosBubble(false)}
            style={{ cursor: "pointer" , fontSize:'20px' }}
          />
        </div>
        <div className={styles.allCab}>
          <h3>Autoriser toutes les cabines</h3>
        </div>

        <h3 className={styles.numberCab}>Nombre de cabines</h3>
        {/* <h3 className={styles.delete}>Supprimer utilisateur</h3> */}
        <h3 className={styles.settings}>Réglages</h3>
      {infosBubble && <BubbleInfos children={<InfosRole />} />}
      </div>
    </>
  );
};

export default HeaderUserCard;
