import React from "react";
import Button from "../../UiComponents/Button";
import styles from "./SearchError.module.scss";
import {BiRefresh} from 'react-icons/bi'

const SearchError = ({ search, setSearch }) => {
  return (
    <div className={styles.search}>
      <input
        type="text"
        onChange={(e) => setSearch(e.target.value.toLocaleLowerCase())}
        placeholder="Entrez le message d'erreur ..."
      />

<Button onPress={()=>setSearch('')} type={'text'} styles={'btnPrimary'} text={'réinitialiser la recherche'} svg={<BiRefresh/>}/>

    </div>
  );
};

export default SearchError;
