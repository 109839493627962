import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./SortablePreset.module.scss";

import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlineDrag } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";

const Item = ({
  preset,
 deleteCardSelection,
 index,
  order,
}) => {
  let name = "";
  let description = "";
  let presetName = preset.preset.match(/\[presetname\]([^\[]*)/);
  let desc = preset.preset.match(/\[desc\](.*?)(\[|$)/);

  if (presetName && presetName[1]) {
    name = presetName[1].trim();
  }
  if (desc && desc[1]) {
    description = desc ? desc[1].trim() : "pas de description";
  }

  return (
    <div className={styles.presetCard}>
      <span className={styles.index}>{index}</span>
      <span className={styles.name}>{name}</span>
      {order > 0 && <span className={styles.order}>{order}</span>}

      <MdDeleteOutline
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation(); // arrête la propagation de l'événement
          deleteCardSelection(index);
        }}
        className={styles.delete}
      />
    </div>
  );
};

const SortableTest = ({
  presetType,

  displayDescription,
  setDisplayDescription,
  setPresetType,
}) => {
  const updateCabIndex = (itemsArray) => {
    return itemsArray.map((item, index) => {
      return {
        ...item,
        cabindex: index,
      };
    });
  };

  const deleteCardSelection = (index) => {
    const updatedItems = [...presetType];
    updatedItems.splice(index, 1);

    const itemsWithUpdatedCabIndex = updateCabIndex(updatedItems);
    setPresetType(itemsWithUpdatedCabIndex);
  };

  const moveCardLeft = (index) => {
    if (index <= 0) return; // Si c'est la première carte, ne faites rien
    const updatedItems = [...presetType];
    const temp = updatedItems[index - 1];
    updatedItems[index - 1] = updatedItems[index];
    updatedItems[index] = temp;
    const itemsWithUpdatedCabIndex = updateCabIndex(updatedItems); // Mise à jour de cabindex
    setPresetType(itemsWithUpdatedCabIndex);
  };

  const moveCardRight = (index) => {
    if (index >= presetType.length - 1) return; // Si c'est la dernière carte, ne faites rien
    const updatedItems = [...presetType];
    const temp = updatedItems[index + 1];
    updatedItems[index + 1] = updatedItems[index];
    updatedItems[index] = temp;
    const itemsWithUpdatedCabIndex = updateCabIndex(updatedItems); // Mise à jour de cabindex
    setPresetType(itemsWithUpdatedCabIndex);
  };

  return (
    <div className={styles.containerPresetCard}>
      {presetType &&
        presetType.map((value, index) => (
          <React.Fragment key={index}>
            {/* Ne montrez pas la flèche gauche pour le premier élément */}
            {index !== 0 && (
              <button onClick={() => moveCardLeft(index)}>←</button>
            )}

            <Item
              index={index}
              preset={value}
              deleteCardSelection={() => deleteCardSelection(index)}
              displayDescription={displayDescription}
              setDisplayDescription={setDisplayDescription}
              order={value.order}
            />

            {/* Ne montrez pas la flèche droite pour le dernier élément */}
            {index !== presetType.length - 1 && (
              <button onClick={() => moveCardRight(index)}>→</button>
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

export default SortableTest;
