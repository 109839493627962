import React from "react";
import styles from "./videosFaq.module.scss";
import ReactPlayer from "react-player";

const VideosFaq = ({ url }) => {
  return (
    <div className={styles.container}>
      <ReactPlayer
        url={url}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              controls: 1,
              modestbranding: 1, // cela enlève le logo YouTube
              rel: 0, // cela empêche de montrer d'autres vidéos à la fin
            },
          },
        }}
      />
    </div>
  );
};

export default VideosFaq;
