import React, { useState } from "react";
import styles from "./Faq.module.scss";

import CategoriesFaq from "./CategoriesFaq";

import InputFaq from "./InputFaq";
import VideosFaq from "./VideosFaq";
import { MdOutlineVideoSettings, MdHeadphones } from "react-icons/md";
import { GiHeadphones } from "react-icons/gi";
import { BsClock, BsSpeakerFill } from "react-icons/bs";
import { FcSpeaker } from "react-icons/fc";
import { RiInstallLine,RiPagesLine } from "react-icons/ri";
import ContactFaq from "./ContactFaq";
import Questions from "./Questions";
import { QuestionData } from "./QuestionData";
import ContainerForm from "../UiComponents/forms/ContainerForm";
import Grid from "../UiComponents/layout/Grid";
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
import DashboardHeader from "../Dashboard/Card/DashboardHeader";
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";
import insertSvg from '../../pics/insertSvg2.png'

const Faq = () => {
  const [showVideo, setShowVideo] = useState(true);
  const [url, setUrl] = useState("https://www.youtube.com/watch?v=CA-OXasI1CE&ab_channel=HearingSpace");

  const selectVideos = (url) => {
    setShowVideo(true);
    setUrl(url);
  };


  // MANUAL
  const downloadManual = () => {
    window.open("https://hearing-space.com/hs-manual", "_blank");
  };


  return (
    <motion.div
      className={styles.container}
      variants={animatePage}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {/* <ContainerForm children={<div>CONTACT FORMS</div>}/> */}

      <CardHeaderPage text="FAQ" />
<div className={styles.containerManual}>
<div className={styles.manual}>
  <span>Mode d'emploi Hearing Space</span>
      <CategoriesFaq
          onPress={() =>
            downloadManual()
          }
          svg={<RiPagesLine style={{ fontSize: "40px" }} />}
          text={"Télécharger le mode d'emploi"}
        />

</div>
</div>

      {/* <InputFaq /> */}
      <h1>Retrouvez-ici nos vidéos d'aide</h1>
      <div className={styles.containerVideos}>
        {" "}

        <CategoriesFaq
          onPress={() => {
            selectVideos(
              "https://www.youtube.com/watch?v=CA-OXasI1CE&ab_channel=HearingSpace"
            );
          }}
          svg={<RiInstallLine style={{ fontSize: "40px" }} />}
          text={"Installation Hearing-Space"}
        />
        <CategoriesFaq
          onPress={() =>
            selectVideos("https://www.youtube.com/watch?v=4sBqZDpN8Pk")
          }
          svg={<FcSpeaker style={{ fontSize: "40px" }} />}
          text={"Calibration du champ libre"}
        />
        <CategoriesFaq
          onPress={() =>
            selectVideos("https://www.youtube.com/watch?v=RQqMR2s5hos")
          }
          svg={<MdHeadphones style={{ fontSize: "40px" }} />}
          text={"Calibration Casques"}
        />
        <CategoriesFaq
          onPress={() =>
            selectVideos("https://www.youtube.com/watch?v=ZUtmdMd3VkI")
          }
          svg={<img className={styles.insertSvg} src={insertSvg}  />}
          text={"Calibration Inserts"}
        />
        <CategoriesFaq
          onPress={() =>
            selectVideos("https://www.youtube.com/watch?v=QUdhPwiYjrI")
          }
          svg={<BsSpeakerFill style={{ fontSize: "40px" }} />}
          text={"Installation du champ libre"}
        />
      </div>

      {showVideo && <VideosFaq id="videos" url={url} />}

      <div className={styles.containerQuestions}>
        <h1>Réponse à vos questions:</h1>
        {QuestionData.map((ques, index) => {
          
          return (
            <Questions
              key={index}
              questions={ques.question}
              answer={ques.answer}
              link={ques.link}
              textLink={ques.linkText}
              list={ques.list}
            />
          );
        })}
      </div>
      <ContactFaq />
    </motion.div>
  );
};

export default Faq;
