import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Button from "../Button";
import {BiUpload} from 'react-icons/bi'
import styles from './DropZone.module.scss'


const DropZone = ({ onFileUpload }) => {
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        onFileUpload(acceptedFiles[0]);
    }, [onFileUpload]);

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div {...getRootProps()} className={styles.dropzone}>
            <input {...getInputProps()} />
            <p>Drag and Drop le fichier ici ou Click</p>
        </div>
    );
};

export default DropZone;
