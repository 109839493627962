import DashboardCard from "./Card/DashboardCard";
import DashboardHeader from "./Card/DashboardHeader";
import styles from "./Dashboard.module.scss";
import Grid from "../UiComponents/layout/Grid";
//IMG
import CalibrationEx from "../../pics/calibrationExample.jpg";
import Telechargements from "../../pics/telechargement.jpg";
import Team from "../../pics/team.webp";
import Support from "../../pics/support.webp";
import Compte from "../../pics/compte.jpg";
import { motion } from "framer-motion";
import { animatePage } from "../../animation/animationPage";
//SVG
import { BsCloudDownload, BsFillPersonPlusFill } from "react-icons/bs";
import { BiMicrophone, BiSupport } from "react-icons/bi";
import { RiUserSettingsLine } from "react-icons/ri";
import {
  WelcomeCardAdmin,
  WelcomeCardOwner,
} from "../UiComponents/card/WelcomeCard";
import { useContext } from "react";
import NavContext from "../../context/NavContext";
import axios from "axios";
import Axios from "../../_services/caller.service";
import CardHeaderPage from "../UiComponents/card/CardHeaderPage";
import Loader from "../UiComponents/loader/Loader";

const colorForCards = {
  1: "#5e548e",
  2: "#e0b1cb",
  3: "#b69efe",
  4: "#58d5c9",
  5: "#be95c4",
};

const sizeSvg = 25;

const Dashboard = () => {
  const { ownerProfile, user } = useContext(NavContext);

  return (
    <motion.main
      className={styles.container}
      variants={animatePage}
      exit="exit"
      initial="hidden"
      animate="visible"
    >
      <CardHeaderPage text={"Bienvenue sur le dashboard hearing space"} />
      {/* <WelcomeCardOwner owner={ownerProfile.display_name} /> */}
      <div className={styles.containerAllCard}>
        {!user && (
          <DashboardCard
            title={"Certificat de calibration"}
            color={colorForCards[1]}
            link="/private/calibration"
            img={CalibrationEx}
            svg={
              <BiMicrophone
                style={{ fontSize: sizeSvg, color: colorForCards[1] }}
              />
            }
            text={"Vos certificat de calibration"}
          />
        )}
        {!user && (
          <DashboardCard
            title={"Mes Téléchargements"}
            color={colorForCards[2]}
            link="/private/download"
            img={Telechargements}
            text={"Télécharger Hearing Space"}
            svg={
              <BsCloudDownload
                style={{ fontSize: sizeSvg, color: colorForCards[2] }}
              />
            }
          />
        )}
        {!user && (
          <DashboardCard
            title={"Gestion des utilisateurs"}
            color={colorForCards[3]}
            img={Team}
            link="/private/team"
            text={"Invitez de nouveau utilisateurs pour vos cabines"}
            svg={
              <BsFillPersonPlusFill
                style={{ fontSize: sizeSvg, color: colorForCards[3] }}
              />
            }
          />
        )}

        <DashboardCard
          title={"FAQ"}
          color={colorForCards[4]}
          img={Support}
          link="/private/Faq"
          text={"Besoin d'aide"}
          svg={
            <BiSupport style={{ fontSize: sizeSvg, color: colorForCards[4] }} />
          }
        />
        <DashboardCard
          title={"Mon Compte"}
          color={colorForCards[5]}
          img={Compte}
          link="/private/mon-compte"
          text={"Gestion de votre compte"}
          svg={
            <RiUserSettingsLine
              style={{ fontSize: sizeSvg, color: colorForCards[5] }}
            />
          }
        />
      </div>
    </motion.main>
  );
};

export default Dashboard;
