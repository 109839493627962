import React, { useContext, useState } from "react";
import styles from "./Password.module.scss";
import axios from "axios";
//import { UserContext } from "../../context/UserContext";
import NavContext from "../../context/NavContext";
import Error from "../UiComponents/error/Error";
import Loader from "../UiComponents/loader/Loader";

import { FaChevronRight, FaUserAlt } from "react-icons/fa";
import { MdOutlineArrowBack } from "react-icons/md";

import { IoArrowBack } from "react-icons/io";
import Success from "../UiComponents/sucess/Success";
import ContainerForm from "../UiComponents/forms/ContainerForm";
import InputForms from "../UiComponents/forms/InputForms";
import BtnForms from "../UiComponents/forms/BtnForms";

const ForgotPassword = ({ showForgot, setShowForgot }) => {
  //CONTEXT
  const { loginUser, errorLogin, loaderLogin } = useContext(NavContext);
  //FORMS
  const [userMail, setUserMail] = useState("");

  //DISPLAY
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSucess] = useState(false);
  const [msgSuccess, setMsgSucess] = useState("");
  const [msgError, setmsgError] = useState("");

  axios.defaults.withCredentials = true;

  const submitFormsForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}api/sendEmailForNewPass`, {
        userMail: userMail,
      })

      .then(function async(response) {
        if (
          response.data.message ===
          "Un email de réinitialisation a été envoyé sur votre boite mail"
        ) {
          setSucess(true);

          setTimeout(() => {
            setSucess(false);
          }, 2000);

          setLoading(false);
          setMsgSucess(response.data.message);
        }

        if (response.data.message === "erreur email non envoyé") {
          setError(true);
          setmsgError("une erreur s'est produite pendant l'envoie de l'email");
          setTimeout(() => {
            setError(false);
          }, 3000);
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
        setError(true);

        setmsgError("aucun utilisateur avec ce nom ou cette email");
      });
  };

  return (
    <div className="fixed-container-modals">
      <div className={styles.back_login} onClick={() => setShowForgot(false)}>
        <MdOutlineArrowBack style={{ fontSize: "25px" }} />
        <span>retour page de connexion</span>
      </div>
      <div className={styles.containerForm}>
        <ContainerForm
          onSubmit={submitFormsForgotPassword}
          modal={true}
          children={
            <>
              <InputForms
                type={"text"}
                placeholder={"User name/ Email"}
                onChange={(e) => setUserMail(e.target.value)}
                required={true}
                // svg={<FaUserAlt />}
              />

              <BtnForms text={"Réinitialiser mot de passe"} />
              {loading && <Loader />}
              {error && <Error error={msgError} />}
              {success && <Success success={msgSuccess} />}
            </>
          }
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
