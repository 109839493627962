//REACT ROUTER
import { Link, useNavigate } from "react-router-dom";

//HOOKS
import useClickOutside from "../../../CustomHooks/ClickOutside";
import React, { useContext, useState } from "react";

//ICONS , PICS , STYLES
import styles from "./MyProfile.module.scss";
import { MdKeyboardArrowDown } from "react-icons/md";
import { GrUserSettings } from "react-icons/gr";
import Logo from "../../../pics/favicon.png";
import NavContext from "../../../context/NavContext";

const MyProfile = () => {
  const [isProfileOpen, setisProfileOpen] = useState(false);
  const { nav, setNav, setUserAuth, setLoading, adminProfile } =
    useContext(NavContext);
  const navigate = useNavigate();

  let domNode = useClickOutside(() => {
    setisProfileOpen(false);
  });

  const logout = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("isLoggedIn");
    setNav(!nav); // hidden side bar
    setUserAuth(false);
    navigate("/");
    // window.location.reload()

    //setLoading(true)
  };
  return (
    <div
      ref={domNode}
      className={styles.avatar_container}
      onClick={() => {
        setisProfileOpen(!isProfileOpen);
      }}
    >
      {/* AVATAR ICON */}

      <GrUserSettings
        style={{ fontSize: "25px", color: "#ffffff", cursor: "pointer" }}
      />

      {/* AVATAR/SETTINGS SUBMENU */}
      <div
        className={`${styles.menu} ${isProfileOpen ? styles.menu_active : ""}`}
      >
        <div className={styles.info}>
          {/* <span className={styles.name}>Paramètres</span> */}
          <span className={styles.role}>{adminProfile.display_name}</span>
        </div>
        <div className={styles.settings}>
          <Link to="/private/mon-compte">Mon compte</Link>
          <Link onClick={logout} to="">
            Déconnexion
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
