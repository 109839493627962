import React from "react";
import styles from "./LoaderLogin.module.scss";
import logo from "../../../pics/favicon.png";

const LoaderLogin = () => {
  return (
    <div className={styles.overlay}>
      <img className={styles.loader} src={logo} alt="" />
      {/* <div className={styles.loader}></div> */}
    </div>

    // <div className={styles.ldsEllipsis}><div></div><div></div><div></div><div></div></div>
  );
};

export default LoaderLogin;
