import React, { useState } from "react";
import styles from "./HeaderManageGroup.module.scss";
import { MdInfoOutline } from "react-icons/md";
import { CgChevronUpO, CgChevronDownO } from "react-icons/cg";
import BubbleInfos from "../../UiComponents/bubleInfos/BubbleInfos";
import { InfosRole } from "../../UiComponents/bubleInfos/Infos";

const HeaderManageGroup = ({ setSorted, sorted }) => {
  return (
    <>
      <div className={styles.container}>
        {/* Pour id */}
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.userId}>id</h3>
          <div className={styles.chevronFilterUserId}>
            <CgChevronUpO
              className={`${styles.arrow} ${
                sorted.field === "id" && sorted.order === "asc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("id", "asc")}
            />
            <CgChevronDownO
              className={`${styles.arrow} ${
                sorted.field === "id" && sorted.order === "desc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("id", "desc")}
            />
          </div>
        </div>
        {/* Pour Login */}
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.login}>Login</h3>
          <div className={styles.chevronFilterLogin}>
            <CgChevronUpO
              className={`${styles.arrow} ${
                sorted.field === "user_login" && sorted.order === "asc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("user_login", "asc")}
            />
            <CgChevronDownO
              className={`${styles.arrow} ${
                sorted.field === "user_login" && sorted.order === "desc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("user_login", "desc")}
            />
          </div>
        </div>

        {/* Pour Nom */}
        <div className={styles.containerChevronFilter}>
          <h3 className={styles.lastName}>Nom</h3>
          <div className={styles.chevronFilterLastName}>
            <CgChevronUpO
              className={`${styles.arrow} ${
                sorted.field === "display_name" && sorted.order === "asc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("display_name", "asc")}
            />
            <CgChevronDownO
              className={`${styles.arrow} ${
                sorted.field === "display_name" && sorted.order === "desc"
                  ? styles.activeSort
                  : ""
              }`}
              onClick={() => setSorted("display_name", "desc")}
            />
          </div>
        </div>

        <div className={styles.mdp}>
          <h3>
            {" "}
            Réinitialisation <br /> mdp
          </h3>
        </div>

        {/* Autres colonnes... */}
        <div className={styles.group}>
          <h3> Groupe</h3>
        </div>
        <div className={styles.restriction}>
          <h3>Restrictions</h3>
        </div>
        <div className={styles.permission}>
          <h3>Permissions</h3>
        </div>
      </div>
    </>
  );
};

export default HeaderManageGroup;
